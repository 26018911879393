import React from 'react';
import { useTranslation } from 'react-i18next';
import { createTheme,ThemeProvider, makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import axios from "axios";
import {serialize} from "object-to-formdata";
import {useAppLoadingState} from "../../../../context/appLoadingState";
import { parseISO } from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime, format } from 'date-fns-tz';
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
    actionLogWrapper: {
      position:'relative'
    },
    backdrop: {
        position:'relative',
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    },
    timeline: {
        width:'100%',
        maxHeight:'650px',
        overflow:'auto',
        marginTop: 0,
        paddingTop: 0
    },
    timelineStepText: {
        fontWeight: 600
    },
    timelineStepDescription: {
        fontWeight: 300
    },
    closeButton: {
        margin: 10
    }
}));

const timeLineTheme = createTheme({
    overrides: {
        MuiTimelineItem: {
            root: {
                minHeight: 'none'
            },
        },
        MuiTimelineOppositeContent: {
            root: {
                flex: 'none'
            }
        }
    }
});

function ActionLog(props) {
    const classes = useStyles();
    const { t } = useTranslation();
   // const { updateAppLoadingState } = useAppLoadingState();
    const {
        showTitle,
        isActionLogLoading,
        actionLogItems,
        ticketId,
        onLoadComplete
    } = props;
/*

    function loadTimeline()
    {
        updateAppLoadingState(true);
        axios.post(
            "/api/v1/support/ticket/get-history",
            serialize({
                ticketId: ticketId,
            })
        ).then(result => {
            updateAppLoadingState(false);
            if( typeof onLoadComplete === 'function' ) {
                onLoadComplete(result);
            }
            if (result.status === 200) {
                setTicketHistoryItems(result.data);
            }
        }).catch(e => {
            console.log(e);
            updateAppLoadingState(false);
        });
    }

    React.useEffect(() => {
        loadTimeline();
    }, [ticketId]);*/


    return (
        <ThemeProvider theme={timeLineTheme}>
            <Box className={classes.actionLogWrapper}>
                {showTitle==true &&
                    <Typography variant="subtitle2" gutterBottom={true} style={{fontWeight: 600}}>
                        {t('actionLog')}
                    </Typography>
                }
                <Timeline className={classes.timeline}>
                    { actionLogItems.map((item, index) => (
                        <TimelineItem>
                            <TimelineOppositeContent>
                                <Typography variant="body2" component="div" color="textSecondary">
                                    {
                                        item.date ? format(parseISO(item.date), 'dd.MM.yyyy HH:mm:ss') : '-'
                                    }
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot variant="outlined" color={ item.isCurrent ? 'primary' : 'grey'}/>
                                {actionLogItems.length-1 != index && <TimelineConnector />}
                            </TimelineSeparator>
                            <TimelineContent>
                                <Typography variant="body2" component="div" display="block" className={classes.timelineStepText}>
                                    {item.step}
                                    {item.step === 'Comment' ? (item.source === 'Cloudiax' ? " by Cloudiax" : "") : ""}
                                </Typography>
                                <Typography style={{whiteSpace: "pre-wrap"}} variant="body2" component="div" color="textSecondary" display="block" gutterBottom={item.description ? true : false} className={classes.timelineStepDescription}>
                                    {item.description}
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                    ))}
                    {isActionLogLoading==true &&
                        <CircularProgress size={12} color="inherit" />
                    }
                </Timeline>
            </Box>
        </ThemeProvider>
    );
}

export default ActionLog;