import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import {makeStyles} from "@material-ui/core/styles";
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from "@material-ui/core/Typography";
import Skeleton from '@material-ui/lab/Skeleton';
import axios from "axios";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    categoryTreeTitle: {
        fontWeight: 600
    },
    categoryTreeParentItem: {
        fontWeight: 500
    },
    categoryTreeItemSkeletonWrapper: {
        margin: '5px 0 5px 0',
    },
}));


function CategoryTree(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        setIsDataStillProcessing,
        setSearchFilters,
        setBreadcrumbData,
        selectedCategoryTreeItems,
        setSelectedCategoryTreeItems
    } = props;

    const treeViewSkeletonFilledArray = Array(12).fill();
    const [categoryTreeItems, setCategoryTreeItems] = React.useState([]);
    const [expandedCategoryTreeItems, setExpandedCategoryTreeItems] = React.useState([1]);

    const handleCategoryTreeNodeToggle = (event, nodeIds) => {
        setExpandedCategoryTreeItems(nodeIds);
    };

    const handleCategoryTreeNodeSelect = (event, nodeIds) => {
        setSelectedCategoryTreeItems(nodeIds);
    };

    // category tree
    const handleCategoryTreeParentNodeClick = (object) => {
        let categoryName = object.name,
            categoryId = object.relatedNodeIds ? object.relatedNodeIds.join(',') : object.categoryId;

        setSearchFilters((prevState) => ({
            ...prevState,
            categoryId: categoryId===1 ? null : categoryId
        }));

        if( categoryName==='All' ) {
            categoryName = null;
        }

        setBreadcrumbData((prevState) => ({
            ...prevState,
            data: {
                ...prevState.data,
                categoryName: categoryName
            }
        }));
    };

    const renderCategoryTree = (nodes) => {
        if(nodes.parentId && expandedCategoryTreeItems.indexOf(nodes.parentId)===-1 ) {
            setExpandedCategoryTreeItems((prevState) => ([
                ...prevState,
                nodes.parentId,
            ]));
        }
        return (
            <TreeItem
                key={nodes.id}
                nodeId={nodes.id}
                label={nodes.name}
                data-relatedNodeIds={nodes.relatedNodeIds}
                data-parentId={nodes.parentId ? nodes.parentId : null}
                data-cy="cy-kb-categoryTree-item"
                onClick={handleCategoryTreeParentNodeClick.bind(null,{
                    categoryId: nodes.id,
                    parentId: nodes.parentId,
                    relatedNodeIds: nodes.relatedNodeIds,
                    name: nodes.name
                })}
            >
                {Array.isArray(nodes.children) ? nodes.children.map((node) => renderCategoryTree(node)) : null}
            </TreeItem>
        );
    };

    const loadCategoryTree = () => {
        setIsDataStillProcessing((prevState) => ({
            ...prevState,
            categoryTree:true
        }));
        axios.post("/api/v1/support/kb/category/tree").then(result => {
            if (result.status === 200) {
                if( result.data.success && result.data.hasOwnProperty('tree') ) {
                    setCategoryTreeItems( result.data.tree );
                }
            }
            setIsDataStillProcessing((prevState) => ({
                ...prevState,
                categoryTree:false
            }));
        }).catch(e => {
            console.log(e);
            setIsDataStillProcessing((prevState) => ({
                ...prevState,
                categoryTree:false
            }));
        });
    }

    // execute on load
    useEffect(() => {
        loadCategoryTree();
    }, []);

    return (
        <React.Fragment>
            <Typography variant="subtitle2" className={classes.categoryTreeTitle} gutterBottom={true}>
                {t('categories')}
            </Typography>
            <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expandedCategoryTreeItems}
                selected={selectedCategoryTreeItems}
                onNodeToggle={handleCategoryTreeNodeToggle}
                onNodeSelect={handleCategoryTreeNodeSelect}
            >
                {renderCategoryTree(categoryTreeItems.length ? categoryTreeItems[0] : [])}
                {!categoryTreeItems.length &&
                    treeViewSkeletonFilledArray.map(() => Math.round(Math.random() * 99)).map((val) => (
                        <div className={classes.categoryTreeItemSkeletonWrapper}>
                            <Skeleton width={val<50 ? '50%' : val + '%'} height={24} animation="wave">
                            </Skeleton>
                        </div>
                    ))
                }
            </TreeView>
        </React.Fragment>
    );
}

export default CategoryTree;