import { createContext, useContext } from 'react';

export const NotificationContext = createContext({
        counters: {
            unread: 0
        },
        list: [
        ]
});

export function useNotification() {
    return useContext(NotificationContext);
}