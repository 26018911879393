import React from 'react';
//import { makeStyles } from '@material-ui/core/styles';
import ExtendedDataTable from "../../../../Combinations/ExtendedDataTable";
import DetailView  from "./DetailView";

function List(props) {

    return (
        <React.Fragment>
            <ExtendedDataTable
                isOpen={true}
                isDenseTable={false}
                title="Your Databases"
                columns={[
                    {
                      name: "id",
                      label: "Id",
                      options: {
                          display: 'excluded'
                      }
                    },
                    {
                        name: "name",
                        label: "Name",
                        options: {
                            filter: true,
                            sort: true,

                        }
                    },
                    {
                        name: "instance",
                        label: "Instance",
                        options: {
                            filter: true,
                            sort: true,

                        }
                    },
                    {
                        name: "purpose",
                        label: "Purpose",
                        options: {
                            filter: true,
                            sort: false,

                        }
                    },
                ]}
                expandableComponent={DetailView}
                options={{
                    /*
                    serverSide: true,
                    onTableChange: (action, tableState) => {
                        this.xhrRequest('my.api.com/tableData', result => {
                            this.setState({ data: result });
                        });
                    },
                     */
                    onRowClick: function(e) {

                    }
                }}
                data={[
                    [100, "99111_p_mustermann_gmbh_t", "SV-GER-T-SQL", "Testing"],
                    [100, "99111_p_mustermann_gmbh_p02", "SV-GER-P-SQL", "Productive"],

                ]}
            />
        </React.Fragment>
    );
}

export default List;