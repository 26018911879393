import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import {makeStyles } from '@material-ui/core/styles';

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import {Typography} from "@material-ui/core";
import axios from "axios";
import {serialize} from "object-to-formdata";
import {useAppLoadingState} from "../../../../context/appLoadingState";
import AlertDialog from "../../Communication/AlertDialog";
import Tooltip from '@mui/material/Tooltip';
import Timer from "../../Helper/Timer";
import ReactInterval from 'react-interval';

const useStyles = makeStyles({
    addComment: {
        padding:'0 10px 0 10px',
    },
    addCommentInput: {
        flex: 1,
        padding: 5,
    },
    addCommentInputWrapper: {
        position:'relative',
        overflow:'none',
        width:'98%',
        padding: 7,
        margin:'10px 0 0 10px',
        display: 'flex',
        alignItems: 'center',
    },
    addCommentButtonWrapper: {
        margin: '4px 0 5px 10px',
    },
    charCounter: {
        display:'block',
        textAlign:'right',
        color: '#666'
    },
    addCommentButton: {
        marginLeft: 4,
        float: 'right',
        position: 'relative',
        right: 0
    },
});

function AddComment(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const { updateAppLoadingState } = useAppLoadingState();
    const commentLimitTimerId = 'laddcmnt';

    const {
        maxLines,
        maxChars,
        ticketId,
        currentStatusIsResolved,
        onAddCommentComplete,
    } = props;

    const [ comment, setComment] = React.useState('');
    const [ currentCharCount, setCurrentCharCount ] = React.useState(0);

    const timerStatusOnLoad = Timer.check(commentLimitTimerId);
    const [ canPostComment, setCanPostComment] = React.useState(timerStatusOnLoad);

    const [ alertDialogOpenState, setAlertDialogOpenState ] = React.useState(false);
    const [ alertDialogText, setAlertDialogText ] = React.useState('');

    function updatePostState() {
        Timer.remoteCheck(
            '/api/v1/support/ticket/comment/get-post-state',
            commentLimitTimerId,
            {
                ticketId: ticketId,
            },
            function(data){
                if(data.canPost) {
                    Timer.removeTimer(commentLimitTimerId, ticketId);
                    setCanPostComment(true);
                }
            }
        );
    }

    function handleChange(e) {
        let text = e.target.value,
            charCount = text.length;

        setComment(text);
        setCurrentCharCount(charCount);
    }

    function handleCommentBoxKeyPress(e) {
        let text = e.target.value,
            charCount = text.length;

        if ( charCount > maxChars-1 ) {
            e.preventDefault();
        }

        if (e.key === 'Enter' || e.key === 'Backspace') {
            let numberOfLines = (text.match(/\n/g) || []).length + 1;

            if ( numberOfLines > maxLines) {
                e.preventDefault();
            }
        }
    }

    function handleAddComment() {

        if( comment.trim().length>1 ) {

            if (Timer.check(commentLimitTimerId) ) {

                setCanPostComment(false);

                Timer.set(commentLimitTimerId, ticketId,2, function(){
                    setCanPostComment(true);
                });

                setAlertDialogOpenState(false);
                setAlertDialogText('');
                updateAppLoadingState(true);
                axios.post(
                    "/api/v1/support/ticket/comment/add",
                    serialize({
                        ticketId: ticketId,
                        comment: comment.trim(),
                    })
                ).then(result => {
                    updateAppLoadingState(false);
                    if (result.status === 200) {
                        if (result.data.success) {
                            if (typeof onAddCommentComplete === 'function') {
                                setComment('');
                                onAddCommentComplete();
                            }
                        } else {
                            setAlertDialogOpenState(true);
                            setAlertDialogText(result.data.message);
                            setCanPostComment(true);
                        }
                    }
                }).catch(e => {
                    updateAppLoadingState(false);
                });

            } else {

            }
        }
    }

    useEffect(() => {
        if( ticketId ) {
            Timer.maintain(commentLimitTimerId, ticketId, function () {
                setCanPostComment(false);
            });
        }
    }, [ticketId]);

    return (
        <React.Fragment>
            <Grid container className={classes.addComment}>
                <Grid item xs={12}>
                    <Paper component="form" variant="outlined" className={classes.addCommentInputWrapper}>
                        <InputBase
                            id="comment"
                            className={classes.addCommentInput}
                            placeholder={t('comment')}
                            multiline
                            inputProps={{
                                "data-cy": "cy-ticket-addComment-comment-input",
                                'aria-label': t('comment'),
                                autocomplete: 'off',
                                form: {
                                    autocomplete: 'off',
                                },
                            }}
                            value={comment}
                            onChange={handleChange}
                            onKeyPress={handleCommentBoxKeyPress}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} className={classes.addCommentButtonWrapper}>
                    <Grid container>
                        <Grid item xs={6} md={8} lg={8} xl={9}>
                            <Typography component="span" variant="overline" display="block" className={classes.charCounter}>
                                {currentCharCount} / {maxChars}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} lg={4} xl={3}>
                            <Tooltip placement="top-start" arrow disableHoverListener={canPostComment} title={!canPostComment ? 'One comment per 5 minutes is permitted' : null}>
                                <span>
                                    <Button
                                        disabled={!canPostComment}
                                        variant="contained"
                                        color="secondary"
                                        size="small"
                                        className={classes.addCommentButton}
                                        disableElevation
                                        onClick={handleAddComment}
                                        data-cy="cy-ticket-addComment-add-btn"
                                    >
                                        {currentStatusIsResolved ? t('addCommentAndReactivate') : t('addComment')}
                                    </Button>
                                </span>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <AlertDialog
                openState={alertDialogOpenState}
                title="Add Comment"
                text={alertDialogText}
            />
            <ReactInterval
                timeout={1000*30}
                enabled={true}
                callback={updatePostState}
            />
        </React.Fragment>
    );
}

export default AddComment;