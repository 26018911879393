import React from 'react'
import './overrides.css';
import {createTheme, MuiThemeProvider} from "@material-ui/core/styles";
import MUIRichTextEditor, { TMUIRichTextEditorRef, TAsyncAtomicBlockResponse } from 'mui-rte';
import FormHelperText from '@mui/material/FormHelperText';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromHTML, ContentState, convertToRaw } from 'draft-js'
import { useTranslation } from 'react-i18next';
import EditorMediaManager from './EditorMediaManager';
import BackupIcon from '@mui/icons-material/Backup'
import UploadImagePopover from "./CustomControl/ImageUploadControl/ImageUploadControl";

const defaultTheme = createTheme();
Object.assign(defaultTheme, {
    overrides: {
        MUIRichTextEditor: {
            root: {
                minHeight: 250,
            },
            container: {
                // background: '#fff',
                border: '1px solid #ccc',
                borderRadius: 4,
                padding: 5,
                '&:hover, &:focus, &:active': {
                    borderColor: '#1A3A7A'
                }
            },
            editor: {
                display: "block",
                overflow: "auto",
                padding: 10,
                paddingTop: 0,
                minHeight: 250,
                maxHeight: 800,
            },
            toolbar: {
                display: "block",
            },
            placeHolder: {
                // backgroundColor: "#fff",
                paddingLeft: 20,
                width: "94%",
                height: "70%"
            },
            anchorLink: {
                color: "#333333",
                textDecoration: "underline"
            }
        }
    },
});

function RichTextEditor(props) {
    const { t } = useTranslation();
    const {
        validationState,
        setValidationState,
        setHtml,
        defaultValue,
        ticketId
    } = props;

    const [ plainContent, setPlainContent] = React.useState('');
    const [ showHelperText, setShowHelperText] = React.useState(false);
    const [ hasFocus, setHasFocus ] = React.useState(false);
    const [ helperText, setHelperText] = React.useState('');
    const [anchor, setAnchor] = React.useState(null);
    const editorRef = React.useRef(null);

    const htmlToRTE = (html) => {
        const contentHTML = convertFromHTML(html || '');
        const contentState = ContentState.createFromBlockArray(
            contentHTML.contentBlocks,
            contentHTML.entityMap
        );
        return JSON.stringify(convertToRaw(contentState));
    }

    const onEditorChange = event => {
        const htmlContent = stateToHTML(event.getCurrentContent(), {
            entityStyleFn(entity) {
                const entityType = entity.get('type').toLowerCase();
                if (entityType === 'image') {
                    const data = entity.getData();
                    return {
                        element: 'img',
                        attributes: {
                            src: data.url,
                            width: data.width,
                            height: data.height,
                            alignment: data.alignment,
                        },
                        style: {}
                    }
                }
            }
        });
        setPlainContent(event.getCurrentContent().getPlainText());
        htmlContent && setHtml(htmlContent);
    }
    const handleBlur = () => {
        setHasFocus(false);
        setShowHelperText(true);
    }

    const handleOnFocus = () => {
        setHasFocus(true);
    }

    function handleImagePlacement(data)
    {
        editorRef.current?.insertAtomicBlockAsync("IMAGE", data, "Uploading now...");
    }

    function handleFileUpload(file) {
        return new Promise(async (resolve, reject) => {
            const resp = await EditorMediaManager.uploadMedia(
                ticketId,
                file,
                function(data){},
                function(resp){}
            );
            if (!resp) {
                reject();
                return;
            }
            resolve({
                data: {
                    url: '/media/'+resp.data.hashId,
                    width: 300,
                    height: 200,
                    alignment: "left",
                    type: "image"
                }
            })
        })

    }

    React.useEffect(() => {
        let validationState = plainContent.trim().length >= 50;
        setValidationState(validationState);
    }, [plainContent]);

    React.useEffect(() => {
        setHelperText(
            !validationState
                ? 'Minimum length is 50 characters'
                : ''
        );
    }, [validationState]);

    return (
            <MuiThemeProvider theme={defaultTheme}>
                <UploadImagePopover
                    anchor={anchor}
                    onSubmit={(data, insert) => {
                        if (insert && data.file) {
                            handleImagePlacement(
                                handleFileUpload(data.file)
                            );
                        }
                        setAnchor(null)
                    }}
                />
                <div>
                    <MUIRichTextEditor
                        id={props.id}
                        name={props.name}
                        controls={["title", "bold", "italic","underline", "numberList", "bulletList", "link", "media", "upload-image" ]}
                        label={t('description')}
                        onChange={onEditorChange}
                        onFocus={handleOnFocus}
                        onBlur={handleBlur}
                        inlineToolbar={true}
                        defaultValue={htmlToRTE(defaultValue)}
                        ref={editorRef}
                        customControls={[
                            {
                                name: "upload-image",
                                icon: <BackupIcon />,
                                type: "callback",
                                onClick: (_editorState, _name, anchor) => {
                                    setAnchor(anchor);
                                }
                            }
                        ]}
                        draftEditorProps={{
                            handleDroppedFiles: (_selectionState, files) => {
                                if (files.length) {
                                    handleImagePlacement(
                                        handleFileUpload(files[0])
                                    );
                                    return true;
                                }
                                return false;
                            }
                        }}
                    />
                    {<FormHelperText error={true}>{showHelperText && helperText}&nbsp;</FormHelperText>}
                </div>
            </MuiThemeProvider>
    );
}

export default RichTextEditor