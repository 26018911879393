import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import { withTranslation } from 'react-i18next';
import Container from "@material-ui/core/Container";
import Avatar from "@material-ui/core/Avatar";
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Copyright from "../../Layout/Partial/Copyright";
import DefaultLayout from "../Default";
import RouteBuilder from "../../Routing/Layout/Login";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

import LanguageSwitcher from "../../../components/Layout/Partial/LanguageSwitcher";
import cloudiaxLogo from "../../../../src/assets/images/logo_2.svg";
import loginBackground from "assets/images/login-background-2.png";

const styles = theme => ({
    body: {
        backgroundColor: '#008ad1 -moz-linear-gradient(45deg, #0066b2 0%, #00adef 100%) -webkit-linear-gradient(45deg, #0066b2 0%, #00adef 100%) linear-gradient(45deg, #0066b2 0%, #00adef 100%)',
        backgroundAttachment: 'fixed',

    },
    pageBackground: {
        backgroundImage: `url(${loginBackground})`,
        backgroundSize: '162.5rem',
        backgroundColor: "#0e0900",

        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center top',
        width: '100vw',
        minHeight: '100vh',
    },
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        borderRadius: 0,
    },
    link: {
        '&:hover, &:focus': {
            cursor: 'pointer',
        },
    },
    languageSwitcherWrapper: {
        display:'block',
        textAlign:'center'
    }
});

class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
        localStorage.removeItem("ticketListFilters")
    }

    render() {
        const {t, classes} = this.props;

        return (
            <DefaultLayout>
                <div className={classes.pageBackground}>
                <Container component="main" maxWidth="xs">
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar} src={cloudiaxLogo}/>
                        <Typography component="h1" variant="h5">
                            {t('cloudiaxCustomerPortal')}
                        </Typography>
                        <RouteBuilder/>
                    </div>
                    <Box mt={8}>
                    {/* <Copyright /> */}
                    <Typography variant="body2" color="textSecondary" >
                        <Link color="inherit" href={t("legalInformationLink")} target="_blank" rel="noopener noreferrer">
                            {t("legalInformation")}
                        </Link>
                    </Typography>
                    </Box>
                    <Box mt={4} className={classes.languageSwitcherWrapper}>
                        <LanguageSwitcher mini={true} semiTransparent={true}/>
                    </Box>
                </Container>
                </div>
            </DefaultLayout>
        )
    }
}

export default withStyles(styles)(withTranslation()(Login));