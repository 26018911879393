import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import "@fontsource/montserrat/500.css";
import TimelineSvg from "assets/images/timeline_three_steps.svg";

const useStyles = makeStyles((theme) => ({
  timelineImage: {
    width: "100%",
    height: "auto",
  },
  stepsText: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 500,
  },
  stepsContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "-40px",
    marginLeft: "4%", // Same as dataProtectionContainer
  },
  stepItem: {
    flex: 1,
    "&:not(:first-child)": {
      marginLeft: "6px", // Half the margin of the dataProtectionText
    },
    "&:not(:last-child)": {
      marginRight: "6px", // Half the margin of the dataProtectionText
    },
  },
}));

export default function TimelineGraph() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.timelineContainer} marginBottom={2}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={10}>
          <img
            src={TimelineSvg}
            alt="Timeline showing three steps"
            className={classes.timelineImage}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2" className={classes.stepsText}>
            <Trans i18nKey="partnerAgreementInfoStep4" />
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={9} className={classes.stepsContainer}>
          {[
            "partnerAgreementInfoStep1",
            "partnerAgreementInfoStep2",
            "partnerAgreementInfoStep3",
          ].map((stepKey, index) => (
            <Typography
              key={index}
              className={`${classes.stepsText} ${classes.stepItem}`}
              component="div"
              variant="body2"
            >
              {t(stepKey)}
            </Typography>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}
