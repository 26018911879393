import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import {makeStyles} from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import Divider from "@material-ui/core/Divider";
import SearchIcon from "@material-ui/icons/Search";
import Paper from "@material-ui/core/Paper";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    searchbar: {
        margin: '15px 0 25px 0',
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        padding: 10,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));


function SearchBar(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [ inputKeyUpDelayTimer, setInputKeyUpDelayTimer] = React.useState(null);
    const {
        startSearchWhileTyping,
        simpleMode,
        searchButton,
        placeholderText,
        searchInputRef,
        searchFilters,
        setSearchFilters,
        searchInputWasCleared,
        setSearchInputWasCleared,
        setSearchButtonWasClicked,
        setSearchWasInit,
        setArticleList
    } = props;


    // search bar
    const handleSearchInputChange = (event) => {
        let val = event.target.value;
        setSearchFilters((prevState) => ({
            ...prevState,
            query: val
        }));
    }

    const handleSearchInputTextClear = (event) => {
        setSearchFilters((prevState) => ({
            ...prevState,
            query: ''
        }));
        if( searchInputRef ) {
            setSearchInputWasCleared(true);
            searchInputRef.current.focus();
        }
    }

    
    const handleSearchInputKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            if( inputKeyUpDelayTimer ) {
                window.clearTimeout(inputKeyUpDelayTimer);
            }
            handleSearchButtonClick();
        }
        else if( startSearchWhileTyping ) {
            if( inputKeyUpDelayTimer ) {
                window.clearTimeout(inputKeyUpDelayTimer);
            }
            setInputKeyUpDelayTimer(
                window.setTimeout(function(){
                    handleSearchButtonClick();
                }, 600)
            );
        }
    }

    const rollbackFiltersForNewSearch = () => {
        setArticleList({});
        setSearchFilters((prevState) => ({
            ...prevState,
            page: 0,
        }));
    };
    
    const handleSearchButtonClick = (event, newSearch) => {
        setSearchButtonWasClicked(true);
        setSearchWasInit(true);
        if ( typeof simpleMode === 'undefined' || !simpleMode )   
        {
            rollbackFiltersForNewSearch();
        }     
    }

        // category selection is changed
        useEffect(() => {
            if ( typeof simpleMode === 'undefined' || !simpleMode ) {
                handleSearchButtonClick();
            }
        }, [searchFilters.categoryId]);

    // search input is cleared
    useEffect(() => {
        if( searchInputWasCleared ) {
            rollbackFiltersForNewSearch();
            setSearchInputWasCleared(false);
            setSearchWasInit(false);
        }
        if( !searchFilters.query.trim().length ) {
            setSearchWasInit(false);
        }
    }, [searchFilters.query]);

    return (
        <Paper component="form" className={classes.searchbar}>
            <InputBase
                inputRef={searchInputRef}
                className={classes.input}
                placeholder={placeholderText}
                inputProps={{
                    "data-cy": "cy-kb-searchbar-field-input",
                     'aria-label': placeholderText
                }}
                value={searchFilters.query}
                onChange={handleSearchInputChange}
                onKeyDown={handleSearchInputKeyDown}
                endAdornment={
                    <InputAdornment position="end">
                        {searchFilters.query.length > 0 &&
                        <IconButton
                            className={classes.iconButton}
                            aria-label="Clear"
                            data-cy="cy-kb-searchbar-clear-btn"
                            onClick={handleSearchInputTextClear}
                        >
                            <ClearIcon/>
                        </IconButton>
                        }
                    </InputAdornment>
                }
            />
            {searchButton ? searchButton : (searchButton!==null ?
                <React.Fragment>
                    <Divider className={classes.divider} orientation="vertical"/>
                    <IconButton
                        data-cy="cy-kb-searchbar-search-btn"
                        type="button"
                        className={classes.iconButton}
                        aria-label="search"
                        onClick={handleSearchButtonClick}
                    >
                    <SearchIcon />
                    </IconButton>
                </React.Fragment>
                : (
                    <React.Fragment>
                    </React.Fragment>
                ))
            }
        </Paper>
    );
}

export default SearchBar;