import React from 'react';
//import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";

function Customers(props) {

    return (
        <Typography variant="h4" gutterBottom={true}>
            Displaying a list of your Customers
        </Typography>
    );
}

export default Customers;