import React from 'react';
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import { useNotification } from "../../../../context/notification";

import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import UnreadNotification from '@mui/icons-material/Email';
import ReadNotification from '@mui/icons-material/MarkEmailRead';

const useStyles = makeStyles(theme => ({
    notificationBubble: {

    },
    isUnread: {

    },
    isRead: {
        opacity: 0.5
    }
}));

function NotificationMenu(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const { notificationData } = useNotification();

    return (
        <React.Fragment>
            {notificationData.hasOwnProperty('list') && notificationData.list.length === 0 &&
                <Typography variant="h7">
                    No Notifications
                </Typography>
            }
            {notificationData.hasOwnProperty('list') && notificationData.list.length > 0 &&
                <List component="nav" dense>
                    {notificationData.list.map(function (item, index) {
                        return (
                            <ListItemButton
                                dense
                                onClick={null}
                                data-cy={'cy-notifications-item' + index}
                                className={item.isRead ? classes.isRead : classes.isUnread}
                            >
                                <ListItemIcon>
                                    {item.isRead
                                        ? <ReadNotification fontSize="small"/>
                                        : <UnreadNotification fontSize="small"/>
                                    }
                                </ListItemIcon>
                                <ListItemText
                                    primary={item.title}
                                   /* secondary={item.teaserText} */
                                />
                            </ListItemButton>)
                    })}
                    {notificationData.list.length &&
                        <React.Fragment>
                            <Divider/>
                            <ListItemButton
                                dense
                                component={Link}
                                href="/inbox"
                                data-cy={'cy-notification-menu-goto-inbox-item'}
                            >
                                <ListItemText
                                    primary={t('myInbox')}
                                />
                            </ListItemButton>
                        </React.Fragment>
                    }
                </List>
            }
        </React.Fragment>
    );
}

export default React.memo(NotificationMenu);