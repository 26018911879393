import * as React from 'react';
import { SvgIcon as MuiSvgIcon, styled } from '@mui/material';

const SvgIcon = styled(MuiSvgIcon, {
       name: 'ProfileIcon',
       shouldForwardProp: (prop) => prop !== 'fill',
     })(() => ({
        fill: '#fff',
        stroke: '#000',
        strokeLinecap: 'round',
        strokeLinejoin:  'round',
        strokeWidth:  '1.5px',
        strokeMiterlimit: 10
     }));

    SvgIcon.defaultProps = {
        viewBox: '0 0 60 60',
        focusable: 'false',
        'aria-hidden': 'true'
    };

 const ProfileIcon = (props) => {
      return (
              <SvgIcon
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{enableBackground:"new 0 0 60 60"}}
                  {...props}
              >
                   <style type="text/css">
                      .st0{"fill:#FFFFFF;stroke:#000000;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;"}
                  </style>
                  <g>
                      <circle className="st0" cx="30" cy="23.1" r="9.6"/>
                      <path className="st0" d="M43.8,46.5c0-7.6-6.2-13.8-13.8-13.8c-7.6,0-13.8,6.2-13.8,13.8"/>
                  </g>
              </SvgIcon>
      );
     };

export default ProfileIcon;