import React, { useState, useEffect } from 'react';
import axios from "axios";
import { serialize } from "object-to-formdata";
import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { useAppLoadingState } from 'context/appLoadingState';
import AlertDialog from 'components/Shared/Communication/AlertDialog/AlertDialog';
import PasswordField from 'components/Shared/PasswordField/PasswordField';
import PasswordStrengthChecklist from 'components/Shared/PasswordField/PasswordStrengthChecklist/PasswordStrengthChecklist';

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    errorMessage: {
        visibility: 'visible',
        color: "red"
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        '&:hover, &:focus': {
            textDecoration: 'none'
        },
    },
}));

function Reset(props) {
    const classes = useStyles();
    const { updateAppLoadingState } = useAppLoadingState();
    const { t } = useTranslation();

    const [ newPassword, setNewPassword ] = useState("");
    const [ newPasswordRepeat, setNewPasswordRepeat ] = useState("");
    const [ alertDialogState, setAlertDialogState] = useState(false);
    const [ alertDialogText, setAlertDialogText ] = useState('');
    const [ resetSuccessStatus, setResetSuccessStatus] = useState(false);

    // Check if the reset link has expired
    useEffect(() => {
        const otac = props.match.params.hasOwnProperty('otac') ? props.match.params.otac : null;
        axios.post(
            "/api/v1/user/recovery/password/reset-render",
            serialize({
                otac: otac,
            })
        ).then(result => {
            if (result.status === 200) {
                if (!result.data.render) {
                    setAlertDialogState(true);
                    setAlertDialogText(t("resetPasswordLinkExpiredInfo"));
                    setResetSuccessStatus(true);
                } 
            }
        })
    }, [props.match.params.otac]);

    function resetPassword(e) {
        e.preventDefault();
        setAlertDialogState(false);

        if( newPassword !== newPasswordRepeat) {
            setAlertDialogText('Passwords do not match');
            setAlertDialogState(true);

            return
        }

        let otac = props.match.params.hasOwnProperty('otac') ? props.match.params.otac : null;
        updateAppLoadingState(true);


        axios.post(
            "/api/v1/user/recovery/password/reset",
            serialize({
                otac: otac,
                newPassword: newPassword,
                newPasswordRepeat: newPasswordRepeat,
            })
        ).then(result => {
            updateAppLoadingState(false);
            if (result.status === 200) {
                if (result.data.success) {
                    setAlertDialogText('Your password was reset successfully.');
                    setResetSuccessStatus(true);
                } else {
                    setAlertDialogText(result.data.message);
                }
                setAlertDialogState(true);
            }
        }).catch(e => {
            updateAppLoadingState(false);
        });
    }

    function handleAlertDialogClose(){
         if(resetSuccessStatus) {
             props.history.push('/login');
         }
    }

    const resetPasswordForm=(
        <ValidatorForm className={classes.form} onSubmit={resetPassword}>
            <Typography color="textSecondary" gutterBottom={true}>
                {t('resetPasswordStepNewPasswordInfo')}
            </Typography>
            <PasswordField
                autoFocus
                autoComplete="new-password"
                id="newPassword"
                name="newPassword"
                password={newPassword}
                setPassword={setNewPassword}
                isRequired={true}
                label={t('newPassword')}
                showStrengthIndicator={false}
                useTextValidator={true}
            />
            <PasswordField
                autoComplete="new-password"
                id="confirmNewPassword"
                name="confirmNewPassword"
                password={newPasswordRepeat}
                setPassword={setNewPasswordRepeat}
                isRequired={true}
                label={t('confirmNewPassword')}
                useTextValidator={true}
            />
            {newPassword.length > 0 &&
                <PasswordStrengthChecklist
                    password={newPassword}
                    passwordAgain={newPasswordRepeat}
                    callbackHandler={ (isValid)=>{ console.log(isValid); }}
                />}
            <Button
                fullWidth
                variant="contained"
                color="secondary"
                className={classes.submit}
                type="submit"
            >
                {t('reset')}
            </Button>
        </ValidatorForm>
    )

    return (
        <React.Fragment>
            <Typography component="h2" variant="subtitle1" gutterBottom={true}>
                {t('resetPassword')}
            </Typography>
            {resetPasswordForm}
            <AlertDialog
                openState={alertDialogState}
                title="Password Reset"
                text={alertDialogText || "Somehting went wrong."}
                onClose={handleAlertDialogClose}
                buttonText={t('ok')}
            />
        </React.Fragment>
    );
}

export default Reset;



