import React, {useEffect, useRef} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
import { useAppLoadingState } from "../../../../../context/appLoadingState";
import axios from "axios";
import { serialize } from 'object-to-formdata';

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import ArticleListItemSkeletonLoader from "../../../../Shared/ArticleList/ArticleListItemSkeletonLoader";
import ArticleListNoSearchResults from "../../../../Shared/ArticleList/ArticleListNoSearchResults";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    articleListRoot: {
        overflow:'auto',
        maxHeight: '62vh'
    },

}));


function ArticleList(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const browserHistory = useHistory();

    const { updateAppLoadingState, isAppLoading } = useAppLoadingState();
    const [isCurrentlyFetching, setIsCurrentlyFetching] = React.useState(false);

    const articleListWrapperRef = useRef(null)
    const [showArticleListLoadingSkeleton, setShowArticleListLoadingSkeleton] = React.useState(true);
    const [articleListTotalPages, setArticleListTotalPages] = React.useState(0);

    const {
        handleClearSearchFilters,
        setBreadcrumbData,
        setIsDataStillProcessing,
        searchFilters,
        setSearchFilters,

        searchInputWasCleared,
        setSearchInputWasCleared,
        articleList,
        setArticleList,

    } = props;

    const fetchNextArticles = (syncFetching) => {
        if(syncFetching && isCurrentlyFetching) {
            return;
        }
        setShowArticleListLoadingSkeleton(true);
        setIsCurrentlyFetching(true);
        axios.post(
            "/api/v1/support/kb/search/query",
            serialize(searchFilters)
        ).then(result => {
            setShowArticleListLoadingSkeleton(false);
            setIsCurrentlyFetching(false);
            if (result.status === 200) {

                setBreadcrumbData((prevState) => ({
                    ...prevState,
                    data: {
                        ...prevState.data,
                        total: result.data.total,
                        hasNoMatches: result.data.total ? false : true,
                        query: searchFilters.query,
                    }
                }));

                let nextDataset = result.data.list;
                if( nextDataset ) {
                    setArticleListTotalPages(result.data.totalPages);
                    setArticleList((prevState) => ({
                        ...prevState,
                        ...nextDataset
                    }));
                }

                if(result.data.total==1 && searchFilters.articleId ) {
                    let articleTitle = null;
                    for (let n in result.data.list) {
                        articleTitle = result.data.list[n].title;
                    }
                    handleArticleItemClick({
                        articleId: searchFilters.articleId,
                        title: articleTitle
                    });
                }
            }
            setIsDataStillProcessing((prevState) => ({
                ...prevState,
                articleList:false
            }));
        }).catch(e => {
            setIsCurrentlyFetching(false);
            setShowArticleListLoadingSkeleton(false);
            setIsDataStillProcessing((prevState) => ({
                ...prevState,
                articleList:false
            }));
        });
    }

    const handleArticleListScroll = (event) => {
        let scrollArea = event.target;
        if( !isAppLoading ) {
            if (scrollArea.scrollTop+160 > articleListWrapperRef.current.clientHeight - scrollArea.clientHeight) {
                if (articleListTotalPages && searchFilters.page < articleListTotalPages) {
                    setSearchFilters((prevState) => ({
                        ...prevState,
                        page: prevState.page + 1
                    }));
                }
            }
        }
    }

    const handleArticleItemClick = (selectedItem) => {
        browserHistory.push({
            pathname: "/kb/article/"+selectedItem.articleId,
            state: {
                selectionData: {
                    articleId: selectedItem.articleId,
                    articleTitle: selectedItem.title
                }
            }
        });

    }

    // execute whenever pagination position changes
    useEffect(() => {
        if( searchFilters.page>0 ) {
            fetchNextArticles();
        }
    }, [searchFilters.page]);

    // search input is cleared
    useEffect(() => {
        if( searchInputWasCleared ) {
            fetchNextArticles();
            setSearchInputWasCleared(false);
        }
    }, [searchFilters.articleId]);


    useEffect(() => {
        if( searchFilters.page==0 && !Object.keys(articleList).length ) {
            fetchNextArticles(true);
        }
    }, [articleList]);

    return (
        <React.Fragment>
            <List className={classes.articleListRoot} onScroll={handleArticleListScroll}>
                <div ref={articleListWrapperRef}>
                    {Object.keys(articleList).length > 0 &&
                    Object.keys(articleList).map((article, index) => (
                        <ListItem
                            button
                            key={index}
                            articleId={articleList[article].articleId}
                            data-cy="cy-kb-articleList-item"
                            onClick={handleArticleItemClick.bind(null, {
                                title: articleList[article].title,
                                articleId: articleList[article].articleId
                            })}
                        >
                            <ListItemText primary={articleList[article].title}
                                          secondary={articleList[article].description}/>
                        </ListItem>
                    ))
                    }
                    {!showArticleListLoadingSkeleton && !Object.keys(articleList).length &&
                    <ArticleListNoSearchResults handleClearSearchFilters={handleClearSearchFilters}/>
                    }
                    {showArticleListLoadingSkeleton &&
                    <ArticleListItemSkeletonLoader/>
                    }
                </div>
            </List>
        </React.Fragment>
    );
}

export default ArticleList;