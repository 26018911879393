import React, {useState} from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";
import {serialize} from "object-to-formdata";
import {useAppLoadingState} from "../../../../context/appLoadingState";
import AlertDialog from "../../../Shared/Communication/AlertDialog";


const useStyles = makeStyles(theme => ({
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },

    submit: {
        margin: theme.spacing(3, 0, 2),
        '&:hover, &:focus': {
            textDecoration: 'none'
        },
    },
}));

function ForgotPassword(props) {
    const classes = useStyles();
    const { updateAppLoadingState } = useAppLoadingState();
    const { t } = useTranslation();
    const [ username, setUsername ]  = useState("");
    const [ emailSentOpenState, setEmailSentOpenState] = useState(false);
    const alertDialogText= <Trans i18nKey="passwordRecoveryDialogInfo"/>


    function handleInputKeyPress(e){
        if(e.key === 'Enter'){
            e.preventDefault();
            handlePasswordReset();
        }
    }

    function handlePasswordReset() {

        setEmailSentOpenState(false);
        updateAppLoadingState(true);

        if(username.length) {
            axios.post(
                "/api/v1/user/recovery/password/reset-request",
                serialize({
                    username: username,
                })
            ).then(result => {
                updateAppLoadingState(false);
                if (result.status === 200) {
                    setEmailSentOpenState(true);
                }
            }).catch(e => {
                updateAppLoadingState(false);
            });
        } else {
            updateAppLoadingState(false);
        }
    }

    function handleEmailSentClose() {
        setEmailSentOpenState(false);
    }

    return (
        <React.Fragment>
                <Typography component="h2" variant="subtitle1" >
                    {t('passwordRecovery')}
                </Typography>

                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        size="small"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={t('username')}
                        name="username"
                        autoComplete="username"
                        autoFocus
                        value={username}
                        placeholder="c_____.__"
                        inputProps={{
                            "data-cy": "cy-forgot-password-form-field-username",
                        }}
                        onChange={(e) => {
                            setUsername(e.target.value);
                        }}
                        onKeyDown={handleInputKeyPress}
                    />
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <Button
                                component={Link}
                                href="/login"
                                fullWidth
                                variant="text"
                                color="primary"
                                className={classes.submit}
                                data-cy="cy-forgot-password-back-btn"
                            >
                                {t('backToLoginPage')}
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="secondary"
                                className={classes.submit}
                                data-cy="cy-forgot-password-resetPassword-btn"
                                onClick={handlePasswordReset}
                            >
                                {t('resetPassword')}
                            </Button>
                        </Grid>
                    </Grid>
                </form>

            <AlertDialog
                openState={emailSentOpenState}
                title={t("passwordRecovery")}
                text={alertDialogText}
            />
        </React.Fragment>
    );
}

export default ForgotPassword;