import React, {useEffect} from 'react';
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import {TextField as MuiTextField} from "@material-ui/core";

const customTheme = (theme) => createTheme({
    ...theme,
    overrides: {
        MuiTextField: {
            root: {
                width: '100%',
                marginTop: theme.spacing(1)
            }
        },
        MuiInputBase: {
            root: {
                // background: "#fff"
            }
        },
        MuiFormHelperText: {
            root: {
                background: "transparent",
                margin: '3px 0px 0px 0px',
                fontSize: '0.75rem',
                color: "#A50203",
            },
            marginDense: {
                marginTop: 1
            }
        }
    }
});

function TextField(props) {
    const [ isValid, setIsValid ] = React.useState(true);
    const [ errorText, setErrorText ] = React.useState('');
    const [ allowHelperText, setAllowHelperText ] = React.useState(false);

    const {
        id,
        name,
        variant,
        margin,
        label,
        size,
        inputProps,
        value,
        onChange,
        validators
    } = props;

    const handleValidators = (event) => {
        setAllowHelperText(true);
        let rawValue = event ? event.target.value.trim() : value;
        if( typeof validators!=='undefined' && Array.isArray(validators) ) {
            let isValidating = true;
            runValidations:
            for(let i in validators) {
                let validation = validators[i];
                switch (validation.validate) {
                    case 'isRequired':
                        isValidating = !!rawValue.length;
                        setIsValid( isValidating );
                        setErrorText(!isValidating ? validation.errorMessage : '');
                        break runValidations;
                    case 'minLength':
                        isValidating = rawValue.length >= validation.minLength;
                        setIsValid( isValidating );
                        setErrorText(!isValidating ? validation.errorMessage : '');
                        break runValidations;
                }
            }
        }
    }

    useEffect(() => {
        if( !allowHelperText && value) {
            handleValidators();
        }
    }, [value]);

    return (
        <>
            <MuiThemeProvider theme={customTheme}>
                <MuiTextField
                    variant={variant}
                    margin={margin}
                    id={id}
                    inputProps={inputProps}
                    label={label}
                    name={name}
                    size={size}
                    value={value}
                    onChange={onChange}
                    onBlur={()=>setAllowHelperText(true)}
                    onInput={handleValidators}
                    error={!isValid}
                    helperText={ allowHelperText && !isValid ? errorText : ' '}
                />
            </MuiThemeProvider>
        </>
    );
}

export default TextField;