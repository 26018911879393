import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {useAuth} from "../../../context/auth";
import {useAppLoadingState} from "../../../context/appLoadingState";
import {Redirect, useLocation} from "react-router-dom";
import Button from '@material-ui/core/Button';
import PasswordField from "../../Shared/PasswordField";
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import CardMedia from '@material-ui/core/CardMedia';
import { serialize } from 'object-to-formdata';
import axios from 'axios';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {Container, Paper} from "@mui/material";
import ResponseToast from "../../Combinations/ResponseToast";
import TotpValidator from '../../Shared/Authentication/TotpValidator';
import PasswordStrengthChecklist from '../../Shared/PasswordField/PasswordStrengthChecklist';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Advertisement from 'components/Layout/Partial/Advertisement/Advertisement';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    stepper: {
        marginTop: theme.spacing(2),
        background:"transparent"
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    errorMessage: {
        visibility: 'visible',
        color: "red"
    },
    loginOtherOptionsTitle: {
        marginTop: 40
    },
    link: {
        '&:hover, &:focus': {
            cursor: 'pointer'
        },
    },
    listItem: {
        padding: '10px 0'
    },
    mfaQRCode: {
        width: 300,
        minHeight: 150,
        border:'1px solid #333'
    },
    mfaWizardProceedBtn: {
        margin: 10
    }
}));

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Login(props) {
    const classes = useStyles();

    const { t } = useTranslation();
    const browserLocation = useQuery();
    const { authData, updateAuthenticationData } = useAuth();

    const { updateAppLoadingState } = useAppLoadingState();
    const [openEmergencyDialog, setOpenEmergencyDialog ] = useState(false)

    const passwordInputRef = React.useRef(null);

    const [isError, setIsError] = useState(false);
    const [userName, setUserName] = useState(browserLocation.get('username') );
    const [token, setToken] = useState("");
    const [password, setPassword] = useState("");

    const [ newPassword, setNewPassword ] = useState("");
    const [ newPasswordRepeat, setNewPasswordRepeat ] = useState("");
    const [ passwordChangeSuccessState, setPasswordChangeSuccessState ] = useState(null);
    const [ changePasswordFormRequiresTotp, setChangePasswordFormRequiresTotp ] = useState(false);
    const [ changePasswordFailureMessage, setChangePasswordFailureMessage ] = useState("");
    const [ changePasswordTotpValidatorOpenState, setChangePasswordTotpValidatorOpenState ]  = useState(false);

    const [activeStep, setActiveStep] = useState(0);
    const [showMfaWizard, setShowMfaWizard] = useState(false);
    const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);
    const [mfaWizardCompleted, setMfaWizardCompleted] = useState(false);
    const [mfaInitQR, setMfaInitQR] = useState(null);
    const [mfaInitEmergencyCode, setmfaInitEmergencyCode] = useState(null);

    const steps = [ t('loginStep'), t('2ndFactorAuthentication') ];

    useEffect(() => {
        if(!authData.authData.basic)
            setActiveStep(0);
    }, [authData.authData.basic]);

    useEffect(() => {
        if( authData.authData.basic && !authData.authData.mfa ) {
            if( authData.authData.mfaAuthType==2 ) {
                if (!mfaWizardCompleted) {
                    if (authData.authData.hasMfa == false || (authData.authData.hasMfa  && authData.authData.mfaWizardComplete == false) ) {
                        setIsError(false);
                        setShowMfaWizard(true);
                    }
                }
            }
            setActiveStep(1);
        }
    });

    useEffect(() => {
        if(showMfaWizard==true) {
            updateAppLoadingState(true);
            axios.post(
                "/api/v1/authentication/init-user-mfa",
                serialize({
                    mfaAuthType: authData.authData.mfaAuthType
                })
            ).then(result => {
                updateAppLoadingState(false);
                if (result.status === 200) {
                    if( result.data.response.hasOwnProperty('payload') ) {
                        var payload = result.data.response.payload;

                        if( payload.isActivated ) {
                            setmfaInitEmergencyCode(payload.emergencyCode);
                            setMfaInitQR(payload.qrData);
                        }
                    }
                }
            }).catch(e => {
                updateAppLoadingState(false);
            });
        }
    }, [showMfaWizard]);


    function authenticateUser(e){
        e.preventDefault();
        updateAppLoadingState(true);
        axios.post(
            "/api/v1/authentication/authenticate-user",
            serialize({
                username: userName,
                password: password
            })
        ).then(result => {
            updateAppLoadingState(false);

            if (result.status === 200) {
                if(result.data.success) {
                    updateAuthenticationData({
                        authData: {
                            username: result.data.username,
                            mfaAuthType: result.data.mfaAuthType,
                            basic: result.data.success,
                            hasMfa: result.data.hasMfa,
                            mfaWizardComplete: result.data.mfaWizardComplete,
                            mfa: false
                        },
                        isAuthenticated: false
                    });

                    let resp = result.data;

                    switch(resp.mfaAuthType) {
                        case 1:
                            break;
                        case 2:
                            if( result.data.hasMfa!==true || (result.data.hasMfa && !result.data.mfaWizardComplete ) ) {
                                setIsError(false);
                                setShowMfaWizard(true);
                            }
                            break;
                    }

                    setActiveStep(1);
                    setIsError(false);
                }
                else if(result.data.isPasswordExpired) {
                    setIsError(false);
                    setShowResetPasswordForm(true);
                    setChangePasswordFormRequiresTotp(result.data.requireTotp);
                } else {
                    setIsError(true);
                }
            } else {
                setIsError(true);
            }
        }).catch(e => {
            console.log(e);
            updateAppLoadingState(false);
            setIsError(true);
        });
    }

    // change expired or initial password
    function changePasswordSubmit(e) {
        e.preventDefault();
        if(!password.length || newPassword !== newPasswordRepeat) {
            setChangePasswordFailureMessage('Passwords do not match');
            setPasswordChangeSuccessState(false);
            return
        }
        if(changePasswordFormRequiresTotp) {
            setChangePasswordTotpValidatorOpenState(true);
        } else {
            postChangedPassword()
        }
    }

    function handleClose() {
        setOpenEmergencyDialog(false);
    }

    function handleOpenEmergencyCode() {
        
        if( mfaInitEmergencyCode!==null ) {
            setOpenEmergencyDialog(true);
        } else {
            setOpenEmergencyDialog(false);
            setMfaWizardCompleted(true);
            setShowMfaWizard(false);
            setActiveStep(1);
        }
        
    }

    function handleMfaWizardComplete(e) {
        e.preventDefault();
        setOpenEmergencyDialog(false);
        setMfaWizardCompleted(true);
        setShowMfaWizard(false);
        setActiveStep(1);
    }

    function authenticateUserMfa(e){
        e.preventDefault();
        updateAppLoadingState(true);
        axios.post(
            "/api/v1/authentication/authenticate-user-mfa",
            serialize({
                username: userName,
                totp: token,
                mfaAuthType: authData.authData.mfaAuthType
            })
        ).then(result => {
            updateAppLoadingState(false);
            if (result.status === 200) {
                const mfaSuccess = result.data.response.success;
                if(mfaSuccess) {
                    updateAuthenticationData({
                        authData: {
                            ...authData.authData,
                            mfa: mfaSuccess
                        },
                        isAuthenticated: authData.authData.basic && mfaSuccess
                    });
                    setIsError(false);
                } else {
                    setIsError(true);
                }
            } else {
                setIsError(true);
            }
        }).catch(e => {
            console.log(e);
            updateAppLoadingState(false);
            setIsError(true);
        });
    }

    function postChangedPassword(totp='',callback){

        updateAppLoadingState(true);
        setChangePasswordFailureMessage(t('failedToUpdatePasswordPleaseTryAgainLater'));

        axios.post(
            "/api/v1/authentication/renew-password",
            serialize({
                username: userName,
                password,
                newPassword,
                totp,
            })
        ).then(result => {
            updateAppLoadingState(false);
            if (result.status === 200) {
                if (result.data.success) {
                    setPassword('')
                    setNewPassword('');
                    setNewPasswordRepeat('');
                    setPasswordChangeSuccessState(true);
                    setShowResetPasswordForm(false);
                    setChangePasswordFormRequiresTotp(false)
                    if( passwordInputRef.current ) {
                        passwordInputRef.current.focus();
                    }
                } else {
                    if(result.data.message && !result.data.errorCode) {
                        setChangePasswordFailureMessage(result.data.message);
                        setPasswordChangeSuccessState(false);
                    }
                }
                if( typeof callback === 'function' ) {
                    callback(result.data);
                }
            }
        }).catch(e => {
            console.log(e);
            updateAppLoadingState(false);
            setPasswordChangeSuccessState(false);
        });
    }

    function handleBackToLoginClick(){
        updateAppLoadingState(true);
        axios.post("/api/v1/authentication/disconnect-user").then(result => {
            updateAppLoadingState(false);
            if (result.status === 200) {
                updateAuthenticationData({
                    authData: {
                        basic: false,
                        mfa: false
                    },
                    isAuthenticated: false
                });
            }
        }).catch(e => {});
    }


    const passwordForm=(
        <ValidatorForm className={classes.form} onSubmit={authenticateUser} >
            <TextValidator
                variant="outlined"
                size="small"
                margin="normal"
                fullWidth
                id="username"
                autoComplete="username"
                label={t('username')}
                placeholder="c_____.__"
                name="username"
                autoFocus
                inputProps={{
                    "data-cy": "cy-login-form-field-username",
                    autocomplete: 'off',
                }}
                validators={['required']}
                errorMessages={['Username is a required field.']}
                value={userName}
                onChange={e => {
                    setUserName(e.target.value);
                }}
            />

            <PasswordField
                id="password"
                name="password"
                autoComplete="current-password"
                ref={passwordInputRef}

                label={t('password')}
                password={password}
                setPassword={setPassword}
                isRequired={true}
                useTextValidator={true}
            />
            <div className={classes.errorMessage}>{ isError ? t('incorrectUserNameOrPassword')+'.' : '' }</div>
            {/* Commented out until functionality is provided
                <FormControlLabel
                control={<Checkbox value="remember" data-cy="cy-login-form-checkbox-rememberMe" color="primary" />}
                label={t('rememberMe')}
            />
            */}
            <Button
                fullWidth
                variant="contained"
                color="secondary"
                className={classes.submit}
                type="submit"
                data-cy="cy-login-submit-first-step"
            >
                {t('login')}
            </Button>
            <Grid container>
                <Grid item xs>
                    <Link data-cy="cy-login-form-link-forgot-password"
                          href="/login/forgot-password" variant="body2" className={classes.link}>
                        {t('forgotPassword')}?
                    </Link>
                </Grid>
                <Grid item>
                    <Link data-cy="cy-login-form-link-forgot-username"
                          href="/login/forgot-username" variant="body2" className={classes.link}>
                        {t('forgotUsername')}?
                    </Link>
                </Grid>
            </Grid>

            <Grid container  className={classes.loginOtherOptionsTitle} spacing={1}>
                {/* <Grid item xs={12} sm={12}>
                    <Typography variant="subtitle1" className={classes.loginOtherOptionsTitle}>
                        {t('otherOptions')}:
                    </Typography>
                </Grid> */}

                <Grid item xs={12} sm={12}>
                    <Link data-cy="cy-login-form-link-forgot-password"
                          href="/login/change-password" variant="body2" className={classes.link}>
                        {t('changePassword')}
                    </Link>
                </Grid>
                <Grid item xs={12} sm={12} >
                    <Link
                        data-cy="cy-login-form-link-need-access"
                        href="/login/no-login" variant="body2" className={classes.link}>
                        {t('iNeedToHaveAccessButIHaveNoLogin')}
                    </Link>
                </Grid>
                <Grid item x2={12} sm={12}>
                    <Link
                        data-cy="cy-login-form-link-need-help"
                        target="_blank" rel="noopener noreferrer"
                        href={"/login/login-help"}
                    >

                        {t('iNeedHelpLoggingIn')}
                    </Link>
                </Grid>
            </Grid>
        </ValidatorForm>
    )

    const resetPasswordForm=(
        <ValidatorForm className={classes.form} onSubmit={changePasswordSubmit}>
            <Typography gutterBottom={true}>
                {t("passwordMustBeChanged")}
            </Typography>
            <PasswordField
                autoFocus
                autoComplete="new-password"
                id="newPassword"
                name="newPassword"
                password={newPassword}
                setPassword={setNewPassword}
                isRequired={true}
                label={t('newPassword')}
                showStrengthIndicator={false}
                useTextValidator={true}
            />
            <PasswordField
                autoComplete="new-password"
                id="confirmNewPassword"
                name="confirmNewPassword"
                password={newPasswordRepeat}
                setPassword={setNewPasswordRepeat}
                isRequired={true}
                label={t('confirmNewPassword')}
                useTextValidator={true}
            />
            {newPassword.length>0 &&
                <PasswordStrengthChecklist
                    password={newPassword}
                    passwordAgain={newPasswordRepeat}
                    callbackHandler={ (isValid)=>{ console.log(isValid); }}
                />}
            <Button
                fullWidth
                variant="contained"
                color="secondary"
                className={classes.submit}
                type="submit"
            >
                {t('changePassword')}
            </Button>
        </ValidatorForm>
    )

    const mfaForm=(
        <ValidatorForm className={classes.form} onSubmit={authenticateUserMfa}>
            <TextValidator
                variant="outlined"
                size="small"
                margin="normal"
                required
                fullWidth
                id="totp"
                label="TOTP"
                name="totp"
                autoFocus
                inputProps={{
                    "data-cy": "cy-login-form-field-totp",
                    autocomplete: 'off',
                    inputMode: "numeric",
                    maxLength: 6,
                }}
                value={token}
                onChange={(e) => {
                    const newTotp = e.target.value.replace(/\D/g, ""); //ignore everything but numbers
                    setToken(newTotp);
                }}
            />
            <div className={classes.errorMessage}>{ isError ? 'Invalid TOTP Password, please try again.' : '' }</div>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        className={classes.submit}
                        type="submit"
                        data-cy="cy-login-form-submit-second-step"
                    >
                        {t('continue')}..
                    </Button>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Button
                        component={Link}
                        onClick={handleBackToLoginClick}
                        fullWidth
                        variant="text"
                        color="primary"
                    >
                        {t('backToLoginPage')}
                    </Button>
                </Grid>
            </Grid>
        </ValidatorForm>
    )

    const mfaWizard=(
        <>
            <Typography variant="h6">
                Multi-Factor Authentication Setup
            </Typography>
            <ol>
                <li className={classes.listItem}>
                    <Typography variant="body2">
                        Install an Authenticator App on your Smartphone.
                    </Typography>
                </li>
                <li className={classes.listItem}>
                    <Typography variant="body2" gutterBottom={true}>
                        From the Authenticator App, scan the following QR-Code:
                    </Typography>
                    <div>
                        <CardMedia
                            className={classes.mfaQRCode}
                            component="img"
                            src={mfaInitQR}
                        />
                    </div>
                </li>
                <li className={classes.listItem}>
                    <Typography variant="body2">
                        After the new TOTP was added to your Authenticator App

                    </Typography>
                </li>
            </ol>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                    <Button
                        type="button"
                        className={classes.mfaWizardProceedBtn}
                        fullWidth
                        variant="contained"
                        color="secondary"
                        disableElevation
                        onClick={handleOpenEmergencyCode}
                        data-cy="cy-login-submit-second-step2"
                    >
                        Click to proceed
                    </Button>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Button
                        component={Link}
                        onClick={handleBackToLoginClick}
                        fullWidth
                        variant="text"
                        color="primary"
                        className={classes.mfaWizardProceedBtn}
                    >
                        {t('backToLoginPage')}
                    </Button>
                </Grid>
            </Grid>
            <Dialog
                open={openEmergencyDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
                    <Typography variant="h6">EMERGENCY CODE</Typography>
                </DialogTitle>
                <DialogTitle style={{ textAlign: 'center' }}>
                    <Typography variant="body3" style={{ fontWeight: 'bold', marginTop: '100px' }}>
                        {mfaInitEmergencyCode}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{ textAlign: 'center', marginTop: '10px' }}>
                        This is your TOTP emergency Code.
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description" style={{ textAlign: 'center', fontWeight: 'bold' }}>
                        It will not be shown again.
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description" style={{ textAlign: 'center' }}>
                        Make sure to store it somewhere safe. Without it you will not be able to recover your TOTP.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        variant="link"
                        size="small"
                        onClick={handleMfaWizardComplete}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )

    if (authData.isAuthenticated) {
        return <Redirect to="/" />;
    }

    return (
        <React.Fragment>
            <div className={classes.root}>
                <Stepper className={classes.stepper} activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};

                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps} style={{ whiteSpace: 'noWrap' }}>
                                    {label}
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {activeStep === 0 && !showResetPasswordForm && passwordForm}
                {activeStep === 0 && showResetPasswordForm && resetPasswordForm}
                {activeStep === 1 && !showMfaWizard && mfaForm}
                {activeStep === 1 && showMfaWizard && mfaWizard}
            </div>
            {activeStep === 0 && !showResetPasswordForm  && <Advertisement/>}
            <ResponseToast
                key="profilePasswordChangeResponseToast"
                autoHideDuration={3000}
                responseStatus={passwordChangeSuccessState}
                successMessage={t('yourPasswordWasUpdatedSuccessfully')}
                failureMessage={changePasswordFailureMessage}
                closeHandler={() => setPasswordChangeSuccessState(null)}
            />
            <TotpValidator
                openState={changePasswordTotpValidatorOpenState}
                setOpenState={setChangePasswordTotpValidatorOpenState}
                onSubmit={(totp,callback) => postChangedPassword(totp,callback)}
            />
        </React.Fragment>

        /*    Downtime Message Information for Go-Live
        <Container>
            <Paper elevation={3} style={{ padding: '20px', marginTop: '40px', textAlign: 'left' }}>
                <Typography variant="h4" gutterBottom>
                    We'll be right back!
                </Typography>
                <Typography variant="body1" paragraph>
                    We are working intensively to migrate your data to the new Service Portal, this will take some time.
                    <br/>
                    <br/>
                    During this time, Login will not be possible.
                    <br/>
                    <br/>
                    In a case of emergency, please contact our emergency hotline.
                    <br />
                    <br />
                    Thank you for your understanding.
                </Typography>
            </Paper>
        </Container>
         */
    );
}

export default Login;