import React, {useEffect, useRef} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
import { useAppLoadingState } from "../../../../../../../context/appLoadingState";
import axios from "axios";
import { serialize } from 'object-to-formdata';

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import ArticleListItemSkeletonLoader from "../../../../../../Shared/ArticleList/ArticleListItemSkeletonLoader";
import ArticleViewer from "../../../../../../Shared/ArticleList/ArticleViewer";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    articleListRoot: {
        overflow:'auto',
        maxHeight: '65vh'
    },

}));


function SolutionArticleList(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const { updateAppLoadingState } = useAppLoadingState();

    const articleListWrapperRef = useRef(null)
    const [showArticleListLoadingSkeleton, setShowArticleListLoadingSkeleton] = React.useState(false);

    const {
        searchButtonWasClicked,
        setSearchButtonWasClicked,
        searchFilters,
        articleList,
        setArticleList,
        startSearchWhileTyping
    } = props;

    const fetchArticles = () => {
        if(!startSearchWhileTyping)
        {
            setShowArticleListLoadingSkeleton(true);
            updateAppLoadingState(true);
        }
        
        axios.post(
            "/api/v1/support/kb/search/query",
            serialize(searchFilters)
            ).then(result => {
            if(!startSearchWhileTyping){
                setShowArticleListLoadingSkeleton(false);
                updateAppLoadingState(false);
            }
            if (result.status === 200) {
                let nextDataset = result.data.list;

                if (nextDataset === null) {
                    nextDataset = {};
                }
                
                let articleListHasChanged = true;
                if ((Object.keys(nextDataset).length === 0 && Object.keys(articleList).length === 0) || 
                    (Object.keys(nextDataset) === Object.keys(articleList))) {
                        articleListHasChanged = false;
                }

                if(articleListHasChanged) {
                    setArticleList(nextDataset);
                }
            }
        }).catch(e => {
            if(!startSearchWhileTyping){
                setShowArticleListLoadingSkeleton(false);
                updateAppLoadingState(false);
            }
        });
    }

    useEffect(() => {
        if( !searchButtonWasClicked)
            return

        if(searchFilters.query.length>0)
            fetchArticles();
        setSearchButtonWasClicked(false);
    }, [searchButtonWasClicked] );


    const handleArticleItemClick = (selectedItem) => {
        updateAppLoadingState(true);
        axios.post(
            "/api/v1/support/kb/article",
            serialize({
                languageId: 1,
                id: selectedItem.articleId
            })
        ).then(result => {

            if (result.status === 200) {
                let article = result.data,
                    content = article.content ? article.content.replace(/"\/public\/backend\/media\/get\?id=(\d+)"/g, "/api/v1/media/get?id=$1") : null;

                setSelectedArticleId(article.id);
                setSelectedArticleTitle(selectedItem.title);
                setSelectedArticleContent(content);
                setArticleViewDialogOpen(true);
            }
            updateAppLoadingState(false);
        }).catch(e => {
            console.log(e);
            updateAppLoadingState(false);
        });
    }

    const [articleViewDialogOpen, setArticleViewDialogOpen] = React.useState(false);
    const [selectedArticleId, setSelectedArticleId] = React.useState(null);
    const [selectedArticleTitle, setSelectedArticleTitle] = React.useState(null);
    const [selectedArticleContent, setSelectedArticleContent] = React.useState(null);

    // article viewer
    const handleArticleViewDialogClose = (event) => {
        setArticleViewDialogOpen(false);
        setSelectedArticleContent(null);
    }

    return (
        <React.Fragment>
            <List className={classes.articleListRoot}>
                <div ref={articleListWrapperRef}>
                    {Object.keys(articleList).length>0 &&
                    Object.keys(articleList).map((article, index) => (
                        <ListItem
                            button
                            key={index}
                            articleId={articleList[article].articleId}
                            data-cy="cy-solutionSearch-articleList-item"
                            onClick={handleArticleItemClick.bind(null, {
                                title: articleList[article].title,
                                articleId: articleList[article].articleId
                            })}
                        >
                            <ListItemText primary={articleList[article].title}
                                          secondary={articleList[article].description}/>
                        </ListItem>
                    ))
                    }
                    {showArticleListLoadingSkeleton &&
                        <ArticleListItemSkeletonLoader fillerArrayAmount={5}/>
                    }
                </div>
            </List>
            <ArticleViewer
                selectedArticleId={selectedArticleId}
                articleViewDialogOpen={articleViewDialogOpen}
                handleArticleViewDialogClose={handleArticleViewDialogClose}
                selectedArticleTitle={selectedArticleTitle}
                selectedArticleContent={selectedArticleContent}
            />
        </React.Fragment>
    );
}

export default SolutionArticleList;