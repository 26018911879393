import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PartnerFormDocusign from "components/Modules/Forms/PartnerFormDocusign";

function RouteBuilder(props) {
  const { i18n } = useTranslation();
  const location = useLocation();

  // Check if the current path is /partnervertrag and redirect if so
  if (location.pathname === "/partnervertrag") {
    i18n.changeLanguage("de");
    return <Redirect to="/partner-agreement" />;
  }


  const appRouteMapping = [
    {
      route: "/partner-agreement",
      breadcrumb: "Partner Agreement",
      component: () => <PartnerFormDocusign language={i18n.language} />,
    },
    {
      route: "/partnervertrag",
      breadcrumb: "Home",
      component: () => null, // This will be handled by the redirect above
    },
  ];

  return appRouteMapping.map(({ route, exact, component }, key) => {
    return (
      <Route key={route} exact={exact} path={route} component={component} />
    );
  });
}

export default RouteBuilder;
