import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width:'100%'
    },
    wrapper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    paper: {
        width:'40%',
        marginTop: '5%',
        padding: 15
    }
}));


function GeneralPermissionDeniedMessage(props) {
    const classes = useStyles();

    const { t } = useTranslation();
    const { customReason, contactPerson } = props;

    return (
        <React.Fragment>
            <div className={classes.wrapper}>
                <Paper variant="outlined" className={classes.paper}>
                    <Typography variant="h4" gutterBottom={true}>
                        Oops..
                    </Typography>
                    <Typography variant="subtitle1">
                        You do not have permission to view this page or perform this action.
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {customReason ? customReason : 'If you need access'}, please contact {contactPerson ? contactPerson : 'your security manager'}.
                    </Typography>
                </Paper>
            </div>
        </React.Fragment>
    );
}

export default GeneralPermissionDeniedMessage;