import React, {Component} from 'react';
import {BrowserRouter, Switch,Route, Redirect } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import Dashboard from "../Layout/Dashboard";
import Forms from "../Layout/Forms";
import Login from "../Layout/Login";

class MasterRouter extends Component {

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route path="/login" component={Login}/>
                    <Route path="/partnervertrag" component={Forms}/>
                    <Route path="/partner-agreement" component={Forms}/>
                    <PrivateRoute path="/" component={Dashboard}/>
                    <Redirect exact from="" to="/"/>
                </Switch>
            </BrowserRouter>
        );
    }
}

export default MasterRouter;
