import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { createTheme,ThemeProvider, withStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import MUIDataTable from "mui-datatables";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

const extendedDataTableStyles = theme => ({
    defaultTableHeaderStyle: {
        backgroundColor: theme.palette.primary.light,
        color:'#fff',
        fontWeight: 700,
    },
    defaultRowStyle: {
        cursor: 'pointer',
        fontFamily: `'Montserrat',Helvetica,Arial,sans-serif`
    }
});

const mdtTheme = (theme) =>
    createTheme({
        ...theme,
        components: {
            MUIDataTable: {
                styleOverrides: {
                    root: {
                        fontFamily: `'Montserrat',Helvetica,Arial,sans-serif`
                    },
                    paper: {
                        boxShadow: "none",
                    },
                },
            },
            MUIDataTableHeadCell: {
                styleOverrides: {
                    sortAction: {
                        fontWeight: 600,
                        "& path": {
                            fontWeight: 600,
                        },
                    },
                },
            },
            MUIDataTableHeadRow: {
                styleOverrides: {
                    root: {
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                    },
                },
            },
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        "&.MuiTableRow-hover": {
                            cursor: "pointer",
                        },
                    },
                },
            },
            //increase filter overlay width
            MUIDataTableToolbar: {
                styleOverrides: {
                    filterPaper: {
                        width: "650px",
                    },
                },
            },
        },
    });

class ExtendedDataTable extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    getMuiTheme = () => createTheme({
        overrides: {
            MUIDataTable: {
                root: {
                    fontFamily: `'Montserrat',Helvetica,Arial,sans-serif`
                }
            },
            MUIDataTableHeadCell: {
                sortAction: {
                    fontWeight: 700,
                    color: "#fff",
                    '& path': {
                        fontWeight: 700,
                        color: "#fff"
                    },
                },
                sortActive: {
                    fontWeight: 700,
                    color: "#fff"
                }
            },
            MUIDataTableHeadRow: {
                root: {
                  whiteSpace: 'nowrap'
                }
            },

        },
    });

    render() {
        const { t, classes } = this.props,
                $this = this;

        const defaultOptions = {
            elevation: 2,
            print: false,
            download: false,
            viewColumns: false,
            filterType: 'multiselect',
            selectableRows: 'none',
            responsive: 'scrollMaxHeight',
            textLabels: null,
            rowsPerPage: 20,
            rowsPerPageOptions: [5, 10, 15, 20, 30],
            fixedSelectColumn: true,
            setTableProps: () => {
                return {
                    padding: this.props.isDenseTable ? "2px" : "default",
                    size: this.props.isDenseTable ? "small" : "medium",
                };
            },
/*            setRowProps: (value) => {
                return {
                    className: classes.defaultRowStyle
                }
            },
            setCellHeaderProps: (value) => {
                return {
                    className: classes.defaultTableHeaderStyle
                }
            },*/
        };

        defaultOptions.textLabels = {
            body: {
                noMatch: t('extendedDataTable_noMatch'),
                toolTip: "Sort",
                columnHeaderTooltip: column => t('extendedDataTable_sortFor')+` ${column.label}`
            },
            pagination: {
                next: t('extendedDataTable_pagination_next'),
                previous: t('extendedDataTable_pagination_previous'),
                rowsPerPage: t('extendedDataTable_pagination_rowsPerPage'),
                displayRows: t('extendedDataTable_pagination_displayRows'),
            },
            toolbar: {
                search: t('extendedDataTable_search'),
                downloadCsv: t('extendedDataTable_downloadCsv'),
                print: t('extendedDataTable_print'),
                viewColumns: t('extendedDataTable_viewColumns'),
                filterTable: t('extendedDataTable_filterTable'),
            },
            filter: {
                all: t('extendedDataTable_filters_all'),
                title: t('extendedDataTable_filters_title'),
                reset: t('extendedDataTable_filters_reset'),
            },
            viewColumns: {
                title: t('extendedDataTable_viewColumns_title'),
                titleAria: t('extendedDataTable_viewColumns_titleAria'),
            },
            selectedRows: {
                text: t('extendedDataTable_selectedRows_text'),
                delete: t('extendedDataTable_selectedRows_delete'),
                deleteAria: t('extendedDataTable_selectedRows_deleteAria'),
            }
        }

        if( this.props.hasOwnProperty('expandableComponent') && this.props.expandableComponent ) {

            defaultOptions.expandableRows = true;
            defaultOptions.expandableRowsOnClick = true;
            const ExpandableComponent = this.props.expandableComponent;

            defaultOptions.renderExpandableRow = function(rowData, rowMeta) {

                if( $this.props.hasOwnProperty('highlightExpandedRow') && $this.props.highlightExpandedRow ) {
                    let row = document.getElementById(`MUIDataTableBodyRow-${rowMeta.rowIndex}`);
                    row.setAttribute('style', 'background-color:#F5F5F5');
                    row.setAttribute('data-is-expanded', 'true');
                    row.addEventListener('click', function (e) {
                        if (row.getAttribute('data-is-expanded') === 'true') {
                            row.setAttribute('data-is-expanded', 'false');
                            row.setAttribute('style', '');
                        }
                    });
                }

                return (
                    <TableRow>
                        <TableCell colSpan={rowData.length+1}>
                            <ExpandableComponent rowData={rowData}/>
                        </TableCell>
                    </TableRow>
                );
            };
        }

        const columns = this.props.columns.map( function(column){
/*            column.options.setCellHeaderProps = function(value) {
                return {
                    className: classes.defaultTableHeaderStyle
                }
            }*/
            return column;
        } );

        const options = Object.assign({}, defaultOptions, this.props.options);

        return (
                <Grid container>
                    <Box component={Grid} item xs={12}>
                        <ThemeProvider theme={mdtTheme}>
                        <MUIDataTable
                            title={this.props.title}
                            data={this.props.data}
                            columns={columns}
                            options={options}
                        />
                        </ThemeProvider>
                    </Box>
                </Grid>
        );
    }
}

export default withStyles(extendedDataTableStyles)(withTranslation()(ExtendedDataTable));