import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";


const useStyles = makeStyles((theme) => ({
    button: {
        fontSize: '1.25rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
        fontWeight: 500,
        lineHeight: 1.6,
        letterSpacing:'0.0075em',
        textTransform: 'inherit'
    },

}));

function ArticleListNoSearchResults(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const { handleClearSearchFilters } = props;

    return (
      <React.Fragment>
          <ListItem>
              <Typography variant="h4" gutterBottom>
                  {t('yourSearchDidNotYieldAnyResults')}, <Button color="primary" className={classes.button} onClick={handleClearSearchFilters}>{t('clickHereToTryAgain')}</Button>
              </Typography>
          </ListItem>
      </React.Fragment>
    );
}

export default ArticleListNoSearchResults;