import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {usePerm} from "../../../../../../context/permission";
import { useAppLoadingState } from "../../../../../../context/appLoadingState";
import axios from "axios";
import { serialize } from 'object-to-formdata';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SaveIcon from '@material-ui/icons/Save';
import Typography from "@material-ui/core/Typography";
import { useTranslation } from 'react-i18next';
import {
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Paper
} from "@mui/material";


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        width:'40%'
    },
}));

function CreatePartnerEmployee(props) {

    const classes = useStyles();
    const { permData } = usePerm();
    const { updateAppLoadingState } = useAppLoadingState();
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        firstname: "",
        lastname: "",
        email: "",
        phoneNumber: "",
    });
    const[apiResultMessage, setApiResultMessage] = useState('');
    const[open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        axios.post(
            "/api/v1/support/form/create-partner-employee/submit",
            serialize({
                email: formData.email,
                firstName: formData.firstname,
                lastName: formData.lastname,
                phone: formData.phoneNumber,
            })
        ).then(result => {
            updateAppLoadingState(false);
            setFormData({
                firstname: "",
                lastname: "",
                email: "",
                phoneNumber: "",
            });
            setOpen(true);
            setApiResultMessage(result.data.message);

        }).catch(e => {

            updateAppLoadingState(false);
        });

    };
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleReset = () => {
        setFormData({
            firstname: "",
            lastname: "",
            email: "",
            phoneNumber: "",
        });
    };

    useEffect(() => {

    }, []);

    return (
        <Container>
            <Paper sx={{ p: 2, minHeight: "100%" }}>
                <h2>Create a new Partner-Employee</h2>
                <h4>Request a creation of a new Partner-Employee</h4>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                id="firstname"
                                name="firstname"
                                label="Firstname"
                                variant="outlined"
                                inputProps={{ maxLength: 30, minLength: 2 }}
                                value={formData.firstname}
                                onChange={(event) => {
                                    const value = event.target.value.replace(/[^\u00C0-\u1FFF\u2C00-\uD7FF\w\s-]+/ig, "");
                                    handleChange({ target: { name: "firstname", value } });
                                }}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                id="lastname"
                                name="lastname"
                                label="Lastname"
                                variant="outlined"
                                inputProps={{ maxLength: 30 }}
                                value={formData.lastname}
                                onChange={(event) => {
                                    const value = event.target.value.replace(/[^\u00C0-\u1FFF\u2C00-\uD7FF\w\s-]+/ig, "");
                                    handleChange({ target: { name: "lastname", value } });
                                }}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="email"
                                name="email"
                                label="Email"
                                type="email"
                                variant="outlined"
                                inputProps={{ maxLength: 45 }}
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="phoneNumber"
                                name="phoneNumber"
                                label="Phone Number"
                                variant="outlined"
                                value={formData.phoneNumber}
                                inputProps={{ maxLength: 20 }}
                                onChange={(event) => {
                                    const value = event.target.value.replace(/[^0-9+]+/g, "");
                                    handleChange({ target: { name: "phoneNumber", value } });
                                }}
                                helperText="Plus sign (+) and numbers only"
                            />
                        </Grid>

                        <Grid item container justifyContent="right" xs={12}>
                            <Grid item xs={6} sm={4}>
                                <Button type="submit" fullWidth variant="contained" size="large" color="secondary">
                                    Submit Request
                                </Button>
                            </Grid>
                        </Grid>
                        <Dialog
                            onClose={handleClose}
                            open={open}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"Create new Partner Employee"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {apiResultMessage}
                                </DialogContentText>

                                <DialogActions>
                                    <Button onClick={handleClose}>
                                        OK
                                    </Button>
                                </DialogActions>
                            </DialogContent>

                        </Dialog>
                    </Grid>


                </form>



            </Paper>

        </Container>

    );
}

export default CreatePartnerEmployee;