import React, {useEffect, useState} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Box from '@mui/material/Box';
import Typography from "@material-ui/core/Typography";
import LinearProgressBar from '../../LinearProgressBar';
import {useTranslation} from "react-i18next";
import zxcvbn from 'zxcvbn';

const colorScheme = {
    progressBackground: '#E9E9E9',
    progressColor: {
        risky: '#AA4A44',
        poor: '#ec645c',
        weak: '#FF8C00',
        acceptable: '#7FFFD4',
        good: '#AFE1AF',
        strong: '#01a167'
    }
};

const useStyles = makeStyles((theme) => ({
    wrapper: {
      paddingLeft: 2,
      paddingRight: 2
    },
    lengthCounter: {
        color: '#6A6B66',
        fontSize: ['0.75rem', "!important"]
    }
}))

function StrengthIndicator(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        visible,
        toggle,
        password,
        callbackHandler,
    } = props;

    const [progress, setProgress] = React.useState(0);
    const [strengthLabel, setStrengthLabel] = React.useState( t('poor') );
    const [barColor, setBarColor] = React.useState(colorScheme.progressColor.poor);

    useEffect(() => {

        let passwordScoreResult = zxcvbn(password),
            passwordScore = passwordScoreResult.score,
            passwordLength = password.length;

        let re = /(.)\1{4,}/g;
        if( (passwordLength < 9 || passwordScore < 3) && re.test(password) ) {
            passwordScore = 2;
        }

        let strengthScale = passwordScore<2 ? 5 : parseInt(passwordLength) * (passwordLength<2 ? 1 :  passwordLength/2 );

            setProgress(
                Math.min(
                    Math.max(strengthScale, 1),
                    100
                )
            );

            if( passwordScore == 0) {
                setStrengthLabel( t('risky') );
                setBarColor(colorScheme.progressColor.risky);
            }
            else if( passwordScore == 1) {
                setStrengthLabel( t('poor') );
                setBarColor(colorScheme.progressColor.poor);
            }
            else if( passwordScore == 2 ) {
                setStrengthLabel(t('weak') );
                setBarColor(colorScheme.progressColor.weak);
            }
            else if( passwordScore == 3 ) {
                setStrengthLabel( t('acceptable') );
                setBarColor(colorScheme.progressColor.acceptable);
            }
            else if( passwordScore == 4 && passwordLength<13 ) {
                setStrengthLabel( t('good') );
                setBarColor(colorScheme.progressColor.good);
            }
            else if( passwordScore == 4 && passwordLength>13 ) {
                setStrengthLabel( t('strong') );
                setBarColor(colorScheme.progressColor.strong);
            }

            if( typeof callbackHandler === 'function' ) {
                callbackHandler(passwordScore);
            }

    }, [password]);

    return (toggle==null || (toggle==true && password.length>0)) && (
            <Grid container className={classes.wrapper}>
                <Grid item spacing={0} lg={2} md={3} xs={3}>
                    <Box>
                        <Typography component="span" variant="caption" className={classes.lengthCounter}>
                            Length: {password.length}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item spacing={0} lg={10} md={9} xs={9}>
                    <LinearProgressBar
                        progressLabel={strengthLabel}
                        value={progress}
                        background={colorScheme.progressBackground}
                        color={barColor}
                    />
                </Grid>
            </Grid>
    );
}

export default StrengthIndicator;



