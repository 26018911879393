import React, {useEffect} from "react";

import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {useAppLoadingState} from "../../../../../../../context/appLoadingState";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Link, Typography } from "@mui/material";
import AlertDialog from "../../../../../../Shared/Communication/AlertDialog";
import axios from "axios";
import {serialize} from "object-to-formdata";

const useStyles = makeStyles(theme => ({
    affectedUserListHeight: {
        height: 350
    }
}));

function NotificationSubscribe(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const { updateAppLoadingState } = useAppLoadingState();
    const [isSubscribed, setIsSubscribed] = React.useState(false);
    const [alertDialogOpenState, setAlertDialogOpenState] = React.useState(false);

    const {
        ticketId
    } = props;

    function handleOnChange() {
        updateAppLoadingState(true);
        axios.post(
            "/api/v1/support/ticket/notification/subscription/toggle-subscription",
            serialize({
                ticketId: ticketId
            })
        ).then(result => {
            updateAppLoadingState(false);
            if (result.status === 200) {
                const resp = result.data;
                if(resp.success) {
                    setIsSubscribed(resp.isSubscribed);
                    if( !resp.isSubscribed ) {
                        setAlertDialogOpenState(true);
                    } else {
                        setAlertDialogOpenState(false);
                    }
                }
            }
        }).catch(e => {
            updateAppLoadingState(false);
        });

    }

    useEffect(() => {

        if( ticketId ) {
            updateAppLoadingState(true);
            axios.post(
                "/api/v1/support/ticket/notification/subscription/is-subscribed",
                serialize({
                    ticketId: ticketId
                })
            ).then(result => {
                updateAppLoadingState(false);
                if (result.status === 200) {
                    const resp = result.data;
                    setIsSubscribed(resp.isSubscribed);
                }
            }).catch(e => {
                updateAppLoadingState(false);
            });
        }

    }, [ticketId]);


    return (
        <React.Fragment>
            <FormGroup>
                <Switch
                    defaultChecked
                    size="small"
                    title={isSubscribed ? 'You are subscribed to receive E-Mail notifications' : 'Subscribe to E-Mail Notifications'}
                    checked={isSubscribed}
                    onChange={handleOnChange}
                />
            </FormGroup>
            <AlertDialog
                openState={alertDialogOpenState}
                title="E-Mail Notifications"
                text="You are now un-subscribed, and will no longer receive E-Mail updates for this ticket."
            />
        </React.Fragment>
    );

}

export default NotificationSubscribe;