import React from "react";
import { serialize } from 'object-to-formdata';
import LinearProgress from '@material-ui/core/LinearProgress';
import {Box, Typography, Button, ListItem, Link, withStyles, Grid} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import {makeStyles} from "@material-ui/core/styles";
import FileUploadService from '../../../../../../services/ticket/attachment/http.service';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import AlertDialog from "../../../../../Shared/Communication/AlertDialog";
import List from "@material-ui/core/List";

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        width: '100%',
        height: 15,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: "#EEEEEE",
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#1a90ff',
    },
}))(LinearProgress);

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        display: 'block'
    },
    attachmentLink: {
        cursor:'pointer'
    },
    btnUpload: {
        position: 'absolute !important',
        right: '10px'
    },
    standardUploadBtn: {
        background:'#fff'
    },
    fileName: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'inline-block',
        maxWidth: '300px',
        verticalAlign: 'middle'
    },
    uploadMessage: {
        fontWeight: 'bold',
        marginTop: '20px !important',
        color: '#63A84E',
    },
    uploadMessageError: {
        fontWeight: 'bold',
        marginTop: '20px !important',
        color: '#DA4148'
    },
    uploadLimitNoticeRow: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
    }
}));

function UploadFile(props) {
    const classes = useStyles();

    const [uploadData, setUploadData] = React.useState({
        selectedFile: undefined,
        currentFile: undefined,
        currentDeletedFileId: undefined,
        isUploading: false,
        progress: 0,
        message: '',
        showMessage: false,
        isError: false,
        uploadedFilesData: [],
    });

    const {
        deleteNotAllowed,
        disabled,
        displayFormat,
        uploadButtonLabel,
        maxFileUploads,
        maxFileUploadSizeMB,
        onUploadComplete,
        uploadButtonProperties
    } = props;

    /**
     * Handling an attachment delete
     */
    function handleFileDelete(file, event) {

        if( !uploadData.currentDeletedFileId ) {
            setUploadData((prevState) => ({
                ...prevState,
                currentDeletedFileId: file.id
            }));

            FileUploadService.delete(props.ticketId, file.id, function () {
                return FileUploadService.list(props.ticketId).then((files) => {
                    setUploadData((prevState) => ({
                        ...prevState,
                        currentDeletedFileId: null,
                        uploadedFilesData: files.data,
                    }));
                });
            })
        }
    }

    /**
     * Handling an attachment download
     */
    function handleAttachmentDownload(file, event) {
        FileUploadService.download(
            props.ticketId,
            file
        );
    }

    /**
     * Handling an attachment upload
     */
    function handleFileUpload(event) {
        if( uploadData.uploadedFilesData.length == maxFileUploads ) {
            setUploadData((prevState) => ({
                ...prevState,
                message: 'You have reached the maximum allowed limit of '+maxFileUploads+' attachments.',
                showMessage: true,
                isError: true
            }));
            return false;
        }

        let currentFile = event.target.files[0];

        setUploadData((prevState) => ({
            ...prevState,
            selectedFile: currentFile,
            isUploading: true,
            progress: 0,
            showMessage: false,
            isError: false,
            message:''
        }));

        FileUploadService.upload(props.ticketId, currentFile, (event) => {
            setUploadData((prevState) => ({
                ...prevState,
                currentFile: currentFile,
                progress: Math.round((100 * event.loaded) / event.total),
            }));

        })
            .then((response) => {
                if( response.data.maxFilesReached) {
                    event.target.value = '';
                }
                setUploadData((prevState) => ({
                    ...prevState,
                    message: response.data.message,
                    showMessage: response.data.showMessage,
                    isError: !!response.data.maxFilesReached || response.data.isInfected
                }));
                if( typeof onUploadComplete==='function' ) {
                    onUploadComplete();
                }
                return FileUploadService.list(props.ticketId);
            })
            .then((files) => {
                setUploadData((prevState) => ({
                    ...prevState,
                    isUploading: false,
                    selectedFile: null,
                    uploadedFilesData: files.data,
                }));
            })
            .catch((error) => {
                let errMsg = ''
                switch(error.response.status){
                    case 413:
                        errMsg = 'File exceeds the maximum allowed upload size of '+(maxFileUploadSizeMB ? maxFileUploadSizeMB : '2')+'MB per file.';
                        break;
                    default:
                        errMsg = 'Could not upload the file!'
                }
                setUploadData((prevState) => ({
                    ...prevState,
                    progress: 0,
                    isUploading: false,
                    message: errMsg,
                    currentFile: undefined,
                    selectedFile: undefined,
                    showMessage: true,
                    isError: true
                }));
                return error.response.status;
            });

    }

    /**
     * load the list of attachment of a specific ticket
     */
    React.useEffect(() => {
        if ( props.ticketId !== null ) {
            FileUploadService.list(props.ticketId).then((response) => {
                setUploadData((prevState) => ({
                    ...prevState,
                    uploadedFilesData: response.data,
                }));
            });
        }
    }, [props.ticketId]);




    return (
        <React.Fragment>
            {!displayFormat || displayFormat!='compact' ?
                (
                    <Grid container className={classes.root}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Box mb={1}>
                                        <Grid container>
                                            <Grid item justify="flex-start">
                                                <Box>
                                                    <Typography variant="subtitle1">
                                                        Attachments
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item justify="flex-start">
                                                <Box pl={3}>
                                                    {disabled==false &&
                                                        <label htmlFor="btn-upload">
                                                            <input
                                                                id="btn-upload"
                                                                name="btn-upload"
                                                                style={{display: 'none'}}
                                                                type="file"
                                                                data-cy="cy-fileUploader-hidden-upload-btn"
                                                                onChange={handleFileUpload}/>
                                                            <Button
                                                                className={classes.standardUploadBtn}
                                                                variant="outlined"
                                                                size="small"
                                                                component="span"
                                                                startIcon={<AttachFileIcon/>}
                                                                data-cy="cy-fileUploader-addAttachment-btn"
                                                            >
                                                                {uploadButtonLabel ? uploadButtonLabel : 'Add Attachment'}
                                                            </Button>
                                                        </label>
                                                    }
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={12} lg={7} lg={7}>
                                    <TableContainer component={Paper} variant="outlined">
                                        <Table>
                                            <TableBody>
                                                {uploadData.uploadedFilesData &&
                                                uploadData.uploadedFilesData.map((file, index) => (
                                                    <TableRow key={file.id}>
                                                        <TableCell component="th" scope="row" width="60%">
                                                            <Link
                                                                data-cy="cy-fileUploader-uploadedFiles-item"
                                                                component="span" className={classes.attachmentLink} onClick={(event) => handleAttachmentDownload(file, event)}>
                                                                {file.name}
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell align="right" width="40%">
                                                            {
                                                                (deleteNotAllowed &&
                                                                deleteNotAllowed == false) ||
                                                                disabled == false &&
                                                                <IconButton
                                                                    data-cy="cy-fileUploader-uploadedFiles-item-delete-btn"
                                                                    aria-label="delete" size="small"
                                                                    onClick={(event) => handleFileDelete(file, event)}>
                                                                    {uploadData.currentDeletedFileId == file.id
                                                                        ? <CircularProgress size={16}/>
                                                                        : <DeleteIcon fontSize="inherit"/>
                                                                    }
                                                                </IconButton>
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                {uploadData.isUploading && (
                                                    <TableRow key="uploadRow">
                                                        <TableCell component="th" scope="row" width="60%">
                                                            <Link component="span">
                                                                {uploadData.selectedFile  ? uploadData.selectedFile.name : null}
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell align="right" width="40%">
                                                            <Box display="flex" alignItems="center">
                                                                <Box width="100%" mr={1}>
                                                                    <BorderLinearProgress variant="determinate" value={uploadData.progress} />
                                                                </Box>
                                                                <Box minWidth={35}>
                                                                    <Typography variant="body2" color="textSecondary">{`${uploadData.progress}%`}</Typography>
                                                                </Box>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item xs={12} md={12} lg={7} lg={7} className={classes.uploadLimitNoticeRow}>
                                    <Typography variant="body2" align="right">
                                        Maximum file upload size: {maxFileUploadSizeMB ? maxFileUploadSizeMB : '2'} MB
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <React.Fragment>
                        {disabled == false &&
                            <label htmlFor="btn-upload">
                                <input
                                    id="btn-upload"
                                    name="btn-upload"
                                    data-cy="cy-fileUploader-hidden-upload-btn"
                                    style={{display: 'none'}}
                                    type="file"
                                    onChange={handleFileUpload}/>

                                <Button
                                    data-cy="cy-fileUploader-addAttachment-btn"
                                    style={uploadButtonProperties && uploadButtonProperties.style ? uploadButtonProperties.style : {}}
                                    variant={uploadButtonProperties && uploadButtonProperties.variant ? uploadButtonProperties.variant : ''}
                                    color={uploadButtonProperties && uploadButtonProperties.color ? uploadButtonProperties.color : ''}
                                    size="small"
                                    component="span"
                                    disableElevation
                                >
                                    {uploadData.isUploading
                                        ? <CircularProgress size={15} style={{marginRight: 2}}/>
                                        : <AttachFileIcon fontSize="small"/>
                                    }
                                    {uploadButtonLabel ? ' ' + uploadButtonLabel : ''}
                                </Button>
                            </label>
                        }
                    </React.Fragment>
                )
            }
            <AlertDialog
                openState={uploadData.showMessage}
                title="Attachment Upload"
                text={uploadData.message}
                onClose={function(){
                    setUploadData((prevState) => ({
                        ...prevState,
                        message: '',
                        isError: false
                    }));
                }}
            />
        </React.Fragment>
    );

}


export default UploadFile;