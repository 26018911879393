import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {Link} from "react-router-dom";
import Badge from '@mui/material/Badge';

const useStyles = makeStyles(theme => ({
    badge: {
        color:'#fff',
        '& .MuiBadge-badge': {
            right: 42,
            top: 19,
        },
    }
}));

function NavBarDropdownItem(props) {

    const classes = useStyles();

    const hasDropDownMenu = (props.hasOwnProperty('dropdownMenu') && props.dropdownMenu && props.dropdownMenu.hasOwnProperty('id') && props.dropdownMenu.items.length);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const { id, ariaLabel, dropdownMenu, custom } = props;

    const menuIsOpen = Boolean(anchorEl);

    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event) => {
        setAnchorEl(null);
    };
    const handleClick = (item,event,callback) => {
        setAnchorEl(null);
        if( typeof callback === 'function' ) {
            callback();
        }
    }

    return (
      <React.Fragment>
          { typeof custom !== 'undefined' && custom.hasOwnProperty('customType') && custom.customType==='badge' ? (
              <Badge
                  component={IconButton}
                  id={id}
                  data-cy={'cy-' + id}
                  aria-label={ariaLabel}
                  aria-controls={hasDropDownMenu ? dropdownMenu.id : ''}
                  aria-haspopup={hasDropDownMenu}
                  className={classes.badge}
                  color="success"
                  onClick={hasDropDownMenu ? handleMenu : (typeof props.onClick === 'function' ? props.onClick : null)}
                  badgeContent={custom.badgeContent}
                  variant={custom.type='minimal' ? 'dot' : 'standard'}
                  invisible={custom.badgeContent==0}
                  max={50}
              >
                  {props.icon}
              </Badge>
          )
           : (
              <IconButton
                  id={id}
                  data-cy={'cy-' + id}
                  aria-label={ariaLabel}
                  aria-controls={hasDropDownMenu ? dropdownMenu.id : ''}
                  aria-haspopup={hasDropDownMenu}
                  color="inherit"

                  onClick={hasDropDownMenu ? handleMenu : (typeof props.onClick === 'function' ? props.onClick : null)}
              >
                  {props.icon}
              </IconButton>
              )
          }
          {hasDropDownMenu &&
          <Menu
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{vertical: "bottom", horizontal: "center"}}
              transformOrigin={{vertical: "top", horizontal: "center"}}
              keepMounted
              open={menuIsOpen}
              onClose={handleClose}
          >
              {props.dropdownMenu.items.map( function(item, index) {
                  let key = item.id ? item.id : 'navBarDropdownMenu'+index;
                      if(item.hasOwnProperty('element') && item.element) {
                          return item.element;
                      }
                      else {
                          if (item.hasOwnProperty('divider') && item.divider) {
                              return (
                                  <Divider key={key}/>
                              );
                          }
                          if (item.hasOwnProperty('routesTo')) {
                              return (
                                  <MenuItem key={key}
                                            data-cy={'cy-'+key}
                                            component={Link}
                                            to={item.routesTo}
                                            onClick={(event) => handleClose(item, event)}
                                            selected={item.selected || false}
                                  >
                                      {item.caption}
                                  </MenuItem>
                              )
                          } else {
                              return (
                                  <MenuItem key={key} 
                                            data-cy={'cy-'+key}
                                            onClick={(event) => handleClick(item, event, typeof item.onClick === 'function' ? item.onClick : null)}
                                            selected={item.selected || false}
                                  >
                                      {item.caption}
                                  </MenuItem>
                              )
                          }
                      }
                  }
              )}
          </Menu>
          }
      </React.Fragment>
    );
}

export default React.memo(NavBarDropdownItem);