import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import NavBarDropdownItem from '../NavBarDropdownItem';
import Grid from "@material-ui/core/Grid";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import cloudiaxLogo from "assets/images/logo_cloudiax_neg_2.svg";
import {ButtonBase} from "@mui/material";

function NavBar(props) {

    const useStyles = makeStyles(theme => ({
        appBar: {
            width: '100%',
            background: '#0e0900',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: 'left',
            zIndex: theme.zIndex.drawer + 1,
          },
        appBarTitle: {
            marginTop: 12,
            flexGrow: 1,
            fontWeight:300,
            fontSize:20,
            letterSpacing: -1,
            lineHeight: 0,
            verticalAlign: "middle"
        },

        hide: {
            display: 'none',
        },
    }));

    const classes = useStyles();

    return (
            <AppBar
                position="fixed"
                className={classes.appBar}
            >
                <Toolbar>
                    <Grid container>
                        <Grid align="left" item xs={8}>
                            {props.hasOwnProperty('title') && props.title.length &&
                                <Typography variant="h5" className={classes.appBarTitle} noWrap>
                                    <a href={props.logoLinkPath || "/"}>
                                        <img
                                            style={{ marginRight: 8, height: 28, position: 'relative', top: 4 }}
                                            src={cloudiaxLogo}
                                            alt="logo"
                                        />
                                   </a>
                                    {props.title}
                                </Typography>
                            }
                        </Grid>
                        <Grid align="right" item xs={4}>
                        {props.hasOwnProperty('navbarItems') && props.navbarItems.map(({id, ariaLabel, icon, onClick, dropdownMenu, custom}, index) => {
                            let key = 'navbarItems'+index;
                            return (
                                <NavBarDropdownItem
                                    key={key}
                                    id={id}
                                    icon={icon}
                                    ariaLabel={ariaLabel}
                                    dropdownMenu={dropdownMenu}
                                    custom={custom}
                                    onClick={(e)=>{
                                        if(typeof onClick==='function') {
                                            onClick(e);
                                        }
                                    }}
                                />
                            )
                        })
                        }
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
    );
}

export default React.memo(NavBar);