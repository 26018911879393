import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@mui/material/Link";
import "@fontsource/montserrat/500.css";

const useStyles = makeStyles((theme) => ({
  dataProtectionContainer: {
    marginLeft: "4%", // Same as stepsContainer
    marginTop: 12,
  },
  dataProtectionText: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 500,
    fontSize: 12,
    columnCount: 3,
    columnGap: "12px", // Double the margin of the steps
    width: "100%",
    color: theme.palette.text.secondary,
  },
}));

export default function DataProtectionInfo() {
  const classes = useStyles();
  const { i18n } = useTranslation();

  const urlPrivacyPolicyEnglish = "https://www.cloudiax.com/privacy-policy";
  const urlPrivacyPolicyGerman =
    "https://www.cloudiax.com/de/datenschutzerklaerung";

  const privacyPolicyUrl =
    i18n.language === "de" ? urlPrivacyPolicyGerman : urlPrivacyPolicyEnglish;

  return (
    <Box paddingBottom={2}>
      <Grid container>
        <Grid item xs={9} className={classes.dataProtectionContainer}>
          <Typography
            variant="body2"
            component="div"
            className={classes.dataProtectionText}
          >
            <Trans i18nKey="dataProtectionInfo">
              child0
              <Link target="_blank" href={privacyPolicyUrl}>
                child1
              </Link>
              child2
            </Trans>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
