import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from '@material-ui/core/IconButton';
import Stack from '@mui/material/Stack';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import EmailIcon from '@mui/icons-material/Email';
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";
import axios from "axios";
import { parseISO } from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime, format } from 'date-fns-tz';
import {serialize} from "object-to-formdata";
import Divider from '@mui/material/Divider';
import parse from 'html-react-parser';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Link from "@material-ui/core/Link";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from '@material-ui/core/Paper';
import {useAppLoadingState} from "../../../../../../context/appLoadingState";
import ActionLog from "../../../../../Shared/Ticket/HistoryTimeline";
import FileUploadService from "../../../../../../services/ticket/attachment/http.service";
import AddComment from "../../../../../Shared/Ticket/AddComment";
import FileUploader from "../FileUploader";
import ReactInterval from 'react-interval';
import DeleteIcon from "@material-ui/icons/Delete";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ImageIcon from '@material-ui/icons/Image';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DescriptionIcon from '@material-ui/icons/Description';
import GeneralPermissionDeniedMessage from "../../../../../Shared/StaticContainerMessage/GeneralPermissionDeniedMessage";
import AffectedUserList from "./AffectedUserList/AffectedUserList";
import NotificationSubscribe from "./NotificationSubscribe/NotificationSubscribe";

const useStyles = makeStyles((theme) => ({
    root: {
        width:'100%',
        flexGrow: 1
    },
    DetailViewAppBar: {
        position: 'relative',
    },
    detailsWrapper: {
        padding: 5,
        paddingTop: 10
    },
    header: {
        flex: 1,
    },
    tableCell: {
        verticalAlign: 'top',
        border: 0
    },
    tableCellDescription: {
        verticalAlign: 'top',
        border: 0,
        whiteSpace: 'pre-line'
    },
    tableCellLabel: {
        verticalAlign: 'top',
        border: 0,
        fontWeight:600,
        width:160
    },
    detailsLeft: {
        width:'30%',
        verticalAlign:'top',
        border: 0
    },
    detailsCenter: {
        width:'25%',
        verticalAlign:'top',
        border: 0
    },
    detailsRight: {
        width:'45%',
        verticalAlign:'top',
        border: 0
    },
    attachmentList: {
        paddingTop: 0
    },
    attachmentListItem: {
        padding:'0px 4px 0px 4px',
        cursor:'pointer',
        '&:hover': {
            background: '#f0f0f0'
        }
    },
    attachmentListItemSecondary: {
        fontSize: '0.7rem'
    }
}));


function Details(props) {
    const maxFileUploads=20;
    const classes = useStyles();
    const { updateAppLoadingState } = useAppLoadingState();
    const { t } = useTranslation();
    const  ticketId = props.match.params.hasOwnProperty('ticketId') ? props.match.params.ticketId : null;

    const [ isActionLogLoading, setIsActionLogLoading] = React.useState(false);
    const [ isAttachmentListLoading, setIsAttachmentListLoading] = React.useState(false);

    const [ ticketData, setTicketData ] = React.useState({
        ticketCode: null,
        createDate: null,
        lastModified: null,
        affectedUserList: null,
        shortDescription: null,
        description: null,
        currentStatusId: null,
        currentStatus: null,
        currentStatusIsClosed: null,
        ownerUsername: null,
        supportGroup: null,
        currentAssigneeName: null,
        urgency: null
    });
    const currentStatusIsResolved = ticketData.currentStatus === 'Resolved';

    const [ ticketActionLogItems, setTicketActionLogItems] =  React.useState([]);
    const [ attachmentList, setAttachmentList] = React.useState({});
    const [ attachmentCountWithinLimit, setAttachmentCountWithinLimit] = React.useState(false);
    const [ attachmentIdBeingDeleted, setAttachmentIdBeingDeleted] = React.useState(null);
    const [ showPermissionMessage, setShowPermissionMessage] = React.useState( false );

    const loadActionLog = (silent) => {
        if(!silent) {
            setIsActionLogLoading(true);
        }
        axios.post(
            "/api/v1/support/ticket/get-action-log",
            serialize({
                ticketId: ticketId,
            })
        ).then(resp => {
            if (resp.status === 200) {
                setTicketActionLogItems(resp.data);
                setIsActionLogLoading(false);
            }
        }).catch(e => {
        });
    }

    const loadAttachmentList = (hideSpinner=false) => {
        if(!hideSpinner) {
            setIsAttachmentListLoading(true);
        }
        FileUploadService.list(ticketId).then((attachments) => {
            setIsAttachmentListLoading(false);

            if( Object.keys(attachments.data).length < maxFileUploads ) {
                setAttachmentCountWithinLimit(true);
            } else {
                setAttachmentCountWithinLimit(false);
            }

            setAttachmentList(attachments.data);
        });
    }

    const loadTicket = (silent= false) => {
        setShowPermissionMessage(false);
        if(!silent) {
            updateAppLoadingState(true);
        }
        axios.post(
            "/api/v1/support/ticket/record",
            serialize({
                ticketId: ticketId,
            })
        ).then(resp => {
            if (resp.status === 200) {
                if( !resp.data.error ) {
                    setTicketData(resp.data.data);
                    loadActionLog(silent);
                    loadAttachmentList(silent);
                } else {
                    // show no permission error
                    setShowPermissionMessage(true);
                }
                updateAppLoadingState(false);
            }
        }).catch(e => {
            console.log(e);
            updateAppLoadingState(false);
        });
    }

    function handleAttachmentDelete(file) {
        setAttachmentIdBeingDeleted(file.id);
        FileUploadService.delete(ticketId, file.id, function () {
            return FileUploadService.list(props.ticketId).then((files) => {
                setAttachmentIdBeingDeleted(null);
                loadAttachmentList(true);
            });
        })
    }

    function handleAttachmentDownload(file, event) {
        FileUploadService.download(
            ticketId,
            file
        );
    }

    function handleBackAction(event) {
        props.history.goBack();
    }

    function onAddCommentComplete() {
        loadActionLog();
        syncTicketData();
    }

    function syncTicketData() {
        loadTicket(true);
    }

    function renderAttachmentIcon(attachment) {
        if( attachment.hasOwnProperty('type') ) {
            if (attachment.type.indexOf('image') !== -1) {
                return <ImageIcon style={{fontSize: '1.3rem'}}/>
            } else if (attachment.type.indexOf('pdf') !== -1) {
                return <PictureAsPdfIcon style={{fontSize: '1.3rem'}}/>
            } else if (attachment.type.indexOf('doc') !== -1) {
                return <DescriptionIcon style={{fontSize: '1.3rem'}}/>
            } else {
                return <InsertDriveFileIcon style={{fontSize: '1.3rem'}}/>
            }
        } else {
            return <InsertDriveFileIcon style={{fontSize: '1.3rem'}}/>
        }
    }
    function beautifyTimestamp(datetime, onlyDate=false) {
        return datetime ? format(parseISO(datetime), 'dd.MM.yyyy'+(onlyDate?'':' HH:mm:ss') ) : ''
    }

    React.useEffect(() => {
        setTicketData({});
        loadTicket();
    }, []);

    return (
        <React.Fragment>
            <ReactInterval
                timeout={1000*30}
                enabled={true}
                callback={syncTicketData}
            />
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom={true} className={classes.header}>
                        <IconButton
                            data-cy="cy-ticket-details-back-button"
                            color="primary" size="medium" component="span" onClick={handleBackAction}>
                            <BackIcon />
                        </IconButton>
                        {t('ticketDetails')}
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.root}>
                    {(showPermissionMessage===true) ?
                            <GeneralPermissionDeniedMessage/>
                        :
                        <Paper className={classes.detailsWrapper}>
                            <Grid container>
                                <Grid item xs={12} md={12} lg={7}>
                                    <Grid container>
                                        <Grid item xs={12} md={12} lg={7}>
                                            <Table size="small">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell
                                                            className={classes.tableCellLabel}> {t('ticketId')} </TableCell>
                                                        <TableCell
                                                            className={classes.tableCell}> {ticketData.ticketId}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            className={classes.tableCellLabel}> {t('ticketOwner')} </TableCell>
                                                        <TableCell
                                                            className={classes.tableCell}> {ticketData.ownerUsername}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            className={classes.tableCellLabel}> {t('type')} </TableCell>
                                                        <TableCell
                                                            className={classes.tableCell}> {ticketData.type}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            className={classes.tableCellLabel}> {t('urgency')} </TableCell>
                                                        <TableCell
                                                            className={classes.tableCell}> {ticketData.urgency}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            className={classes.tableCellLabel}> {t('createDate')} </TableCell>
                                                        <TableCell
                                                            className={classes.tableCell}>
                                                            {
                                                                ticketData.createDate ? format(parseISO(ticketData.createDate), 'dd.MM.yyyy HH:mm:ss') : '-'
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            className={classes.tableCellLabel}> {t('lastModified')} </TableCell>
                                                        <TableCell
                                                            className={classes.tableCell}>
                                                            {
                                                                ticketData.lastModified ? format(parseISO(ticketData.lastModified), 'dd.MM.yyyy HH:mm:ss') : 'Never'
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            className={classes.tableCellLabel}> {t('affectedUsers')} </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            <AffectedUserList
                                                                areAllUsersAffected={ticketData.areAllUsersAffected}
                                                                affectedUserList={ticketData.affectedUserList}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            className={classes.tableCellLabel}> {t('shortDescription')} </TableCell>
                                                        <TableCell
                                                            className={classes.tableCell}> {ticketData.shortDescription} </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            className={classes.tableCellLabel}> {t('description')} </TableCell>
                                                        <TableCell className={classes.tableCellDescription}>
                                                            <div style={{ whiteSpace: 'initial' }}>
                                                                {
                                                                ticketData.description ? parse(ticketData.description) : ''
                                                            }
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            className={classes.tableCellLabel}> {t('attachments')} </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {isAttachmentListLoading == true ?
                                                                <CircularProgress size={12} color="inherit"/>
                                                                :
                                                                Object.keys(attachmentList).length
                                                                    ?
                                                                    <List className={classes.attachmentList}>
                                                                        {attachmentList.map((attachment, index) => (
                                                                            <ListItem
                                                                                className={classes.attachmentListItem}
                                                                                dense={true}
                                                                                disableGutters={true}
                                                                                divider={index < attachmentList.length-1}
                                                                                data-cy="cy-ticket-details-attachment-download"
                                                                                onClick={(event) => handleAttachmentDownload(attachment, event)}
                                                                            >
                                                                                <ListItemAvatar style={{ minWidth: '40px' }}>
                                                                                    <Avatar style={{ height: '30px', width: '30px' }}>
                                                                                        {renderAttachmentIcon(attachment)}
                                                                                    </Avatar>
                                                                                </ListItemAvatar>
                                                                                <ListItemText
                                                                                    classes={{secondary:classes.attachmentListItemSecondary}}
                                                                                    primary={attachment.name}
                                                                                    secondary={
                                                                                    attachment.uploader
                                                                                        ? 'Added by '+attachment.uploader+' on '+ beautifyTimestamp(attachment.createDate, true)
                                                                                        : 'Added on ' + beautifyTimestamp(attachment.createDate, true)
                                                                                } />
                                                                                <ListItemSecondaryAction>
                                                                                    {
                                                                                        1==2 && // remove attachment delete option for customer (SDE-19)
                                                                                        ticketData.currentStatusIsClosed == false &&
                                                                                        <IconButton
                                                                                            aria-label="delete"
                                                                                            size="small"
                                                                                            data-cy="cy-ticket-details-attachment-delete"
                                                                                            onClick={(event) => handleAttachmentDelete(attachment, event)}
                                                                                        >
                                                                                            {attachmentIdBeingDeleted && attachmentIdBeingDeleted == attachment.id
                                                                                                ?
                                                                                                <CircularProgress
                                                                                                    size={15}/>
                                                                                                : <DeleteIcon
                                                                                                    fontSize="inherit"/>
                                                                                            }
                                                                                        </IconButton>
                                                                                    }
                                                                                </ListItemSecondaryAction>
                                                                            </ListItem>
                                                                        ))}
                                                                    </List>
                                                                    :
                                                                    <span>None</span>
                                                            }
                                                            {attachmentCountWithinLimit == true &&
                                                                <div>
                                                                    <FileUploader
                                                                        deleteNotAllowed={true} // remove attachment delete option for customer (SDE-19)
                                                                        disabled={ticketData.currentStatusIsClosed}
                                                                        displayFormat="compact"
                                                                        ticketId={ticketId}
                                                                        maxFileUploads={maxFileUploads}
                                                                        maxFileUploadSizeMB={10}
                                                                        uploadButtonLabel="Add Attachment (Max. 10MB)"
                                                                        onUploadComplete={() => {
                                                                            loadAttachmentList(true)
                                                                        }}
                                                                        uploadButtonProperties={{
                                                                            variant: 'contained',
                                                                        }}
                                                                    />
                                                                </div>
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Grid>

                                        <Grid item xs={12} md={12} lg={5}>
                                            <Table size="small">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell
                                                            className={classes.tableCellLabel}> {t('status')} </TableCell>
                                                        <TableCell
                                                            className={classes.tableCell}> {ticketData.currentStatus} </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            className={classes.tableCellLabel}>
                                                            <Stack direction="row" alignItems="center" gap={1}>
                                                                <EmailIcon fontSize="inherit"/>
                                                                {t('notifications')}
                                                            </Stack>
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            <NotificationSubscribe
                                                                ticketId={ticketData.ticketId}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={12} lg={5}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <ActionLog
                                                showTitle={false}
                                                isActionLogLoading={isActionLogLoading}
                                                actionLogItems={ticketActionLogItems}
                                            />

                                        </Grid>
                                        <Grid item xs={12}>
                                            {(ticketData.currentStatusIsClosed == false && isActionLogLoading == false) &&
                                                <AddComment
                                                    maxLines={20}
                                                    maxChars={5000}
                                                    ticketId={ticketId}
                                                    currentStatusIsResolved={currentStatusIsResolved}
                                                    onAddCommentComplete={onAddCommentComplete}
                                                />
                                            }
                                        </Grid>
                                        <Grid item xs={12} style={{margin: '4px 10px 5px 10px'}}>

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default Details;


