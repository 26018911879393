import React, {useState,useEffect} from 'react';

import Link from "@material-ui/core/Link";
import {useTranslation} from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';


import adImageGerman from 'assets/images/advertisement-de.jpg';
import adImageEnglish from 'assets/images/advertisement-en.jpg';

const adUrlGerman =
  "https://www.cloudiax.com/de/sap-business-one-cloud/sommer-sonderaktion/?utm_source=service_portal&utm_medium=web&utm_campaign=summer";
const adUrlEnglish =
  "https://www.cloudiax.com/sap-business-one-cloud/summer-special-offer/?utm_source=service_portal&utm_medium=web&utm_campaign=summer";



const useStyles = makeStyles(theme =>  ({
    image: {
        opacity: 0,
        transition: 'opacity 1s',
        position: "absolute",
        top: 70, 
        left: "50%",
        transform: 'translate(100%, 0)',
        borderRadius: "4px",
        borderColor: "#878378",
        borderStyle: "solid",
        borderWidth: "1px",
        [theme.breakpoints.down('sm')]: {
            top: 700, 
            left: "50%",
            transform: 'translate(-50%, 0)',
        },
      },

    visible: {
        opacity: 1
    },
      
    link: {
        '&:hover, &:focus': {
            cursor: 'pointer',
        },
    },
}));


function Advertisement(props) {
    const { t } = useTranslation();
    const classes = useStyles();

    const [showImage, setShowImage] = useState(false);

    useEffect(() => {
      // Use a timeout to control when the image should fade in (e.g., 3000 milliseconds)
      const timeout = setTimeout(() => {
        setShowImage(true);
      }, 500);
  
      return () => clearTimeout(timeout);
    }, []);
            
    const imageSrc= window.localStorage.i18nextLng === "de" ? adImageGerman : adImageEnglish
    const advertisementUrl= window.localStorage.i18nextLng === "de" ? adUrlGerman : adUrlEnglish


    return (
        <Link target="_blank" rel="noopener noreferrer" color="inherit" href={advertisementUrl}> 
            <img 
                src={imageSrc} 
                alt="Advertisement" 
                className={`${classes.image} ${showImage ? classes.visible : ''}`}
            />
        </Link>
    )
}

export default Advertisement;