import { createContext, useContext } from 'react';

export const PermContext = createContext({
    flags: {
        tc: false,
        sm: true,
        psm: true
    }
});

export function usePerm() {
    return useContext(PermContext);
}