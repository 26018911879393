import React, {useEffect} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {serialize} from "object-to-formdata";
import {useAppLoadingState} from "../../../../../context/appLoadingState";
import ResponseToast from "../../../../Combinations/ResponseToast";
import {CopyToClipboard} from "react-copy-to-clipboard";
import LinkIcon from "@material-ui/icons/Link";
import {Markup} from "interweave";
import {useHistory, useLocation} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        width:'100%',
        flexGrow: 1
    },
    DetailViewAppBar: {
        position: 'relative',
    },
    detailsWrapper: {
        padding: 5,
        paddingTop: 10
    },
    header: {
        flex: 1,
    },
    articleViewAppBar: {
        position: 'relative',
    },
    articleViewTitleWrapper: {
        padding: 2,
        borderBottom:'1px solid #eee'
    },
    articleViewTitle: {
        flex: 1,
        marginLeft: theme.spacing(2),
    },
    articleViewContainer: {
        width:'100%',
        maxHeight: '77vh',
        padding: '5px 10px',
        overflow: 'auto'
    },
    backBtn: {
        marginRight: 5
    },
    copyLinkButton: {
        marginRight:15
    }
}));

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function ArticleView(props) {
    const classes = useStyles();
    const { updateAppLoadingState } = useAppLoadingState();
    const { t } = useTranslation();
    const browserHistory = useHistory();

    const selectionData = props.location.state ? props.location.state.selectionData  : {articleId: null, articleTitle:null};

    const queryArticleId = props.match.params.articleId;
    const articleId = queryArticleId ? queryArticleId : (selectionData.articleId ? selectionData.articleId : null);

    const [articleViewTitle, setArticleViewTitle] = React.useState(selectionData.articleTitle);
    const [articleViewContent, setArticleViewContent] = React.useState(null);

    const [ clipboardCopyState , setClipboardCopyState ] = React.useState(null);

    function handleBackAction(event) {
        browserHistory.push('/kb/list');
    }

    const handleCopyArticleLink = (event) => {
        setClipboardCopyState(true);
    }

    const loadArticle = () => {
        updateAppLoadingState(true);
        axios.post(
            "/api/v1/support/kb/article",
            serialize({
                languageId: 1,
                id: articleId
            })
        ).then(result => {

            if (result.status === 200) {
                let article = result.data,
                    content = article.content ? article.content.replace(/"\/public\/backend\/media\/get\?id=(\d+)"/g, "/api/v1/media/get?id=$1") : null;

                setArticleViewContent(content);

                if(!articleViewTitle) {
                    setArticleViewTitle(article.translatedQuestionTitle);
                }
            }
            updateAppLoadingState(false);
        }).catch(e => {
            updateAppLoadingState(false);
        });
    }

    useEffect(() => {
        if( articleId ) {
            loadArticle();
        }
    }, [articleId]);


    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container className={classes.articleViewTitleWrapper}>
                        <Grid item xs={11}>
                            <Typography variant="h6" className={classes.articleViewTitle}>
                                <IconButton
                                    className={classes.backBtn}
                                    data-cy="cy-ticket-details-back-button"
                                    color="primary" size="medium" component="span" onClick={handleBackAction}>
                                    <BackIcon/>
                                </IconButton>
                                {articleViewTitle}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <CopyToClipboard text={window.location.host + '/kb/article/' + articleId}
                                             onCopy={handleCopyArticleLink}>
                                <IconButton
                                    color="inherit"
                                    aria-label="Copy Link"
                                    edge="end"
                                    size="medium"
                                    component="span"
                                    className={classes.copyLinkButton}
                                    data-cy="cy-kb-articleViewer-copyLink-btn"
                                >
                                    <LinkIcon/>
                                </IconButton>
                            </CopyToClipboard>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.root}>
                    <Grid container>
                        <Grid className={classes.root}>
                            <div className={classes.articleViewContainer}>
                                <Markup content={articleViewContent}/>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <ResponseToast
                key="articleViewerCopyToClipboardToast"
                autoHideDuration={2000}
                responseStatus={clipboardCopyState}
                closeHandler={function(){setClipboardCopyState(null)}}
                successMessage={t('linkWasSuccessfullyCopiedToClipboard')}
                failureMessage={t('linkWasCopyToClipboardHasFailed')}
            />
        </React.Fragment>

    );
}

export default ArticleView;


