import React from "react";
import { useTranslation } from "react-i18next";
import "@fontsource/barlow-condensed/700.css";
import { Container, Toolbar, Typography } from "@material-ui/core";
import TimelineGraph from "./TimelineGraph/TimelineGraph";
import DataProtectionInfo from "./DataProtectionInfo/DataProtectionInfo";

function PartnerFormDocusign() {
  const { t, i18n } = useTranslation();

  const urlGerman =
    "https://eu.services.docusign.net/webforms-ux/v1.0/forms/4c58f34b22ee757c58f885a86f2b2740";
  const urlEnglish =
    "https://eu.services.docusign.net/webforms-ux/v1.0/forms/4ae321b8015b856c1577ce419aa28ec3";
  const docusingUrl = i18n.language === "de" ? urlGerman : urlEnglish;

  return (
    <div>
      <Container
        component="main"
        maxWidth="lg"
        sx={{ my: 2, overflow: "hidden" }}
      >
        <Toolbar />
        <Typography
          variant="h4"
          style={{
            fontWeight: 700,
            fontFamily: "Barlow Condensed, sans-serif",
            letterSpacing: -1,
            textTransform: "uppercase",
            marginTop: 16,
            fontSize: "2.125rem",
            color: "rgba(0, 0, 0, 0.87)",
          }}
        >
          {t("partnerAgreementTitle")}
        </Typography>

        <TimelineGraph />
      </Container>

      <div style={{ width: "100vw", height: "800px", overflow: "hidden" }}>
        <iframe
          src={docusingUrl}
          style={{
            border: "none",
            overflow: "hidden",
            width: "99vw",
            height: "100%",
          }}
          title="Partner-agreement-form"
        />
      </div>

      <Container maxWidth="lg" sx={{ py: 2, overflow: "hidden" }}>
        <DataProtectionInfo />
      </Container>
    </div>
  );
}

export default PartnerFormDocusign;
