import React from "react";
import { serialize } from 'object-to-formdata';
import LinearProgress from '@material-ui/core/LinearProgress';
import {Box, Typography, Button, Link, withStyles, Grid} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import MediaManagementService from 'services/ticket/media/http.service';


class EditorMediaManager  {

    uploadMedia(ticketId, file, onUpload, onComplete) {
        let resp = MediaManagementService.upload(ticketId, file, (event) => {
            if( typeof onUpload === 'function') {
                onUpload({
                    progress: Math.round((100 * event.loaded) / event.total)
                });
            }
        });
/*        .then((resp) => {
            if( typeof onComplete === 'function' ) {
                onComplete(resp);
            }
            if(resp.data.success==true) {
                return this.getMedia(ticketId, resp.data.insertId);
            } else {
                return null;
            }
        });*/
        return resp;
    }

    getMedia(ticketId, id) {
        return MediaManagementService.get(
            ticketId,
            id
        );
    }

    deleteMedia(file, ticketId) {
        return MediaManagementService.delete(ticketId, file.id, function () {});
    }
}


export default new EditorMediaManager();