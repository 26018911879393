import React, {useEffect} from 'react';
import {  makeStyles } from '@material-ui/core/styles';
import ExtendedDataTable from "../../../../../Combinations/ExtendedDataTable";
import { Container, Paper, Stack, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import { useAppLoadingState } from "../../../../../../context/appLoadingState";
import { usePerm } from "../../../../../../context/permission";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { Chip } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import GeneralPermissionDeniedMessage from "../../../../../Shared/StaticContainerMessage/GeneralPermissionDeniedMessage";
import { convertDateStringToObject } from "../../../../../Shared/Helper/Date";
import {  parseISO } from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime, format } from 'date-fns-tz';
import {useHistory} from "react-router";
import Button from '@material-ui/core/Button';
import ClearIcon from "@material-ui/icons/Clear";


const useStyles = makeStyles(theme => ({
    header: {
        flex: 1,
        paddingBottom: 0
    },
    statusTypographyActive: {
        fontWeight: 600,
        color:'#357a38'
    },
    resetButton: {
        textTransform: 'none' 
    }
}));

function List(props) {
    const classes = useStyles();
    const history = useHistory()
    const { permData } = usePerm();
    const { updateAppLoadingState } = useAppLoadingState();
    const {t} = useTranslation();

    const [ticketData, setTicketData] = React.useState([]);
    const [ ticketId , setTicketId] = React.useState( null );

    const storageKey="ticketListFilters"
    const storedTableData=JSON.parse(localStorage.getItem(storageKey));
    const initialFilters = { status: ["Active", "Pending", "Resolved", "Postponed"],}
    const defaultFilterList=[[], [], [], [], [], [], [], [], initialFilters.status, [], [], [], [], []]; // must correspond to colöumn order of ticketData
    const haveFiltersChanged = storedTableData && JSON.stringify(storedTableData?.filterList) !== JSON.stringify(defaultFilterList);

    const defaultSortOrder={name: "lastUpdateDate", direction: "desc"};
    const [ showResetFilterButton, setShowResetFilterButton ] = React.useState(false)

    useEffect(() => {
        updateAppLoadingState(true);
        if( (permData.flags.tc!=null && permData.flags.sm!=null && permData.flags.psm!=null) ) {
            updateAppLoadingState(false);
        }
        fetchTicketData();
    }, [permData.flags]);


    function fetchTicketData() {
        if( permData.flags.tc==true || permData.flags.sm==true || permData.flags.psm==true ) {
            updateAppLoadingState(true);

            axios.post("/api/v1/support/ticket/list").then(result => {
                updateAppLoadingState(false);
                if (result.status === 200) {
                    setTicketData(result.data);
                }
            }).catch(e => {
                updateAppLoadingState(false);
            });
        }
    }

    function resetFilters(){
        const defaultTableData = {
            ...storedTableData,
            sortOrder: defaultSortOrder,
            filterList: defaultFilterList,
        }
        localStorage.setItem(storageKey, JSON.stringify(defaultTableData))
        setShowResetFilterButton(false); // causes rerender, resulting in reset to defaultSortOrder and defaultFilterList in this component
    }

    const renderResetButton = showResetFilterButton && (
        <Button
            edge="end"
            size="small"
            startIcon={<ClearIcon />}
            onClick={resetFilters}
            className={classes.resetButton}
            // style={{ textTransform: 'none' }}
        >
            {t('resetFiltersAndSorts')}
        </Button>
    );

    return (
        <React.Fragment>
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                sx={{ mb: 2 }}
            >
                <Typography variant="h4" className={classes.header}>
                    {t('myTickets')}
                </Typography>

                <Tooltip title={t("createANewTicket")}>
                    <Fab
                        color="primary"
                        onClick={() => history.push('/support/get-help')}
                        size="small"
                        sx={{ mr: 2 }}
                    >
                        <AddIcon />
                    </Fab>
                </Tooltip>
            </Stack>

            {(permData.flags.tc===false && permData.flags.sm===false && permData.flags.psm===false) &&
                <GeneralPermissionDeniedMessage/>
            }
            {(permData.flags.tc===true || permData.flags.sm===true || permData.flags.psm===true) &&
                <ExtendedDataTable
                    isOpen={true}
                    isDenseTable={false}
                    title={<div>{renderResetButton}</div>}
                    columns={[
                        {
                            label: t("urgency"),
                            name: "urgency",
                            options: {
                                filter: false,
                                sort: false,
                                sortDescFirst: true,
                                customHeadLabelRender: () => " ",
                                customBodyRender: (urgency, tableMeta, updateValue) => {
                                    const urgencyColors = {
                                        Low: "info",
                                        Medium: "warning",
                                        High: "error",
                                    };
                                    const urgencyIcons = {
                                        Low: KeyboardArrowDownIcon,
                                        Medium: DragHandleIcon,
                                        High: KeyboardArrowUpIcon,
                                    };
                                    const urgencyValue = urgency?.name;
                                    const iconColor = urgencyColors[urgencyValue] || "info";
                                    const UrgencyIcon = urgencyIcons[urgencyValue] || DragHandleIcon;
                                    return (
                                        <Tooltip title={t("urgency") + ": " + urgencyValue}>
                                            <UrgencyIcon size="small" color={iconColor}/>
                                        </Tooltip>
                                    );
                                },
                                sortCompare: (order) => {
                                    return (a, b) => {
                                        const urgencyScore = { Low: 0, Medium: 1, High: 2 };
                                        if (a.data === null) {
                                            return 1 * (order === "desc" ? 1 : -1);
                                        }
                                        if (b.data === null) {
                                            return -1 * (order === "desc" ? 1 : -1);
                                        }
                                        if (a.data === b.data) return 0;

                                        return (
                                            (urgencyScore[a.data] < urgencyScore[b.data] ? 1 : -1) *
                                            (order === "desc" ? 1 : -1)
                                        );
                                    };
                                },
                            },
                        },
                        {
                          name: "ticketId",
                          label: "Ticket ID",
                          options: {
                              ...(haveFiltersChanged ? {} :  {filterList: []}),
                              filter: true,
                              sort: true,
                          }
                        },
                        {
                            name: "ticketType",
                            label: "Type",
                            options: {
                                ...(haveFiltersChanged ? {} :  {filterList: []}),
                                filter: true,
                                sort: true,
                            }
                        },
                        {
                            name: "shortDescription",
                            label: t('shortDescription'),
                            options: {
                                filter: false,
                                sort: false,
                            }
                        },
                        {
                            name: "createDate",
                            label: t('createDate'),
                            options: {
                                ...(haveFiltersChanged ? {} :  {filterList: []}),
                                filter: true,
                                sort: true,
                                customBodyRender: (value, tableMeta, updateValue) => {
                                    return (
                                        <div>
                                            { value ? format(parseISO(value), 'dd.MM.yyyy HH:mm:ss') : '-'}
                                        </div>
                                    );
                                },
                            }
                        },
                        {
                            name: "ownerUsername",
                            label: t("ticketOwner"),
                            options: {
                                ...(haveFiltersChanged ? {} :  {filterList: []}),
                                filter: true,
                                sort: true,
                            },
                          },
                        {
                            name: "lastUpdateDate",
                            label: t('lastModified'),
                            options: {
                                ...(haveFiltersChanged ? {} :  {filterList: []}),
                                filter: true,
                                sort: true,
                                customBodyRender: (value, tableMeta, updateValue) => {
                                    return (
                                        <div>
                                            { value ? format(parseISO(value), 'dd.MM.yyyy HH:mm:ss') : '-'}
                                        </div>
                                    );
                                },
                            }
                        },
                        {
                            name: "description",
                            label: t('description'),
                            options: {
                                display: 'excluded',
                                filter: false,
                                sort: false,
                            }
                        },
                        {
                            name: "status",
                            label: t('status'),
                            options: {
                                ...(haveFiltersChanged ? {} : {filterList: initialFilters.status}),
                                filter: true,
                                sort: true,
                                customBodyRender: (value, tableMeta, updateValue) => {
                                    let styleMap = {
                                        'Active': {color:'success', variant:'filled'},
                                        'Pending':  {color:'primary', variant:'filled'},
                                        'Postponed':  {color:'primary', variant:'outlined'},
                                        'Resolved':  {color:'primary', variant:'outlined'},
                                        'Closed':  {color:'success', variant:'outlined'},
                                    };
                                    return <Chip label={value} size="small" color={styleMap[value]?.color || 'error'} variant={styleMap[value]?.variant || "outlined"} />;
                                },
                                filterOptions: {
                                    names: ['Active','Pending','Postponed','Resolved','Closed'],
                                  },
                              }
                        },
                        {
                            name: "priority",
                            label: t('priority'),
                            options: {
                                display: 'excluded',
                                filter: false,
                                sort: true,
                            }
                        },
                        {
                            name: "company",
                            label: t('affectedCompany'),
                            options: {
                                ...(haveFiltersChanged ? {} :  {filterList: []}),
                                filter: true,
                                sort: true,
                            }
                        },
                        {
                            name: "affectedUsers",
                            label: t('affectedUsers'),
                            options: {
                                display: 'excluded',
                                filter: false,
                                sort: false,
                            }
                        },
                        {
                            name: "supportGroup",
                            label: t('supportGroup'),
                            options: {
                                display: 'excluded',
                                filter: false,
                                sort: false,
                            }
                        },
                        {
                            name: "assignee",
                            label: t('assignee'),
                            options: {
                                display: 'excluded',
                                filter: false,
                                sort: false,
                            }
                        }
                    ]}
                    highlightExpandedRow={false}
                    options={{
                        responsive:'vertical',
                        storageKey,
                        sortOrder: storedTableData?.sortOrder || defaultSortOrder,
                        onTableChange: (action, tableState) => {        
                            const haveFiltersChanged = JSON.stringify(tableState.filterList) !== JSON.stringify(defaultFilterList);
                            const hasSortOrderChanged = JSON.stringify(tableState.sortOrder) !== JSON.stringify(defaultSortOrder);
                            setShowResetFilterButton(haveFiltersChanged || hasSortOrderChanged) 
                        },
                        onRowClick: function(row) {
                            setTicketId(row[1]);
                            props.history.push('/support/ticket/details/'+row[1]);
                        }
                    }}
                    data={ticketData}
                />
            }
        </React.Fragment>
    );
}

export default List;