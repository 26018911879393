import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import LinkIcon from '@material-ui/icons/Link';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from '@material-ui/core/DialogContentText';


import { Markup } from 'interweave';
import ResponseToast from "../../../Combinations/ResponseToast";
import { useTranslation } from 'react-i18next';
//import Slide from '@material-ui/core/Slide';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width:'100%'
    },
    dialog: {
        height:'100%'
    },
    articleViewAppBar: {
        position: 'relative',
    },
    articleViewTitle: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    copyLinkButton: {
        marginRight:15
    }
}));


function ArticleViewer(props) {
    const classes = useStyles();
    const {
        articleViewDialogOpen,
        handleArticleViewDialogClose,
        selectedArticleId,
        selectedArticleTitle,
        selectedArticleContent,
    } = props;

    const { t } = useTranslation();

    const [ clipboardCopyState , setClipboardCopyState ] = React.useState(null);

/*    const ArticleViewDialogTransition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });*/


    const handleCopyArticleLink = (event) => {
        setClipboardCopyState(true);
    }

    return (
        <React.Fragment>
            <Dialog
                className={classes.dialog}
                maxWidth="xl"
                fullWidth
                open={articleViewDialogOpen}
                // TransitionComponent={ArticleViewDialogTransition}
                onClose={handleArticleViewDialogClose}
                scroll="paper"

            >
                <AppBar className={classes.articleViewAppBar}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.articleViewTitle}>
                            {selectedArticleTitle}
                        </Typography>


                        <CopyToClipboard text={window.location.host+'/kb/search?articleId='+selectedArticleId}
                                            onCopy={handleCopyArticleLink}>
                            <Button
                                component={IconButton}
                                edge="end"
                                color="inherit"
                                aria-label="Copy Link"
                                className={classes.copyLinkButton}
                                data-cy="cy-kb-articleViewer-copyLink-btn"
                            >
                                <LinkIcon />
                            </Button>
                        </CopyToClipboard>
                        <IconButton
                            data-cy="cy-kb-articleViewer-closeArticle-btn"
                            edge="end" color="inherit" onClick={handleArticleViewDialogClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent dividers >
                    
                    <DialogContentText
                        tabIndex={-1}
                    >
                        <Markup content={selectedArticleContent} />
                    </DialogContentText>

                </DialogContent>
            </Dialog>
            <ResponseToast
                key="articleViewerCopyToClipboardToast"
                autoHideDuration={2000}
                responseStatus={clipboardCopyState}
                closeHandler={function(){setClipboardCopyState(null)}}
                successMessage={t('linkWasSuccessfullyCopiedToClipboard')}
                failureMessage={t('linkWasCopyToClipboardHasFailed')}
            />
        </React.Fragment>
    );
}

export default ArticleViewer;