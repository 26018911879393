import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
}));

function Breadcrumbs(props) {
   // const classes = useStyles();
    const { t } = useTranslation();
    const {
        searchFilters,
        breadcrumbData,
        setBreadcrumbData,
        handleClearSearchFilters
    } = props;


    const updateBreadcrumbs = () => {
        let breadcrumbs = '',
            articleId = breadcrumbData.data.articleId,
            searchHasNoMatches = breadcrumbData.data.hasNoMatches,
            articleTextSelection = breadcrumbData.data.total > 1 ? t('bcTheArticles') : t('article');

        if ( !breadcrumbData.data.categoryName ) {
            if( articleId && articleId.length ) {
                breadcrumbs = t('bcListingAndDisplayingTheSelectedArticle')+' '+articleId;
            } else {
                if ( !breadcrumbData.data.query ) {
                    if ( searchHasNoMatches ) {
                        breadcrumbs = '';
                    } else {
                        breadcrumbs = t('bcListingAllAvailable')+' '+breadcrumbData.data.total+' '+articleTextSelection+'.';
                    }
                } else {
                    if ( searchHasNoMatches ) {
                        breadcrumbs = t('bcNoSearchResultsFor')+' "' + breadcrumbData.data.query + '"';
                    } else {
                        breadcrumbs = t('bcListingOf')+' '+breadcrumbData.data.total+' '+articleTextSelection+' '+t('bcForSearch')+' "' + breadcrumbData.data.query + '"';
                    }
                }
            }
        } else {
            if ( !breadcrumbData.data.query ) {
                if ( searchHasNoMatches ) {
                    breadcrumbs = t('bcNoSearchResultsRelatedTo')+' "'+breadcrumbData.data.categoryName+'"';
                } else {
                    breadcrumbs = t('bcListingOf')+' '+breadcrumbData.data.total+' '+articleTextSelection+' '+t('bcRelatedTo')+' "'+breadcrumbData.data.categoryName+'"';
                }
            } else {
                if ( searchHasNoMatches ) {
                    breadcrumbs = t('bcNoSearchResultsFor')+' "' + breadcrumbData.data.query + '" '+t('bcRelatedTo')+' "'+breadcrumbData.data.categoryName+'"';
                } else {
                    breadcrumbs = t('bcListingOf')+' '+breadcrumbData.data.total+' '+articleTextSelection+' '+t('bcForSearch')+' "' + breadcrumbData.data.query + '" '+t('bcRelatedTo')+' "'+breadcrumbData.data.categoryName+'"';
                }
            }
        }

        setBreadcrumbData((prevState) => ({
            ...prevState,
            text: breadcrumbs

        }));
    }

    // update breadcrumbs whenever search data is changed
    useEffect(() => {
        updateBreadcrumbs();
    }, [breadcrumbData.data.total]);

    return (
        <React.Fragment>
            <Grid item xs={10}>
                <Typography variant="subtitle2" gutterBottom={true} color="primary">
                    {breadcrumbData.text}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                {(searchFilters.categoryId!=null || searchFilters.articleId!=null) &&
                <Button
                    fullWidth
                    edge="end"
                    size="small"
                    //className={classes.button}
                    data-cy="cy-kb-breadcrumbs-clearFilters-btn"
                    startIcon={<ClearIcon/>}
                    onClick={handleClearSearchFilters}
                >
                    {t('clearFilters')}
                </Button>
                }
            </Grid>
        </React.Fragment>
    );
}

export default Breadcrumbs;