import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const responseToastStyles = theme => ({
    root: {

    }
});

class ResponseToast extends Component {

    constructor(props) {
        super(props);

        this.state = {
            key: this.props.hasOwnProperty('key') && this.props.key ? this.props.key : 'responseToast',
            responseStatus: this.props.responseStatus ?? null
        };
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose(reason) {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({responseStatus: null});
        if(this.props.hasOwnProperty('closeHandler') && typeof this.props.closeHandler) {
            this.props.closeHandler();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.responseStatus !== prevState.responseStatus) {
            this.setState({responseStatus: this.props.responseStatus});
        }
    }

    render() {
        const { t /*,classes*/ } = this.props;

        return (
            <React.Fragment>
                <Snackbar key={this.state.key+'_success'} open={this.state.responseStatus===true} onClose={this.handleClose} autoHideDuration={this.props.autoHideDuration}>
                    <Alert  onClose={this.handleClose} severity="success">
                        {this.props.successMessage ?? t('success')+'!'}
                    </Alert>
                </Snackbar>
                <Snackbar key={this.state.key+'_failure'} open={this.state.responseStatus===false} onClose={this.handleClose} autoHideDuration={this.props.autoHideDuration}>
                    <Alert  onClose={this.handleClose} severity="error">
                        {this.props.failureMessage ?? t('error')+'.'}
                    </Alert>
                </Snackbar>
            </React.Fragment>
        );
    }
}

export default withStyles(responseToastStyles)(withTranslation()(ResponseToast));
