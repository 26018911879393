import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";
import { serialize } from 'object-to-formdata';
import AlertDialog from "../../../Shared/Communication/AlertDialog/AlertDialog";
import PasswordStrengthChecklist from '../../../Shared/PasswordField/PasswordStrengthChecklist'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import PasswordField from "../../../Shared/PasswordField";
import {useAppLoadingState} from "../../../../context/appLoadingState";

import {useHistory} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        '&:hover, &:focus': {
            textDecoration: 'none'
        },
    },
}));


function ChangePassword(props) {
    const classes = useStyles();
    const { updateAppLoadingState } = useAppLoadingState();
    const { t } = useTranslation();
    const history = useHistory();
    const [username, setUsername] = useState("");
    const [currentPassword, setCurrentPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmNewPassword, setConfirmNewPassword] = useState("")
    const [dialogVisibility, setDialogVisibility] = useState(false)
    const [statusMessage, setStatusMessage] = useState("")
    const [isPasswordChangeSuccessful, setIsPasswordChangeSuccessful] = useState(false)


    function handleDialogClose() {
        setDialogVisibility(false);
        if( isPasswordChangeSuccessful ) {
            setUsername('');
            setCurrentPassword('');
            setNewPassword('');
            setConfirmNewPassword('');
            history.push('/login');
        }
    }

    function handleChangePassword(e) {
        e.preventDefault();
        updateAppLoadingState(true);
        axios.post(
            "/api/v1/authentication/change-password",
            serialize({
                username: username,
                password: currentPassword,
                newPassword: newPassword
            })
        ).then(result => {
            updateAppLoadingState(false);
            if (result.status === 200) {
                setStatusMessage(result.data.message);
                setDialogVisibility(true);
                if (result.data.success) {
                    setIsPasswordChangeSuccessful(true);
                } else {
                    setIsPasswordChangeSuccessful(false);
                }
            }  
        }).catch(e => {
            updateAppLoadingState(false);
        });
    }

    return (
        <React.Fragment>
            <Typography component="h2" variant="subtitle1">
                {t('changePassword')}
            </Typography>
            <ValidatorForm className={classes.form} onSubmit={handleChangePassword} >
                <TextValidator
                    variant="outlined"
                    margin="normal"
                    size="small"
                    fullWidth
                    id="username"
                    // autoComplete="username"
                    label={t('username')}
                    placeholder="c_____.__"
                    name="username"
                    autoFocus
                    inputProps={{
                        autocomplete: 'off',
                    }}
                    validators={['required']}
                    errorMessages={['Username is a required field.']}
                    value={username}
                    onChange={e => {
                        setUsername(e.target.value);
                    }}
                />
                <PasswordField
                    id="currentPassword"
                    size="small"
                    name="password"
                    // autoComplete="current-password"
                    label={t('currentPassword')}
                    password={currentPassword}
                    setPassword={setCurrentPassword}
                    isRequired={true}
                    useTextValidator={true}
                />
                <PasswordField
                    id="newPassword"
                    name="newPassword"
                    password={newPassword}
                    setPassword={setNewPassword}
                    handleLastInputKeyPress={null}
                    label={t('newPassword')}
                    isRequired={true}
                    useTextValidator={true}
                />
                <PasswordField
                    id="confirmNewPassword"
                    name="confirmNewPassword"
                    password={confirmNewPassword}
                    setPassword={setConfirmNewPassword}
                    label={t('confirmNewPassword')}
                    isRequired={true}
                    useTextValidator={true}
                />
                <PasswordStrengthChecklist
                    password={newPassword}
                    passwordAgain={confirmNewPassword}
                    callbackHandler={ (isValid)=>{ console.log(isValid); }}
                />

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Button
                            type="button"
                            component={Link}
                            href="/login"
                            fullWidth
                            variant="text"
                            color="primary"
                            className={classes.submit}
                        >
                            {t('backToLoginPage')}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="secondary"
                            className={classes.submit}
                            disabled={!newPassword || !confirmNewPassword || !username ||!currentPassword || confirmNewPassword !== newPassword}
                        >
                            {t('changePassword')}
                        </Button>
                    </Grid>
                </Grid>
            </ValidatorForm>

            <Dialog
                open={dialogVisibility}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{ marginTop: '-400px' }}
            >
                <DialogTitle id="alert-dialog-title">{t('changePassword')}</DialogTitle>
                <DialogContent>
                    <DialogContentText color="text.primary" id="alert-dialog-description">
                        {statusMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleDialogClose} color="primary" autoFocus>
                        {t('ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default ChangePassword;