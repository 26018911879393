import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DrawerMenu from '../DrawerMenu';

function LeftDrawerMenu(props) {

    const drawerWidth = props.drawerWidth || 260;
    const useStyles = makeStyles(theme => ({
        hide: {
            display: 'none',
        },
        drawer: {
            index: 1,
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            background:'#fff'
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9) + 1,
            },
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        paper: {
            background: '#fff'
        }
    }));

    const classes = useStyles();
    const theme = useTheme();
    return (
        <React.Fragment>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: props.state.isOpen,
                    [classes.drawerClose]: !props.state.isOpen,
                })}
                classes={{
                    paper: clsx(classes.paper, {
                        [classes.drawerOpen]: props.state.isOpen,
                        [classes.drawerClose]: !props.state.isOpen,
                    }),
                }}
            >
                <div className={classes.toolbar} />
                <div className={classes.toolbar}>
                    <IconButton onClick={props.onClose}>
                        {   props.state.isOpen===true
                            ? (theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />)
                            : (theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />)
                        }
                    </IconButton>
                </div>
                <DrawerMenu
                    nodes={props.menuItems}
                    onMenuItemClick={props.onDrawerMenuItemClick}
                    state={props.state.drawerMenu}
                />
            </Drawer>
        </React.Fragment>
    );
}

export default React.memo(LeftDrawerMenu);