import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
import Typography from "@material-ui/core/Typography";
import List from '@mui/material/List';
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from '@mui/material/ListSubheader';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height:'100%'
    },
    activeListItem: {
        background:'#eee',
    }
}));

function SideMenu(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <List
                className={classes.root}
                subheader={<ListSubheader>{t('management')}</ListSubheader>}
            >
            <ListItem button
                      key="createPartnerEmployee_listItem"
                      component={NavLink}
                      to="/security-manager/management/create-partner-employee"
                      activeClassName={classes.activeListItem}
            >
                <ListItemText primary="Create Partner Employee"/>
            </ListItem>
            </List>
        </React.Fragment>
    );
}

export default SideMenu;