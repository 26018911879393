import React from 'react';
import {makeStyles, withStyles, createTheme,ThemeProvider } from '@material-ui/core/styles'
import List from '@mui/material/List';
import Collapse from '@material-ui/core/Collapse';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { NavLink } from 'react-router-dom';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const drawerMenuUseStyles = makeStyles(theme => ({
    submenu: {
        background: 'transparent',
    },
    expandedParentItem: {
        fontWeight: 600
    },
    activeListItem: {
        color:'#fff',
        background: '#EB1C26',
        '&:hover': {
            background: '#EB1C26',
        }
    }
}));

const getMuiTheme = () => createTheme({
    typography: {
        allVariants: {
            fontFamily: `'Montserrat',Helvetica,Arial,sans-serif`,
            fontSize: 16,
            letterSpacing: -0.5
        },
    },
    overrides: {
        ListItemText: {
            root: {

            }
        },
    },
});

function DrawerMenu(props) {
    const classes = drawerMenuUseStyles();

    const handler = function( nodes ) {
        return nodes.map( ( node ) => {
            if( node.hasOwnProperty('isVisible') && !node.isVisible ) {
               return '';
            }
            if ( !node.hasOwnProperty('nodes') || !node.nodes ) {
                return (
                    <ListItem button
                              key={node.id}
                              component={NavLink}
                              to={node.routesTo}
                              activeClassName={classes.activeListItem}
                              disabled={node.hasOwnProperty('isActive') && node.isActive}
                              data-cy={'cy-'+node.id}
                        >
                        <ListItemIcon>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    width: 56
                                }}
                            >
                            {node.hasOwnProperty('icon') ? node.icon : <div/>}
                            </div>
                        </ListItemIcon>
                        <ListItemText primary={node.caption}/>
                    </ListItem>
                );
            }
            return (
                <div key={ node.id }>
                    <ListItem
                        button
                        disabled={node.hasOwnProperty('isActive') && node.isActive}
                        onClick={()=> props.onMenuItemClick(node.id)}
                        data-cy={'cy-'+node.id}
                    >
                        <ListItemIcon>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    width: 56
                                }}
                            >
                            {node.hasOwnProperty('icon') ? node.icon : <div/>}
                            </div>
                        </ListItemIcon>
                        <ListItemText
                            primary={ node.caption }
                            classes={{
                                primary: (node.hasOwnProperty('nodes') && props.state.items[ node.id ]) ? classes.expandedParentItem : null
                            }}
                        />
                        { props.state.items[ node.id ] ?
                            <ExpandLess /> :
                            <ExpandMore />
                        }
                    </ListItem>
                    <Collapse
                        in={ props.state.items[ node.id ] }
                        timeout="auto"
                        unmountOnExit
                        className={classes.submenu}
                    >
                        { handler( node.nodes ) }
                    </Collapse>
                </div>
            )
        } )
    }

    const { nodes } = props;

    return (
        <ThemeProvider theme={getMuiTheme()}>
        <List>
            { nodes.length && handler( nodes ) }
        </List>
        </ThemeProvider>
    );
}

export default React.memo(DrawerMenu);