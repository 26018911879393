import React, {Component} from 'react';
import NavBar from "../Partial/NavBar";

import MainContainer from "../Partial/MainContainer";
import RouteBuilder from "../../Routing/Layout/Dashboard";

import ProfileIcon from "../../Shared/CloudiaxIcon/Profile";
import NotificationsIcon from "../../Shared/CloudiaxIcon/Notifications";
import ListSubheader from '@material-ui/core/ListSubheader';

import {AuthContext, useAuth} from "../../../context/auth";
import { NotificationContext } from "../../../context/notification";

import axios from "axios";
import { withTranslation } from 'react-i18next';
import NotificationMenu from "../Partial/NotificationMenu";

class Dashboard extends Component {

    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            NavBar: {
                title: this.props.t('customerPortal')
            },
            LeftDrawerMenu: {
                isOpen: true,
                drawerMenu: {
                    items: {}
                }
            }
        };
    }

    componentDidMount() {

    }

    render() {
        const { authData, updateAuthenticationData } = this.context;
        const { t } = this.props;



        const handleLogoutItemClick = () => {
            axios.post("/api/v1/authentication/disconnect-user").then(result => {
                if (result.status === 200) {
                    updateAuthenticationData({
                        authData: {
                            basic: false,
                            mfa: false
                        },
                        isAuthenticated: false
                    });
                }
            }).catch(e => {
            });
        }

        const handleDrawerOpenToggle = () => {
            this.setState( function(prevState) {
                return {
                    LeftDrawerMenu: {
                        isOpen: !prevState.LeftDrawerMenu.isOpen,
                        drawerMenu: prevState.LeftDrawerMenu.drawerMenu
                    }
                }
            });
        };

        const handleDrawerMenuItemClick  = ( item ) => {
            this.setState( function(prevState) {
                return {
                    LeftDrawerMenu: {
                        isOpen: prevState.LeftDrawerMenu.isOpen,
                        drawerMenu: {
                            items: {
                                [item]: !prevState.LeftDrawerMenu.drawerMenu.items[ item ]
                            }
                        }
                    }
                }
            });
        }
        return (
                <React.Fragment>
                    <NotificationContext.Consumer>
                        {({ notificationData }) => (
                        <NavBar
                            title={this.state.NavBar.title}
                            leftDrawerIsOpen={this.state.LeftDrawerMenu.isOpen}
                            navbarItems={[
                                {
                                    id: 'navbar-notificationMenu',
                                    icon: <NotificationsIcon sx={{ fontSize: '2.5rem' }} />,
                                    ariaLabel: 'Notifications',
                                    onClick: null,
                                    custom: {
                                        customType: 'badge',
                                        badgeContent: notificationData && notificationData.hasOwnProperty('counters') ? notificationData.counters.unread : 0,
                                        type:'minimal'
                                    },
                                    dropdownMenu: {
                                        id: 'notification-menu',
                                        items: [
                                            {
                                                element: <NotificationMenu/>
                                            }
                                        ]
                                    },
                                },
                                {
                                    id: 'navbar-accountMenu',
                                    icon: <ProfileIcon sx={{ fontSize: '2.5rem' }} />,
                                    ariaLabel:'My Account',
                                    onClick: null,
                                    dropdownMenu: {
                                        id: 'menu-account',
                                        items: [
                                            {
                                                element: <ListSubheader key="userNameListSubHeader" component="div">{authData.authData.username}</ListSubheader>
                                            },
                                            {
                                                id: 'navbar-item-myProfile',
                                                caption: t('myProfile'),
                                                routesTo: '/account/profile'
                                            },
                                            {
                                                id: 'navbar-item-changePassword',
                                                caption: t('changePassword'),
                                                routesTo: '/account/password',
                                                onClick: null
                                            },
                                            {
                                                divider: true,
                                                onClick: null
                                            },
                                            {
                                                id: 'navbar-item-preferences',
                                                caption: t('preferences'),
                                                routesTo: '/account/preferences',
                                                onClick: null
                                            },
                                            {
                                                divider: true,
                                                onClick: null
                                            },
                                            {
                                                id: 'navbar-item-logout',
                                                caption: t('logout'),
                                                onClick: handleLogoutItemClick
                                            }
                                        ]
                                    },
                                },
                            ]}
                            />
                        )}
                        </NotificationContext.Consumer>
                    <MainContainer drawer={
                        {
                            state: this.state.LeftDrawerMenu ,
                            methods: {
                                handleDrawerOpenToggle: handleDrawerOpenToggle,
                                handleDrawerMenuItemClick:handleDrawerMenuItemClick
                            }
                        }
                    }>
                        <RouteBuilder/>
                    </MainContainer>
                </React.Fragment>
        );
    }
}

export default withTranslation()(Dashboard);