import React, {useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
import SolutionSearch from '../SolutionSearch';

const useStyles = makeStyles(theme => ({
    stepper: {
        background:'transparent'
    },
    stepContainer: {
        display:'block',
    },
    searchBarWrapper: {
        display:'block',
        width:'100%',
    },
    searchBar: {
        margin: '0 auto'
    }
}));

function Resolve(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Typography variant="h4" gutterBottom={true}>
                {t('createTicket')}
            </Typography>
            <div className={classes.stepContainer}>
                <div className={classes.searchBarWrapper}>
                    <SolutionSearch
                        history={props.history}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}


export default Resolve;