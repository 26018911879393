import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import BreadcrumbBuilder from "../BreadcrumbBuilder";
import LeftDrawerMenu from "../../Partial/LeftDrawerMenu";
import {usePerm} from "../../../../context/permission";
import CloudiaxIconKnowledgeBase from "../../../Shared/CloudiaxIcon/KnowledgeBase/CloudiaxIconKnowledgeBase";
import BillingIcon from "../../../Shared/CloudiaxIcon/Billing";
import SecurityManagerIcon from "../../../Shared/CloudiaxIcon/SecurityManager";
import CloudiaxIconSupport from "../../../Shared/CloudiaxIcon/Support/CloudiaxIconSupport";
import CloudiaxIconTickets from "../../../Shared/CloudiaxIcon/Support/Tickets/CloudiaxIconTickets";
import CloudiaxIconTicket from "../../../Shared/CloudiaxIcon/Support/Ticket/CloudiaxIconTicket";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const drawerWidth = 300;

function MainContainer(props) {

    const useStyles = makeStyles(theme => ({
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        contentWrapper: {
            flexGrow: 1,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
    }));

    const classes = useStyles();
    const { permData } = usePerm();

    const { t, drawer } = props;

    let sideMenuItems = [
        {
            id: 'menuItemTicketingSystem',
            caption: t('support'),
            icon: <CloudiaxIconSupport sx={{ fontSize: '3rem' }} />,
            nodes: [
                {
                    id: 'menuItemTicketingSystemList',
                    caption: t('nav_myTickets'),
                    icon: <CloudiaxIconTickets sx={{ fontSize: '3rem' }}/>,
                    routesTo:'/support/ticket/list',
                },
                {
                    id: 'menuItemSupportGetHelp',
                    caption: t('createTicket'),
                    icon: <CloudiaxIconTicket sx={{ fontSize: '3rem' }}/>,
                    routesTo:'/support/get-help',
                }
            ]
        },
        {
            id: 'menuItemKnowledgeBase',
            caption: t('knowledgeBase'),
            icon: <CloudiaxIconKnowledgeBase sx={{ fontSize: '3rem' }}/>,
            routesTo:'/kb/list',
        },
    ];

    if( (permData.flags.sm == true || permData.flags.psm == true) ) {
        sideMenuItems.push({
            id: 'menuItemSecurityManager',
            caption: t('securityManager'),
            icon: <SecurityManagerIcon sx={{ fontSize: '3rem' }}/>,
            nodes: [
                {
                    id: 'menuItemSecurityManagerManagement',
                    caption: t('management'),
                    icon: <AdminPanelSettingsIcon sx={{ fontSize: '2rem' }}/>,
                    routesTo:'/security-manager/management',
                },
            ]
        });
    }

    if( 1==2 ) {
        sideMenuItems.push({
            id: 'menuItemBilling',
            caption: t('billing'),
            icon: <BillingIcon sx={{ fontSize: '3rem' }}/>,
            routesTo:'/billing',
        });
    }

    return (
        <React.Fragment>
            <LeftDrawerMenu
                drawerWidth={drawerWidth}
                state={drawer.state}
                onClose={drawer.methods.handleDrawerOpenToggle}
                onDrawerMenuItemClick={drawer.methods.handleDrawerMenuItemClick}
                menuItems={sideMenuItems}
            />
            <main className={classes.contentWrapper}>
                <div className={classes.toolbar} />
               {/* <BreadcrumbBuilder showHome={false}/>*/}
                <div className={classes.content}>
                    {props.children}
                </div>
            </main>
        </React.Fragment>
    );
}

export default withTranslation()(MainContainer);