import KnowledgeBaseModule from "../../../Modules/KnowledgeBase";
import KnowledgeBaseArticleViewModule from "../../../Modules/KnowledgeBase/Components/ArticleView";

import InboxModule from "../../../Modules/Inbox";
import AccountProfileModule from "../../../Modules/Profile";
import AccountChangePasswordModule from "../../../Modules/Profile/ChangePassword/ChangePassword";
import AccountPreferences from "../../../Modules/Preferences";
import ResourcesUsersModule from "../../../Modules/Resources/Users";
import ResourcesCustomersModule from "../../../Modules/Resources/Customers";
import ResourcesDatabasesModule from "../../../Modules/Resources/Databases";
import TicketSystemListModule from "../../../Modules/Support/TicketSystem/Ticket/List";
import TicketSystemTicketDetailsModule from "../../../Modules/Support/TicketSystem/Ticket/Details";
import TicketSystemResolveModule from "../../../Modules/Support/TicketSystem/Ticket/Resolve";
import TicketSystemCreateModule from "../../../Modules/Support/TicketSystem/Ticket/Create";
import DashboardHome from "../../../Modules/Dashboard/Home";
import SecurityManagerManagementModule from "../../../Modules/SecurityManager/ManagementModule";
import CreatePartnerEmployee from "../../../Modules/SecurityManager/ManagementModule/Views/CreatePartnerEmployee";


export const appRouteMapping = [
    {
        route: '/',
        exact: true,
        breadcrumb: 'Dashboard',
        component: DashboardHome,
    },
    {
        route: '/inbox',
        breadcrumb: 'Inbox',
        component: InboxModule,
    },
    {
        route: '/account',
        breadcrumb: 'Account',
        component: null,
    },
    {
        route: '/account/profile',
        breadcrumb: 'Profile',
        component: AccountProfileModule,
    },
    {
        route: '/account/password',
        breadcrumb: 'Password',
        component: AccountChangePasswordModule,
    },
    {
        route: '/account/preferences',
        breadcrumb: 'Preferences',
        component: AccountPreferences,
    },
    {
        route: '/resources',
        breadcrumb: 'Resources',
        component: null,
    },
    {
        route: '/resources/users',
        breadcrumb: 'Users',
        component: ResourcesUsersModule,
    },
    {
        route: '/resources/customers',
        breadcrumb: 'Customers',
        component: ResourcesCustomersModule,
    },
    {
        route: '/resources/databases',
        breadcrumb: 'Databases',
        component: ResourcesDatabasesModule,
    },
    {
        route: '/support',
        breadcrumb: 'Support',
        component: null,
    },
    {
        route: '/support/ticket',
        breadcrumb: 'Ticket',
        component: null,
    },
    {
        route: '/support/ticket/list',
        breadcrumb: 'List',
        component: TicketSystemListModule,
    },
    {
        route: '/support/ticket/details/:ticketId',
        breadcrumb: 'Details',
        component: TicketSystemTicketDetailsModule,
    },
    {
        route: '/support/ticket/create',
        breadcrumb: 'Create',
        component: TicketSystemCreateModule,
    },
    {
        route: '/support/get-help',
        breadcrumb: 'Get Help',
        component: TicketSystemResolveModule,
    },
    {
        route: '/kb',
        breadcrumb: 'Knowledge Base',
        component: null,
        alternativeRoute: '/kb/list'
    },
    {
        route: '/kb/list',
        breadcrumb: 'Browse',
        component: KnowledgeBaseModule,
    },
    {
        route: '/kb/article/:articleId',
        breadcrumb: 'Article',
        component: KnowledgeBaseArticleViewModule,
    },
    {
        route: '/security-manager',
        breadcrumb: 'Security Manager',
        component: null,
    },
    {
        route: '/security-manager/management',
        breadcrumb: 'Management',
        component: SecurityManagerManagementModule,
    },
];