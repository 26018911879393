import React, {useEffect} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import SolutionArticleList from "./SolutionArticleList";
import Grid from "@material-ui/core/Grid";
import Divider from '@material-ui/core/Divider';
import SearchBar from "../../../../KnowledgeBase/Components/SearchBar";
import OpenTicketButtonContainer from "./OpenTicketButtonContainer";

const useStyles = makeStyles(theme => ({
    stepper: {
        background:'transparent'
    },
    stepContainer: {
        display:'block',
    },
    searchBarWrapper: {
        display:'block',
        width:'100%',
    },
    searchBar: {
        margin: '0 auto'
    },
}));

function SolutionSearch(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const searchInputRef = React.useRef( null);
    const [searchWasInit, setSearchWasInit] = React.useState(false);
    const [searchInputWasCleared, setSearchInputWasCleared] = React.useState(false);
    const [searchButtonWasClicked, setSearchButtonWasClicked] = React.useState(false);

    const [searchFilters, setSearchFilters] = React.useState({
        languageId: 1,
        limit: 5,
        page: 0,
        query: ''
    });

    const [articleList, setArticleList] = React.useState({});

    const handleSearch = function() {
        setSearchButtonWasClicked(true);
        setSearchWasInit(true);
        setArticleList({});
        setSearchFilters((prevState) => ({
            ...prevState,
            articleId: null,
            page:0,
        }));
    }

    const startSearchWhileTyping=true; 

    return (
        <React.Fragment>
            <Typography variant="subtitle1" gutterBottom={true}>
                {t('supportResolveSubTitle')}
            </Typography>
            <Grid container>
                <Grid item xs={12} md={12} lg={7} xl={8}>
                <SearchBar
                    startSearchWhileTyping={startSearchWhileTyping}
                    simpleMode={true}
                    className={classes.searchBar}
                    placeholderText={t('howCanWeHelpYou')+'?'}
                    searchInputRef={searchInputRef}
                    searchFilters={searchFilters}
                    setSearchFilters={setSearchFilters}
                    setSearchInputWasCleared={setSearchInputWasCleared}
                    searchInputWasCleared={searchInputWasCleared}
                    setSearchButtonWasClicked={setSearchButtonWasClicked}
                    setSearchWasInit={setSearchWasInit}
                    setArticleList={setArticleList}
                />
                <SolutionArticleList
                    startSearchWhileTyping={startSearchWhileTyping}
                    searchFilters={searchFilters}
                    setSearchFilters={setSearchFilters}
                    setSearchInputWasCleared={setSearchInputWasCleared}
                    searchInputWasCleared={searchInputWasCleared}
                    setSearchButtonWasClicked={setSearchButtonWasClicked}
                    searchButtonWasClicked={searchButtonWasClicked}
                    articleList={articleList}
                    setArticleList={setArticleList}
                />

                {searchWasInit===true && searchFilters.query.trim().length>0 &&
                    <OpenTicketButtonContainer
                        history={props.history}
                        articleList={articleList}
                        shortDescriptionStarterText={searchFilters.query}
                    />
                }

                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default SolutionSearch;