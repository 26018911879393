import * as React from 'react';
import { SvgIcon as MuiSvgIcon, styled } from '@mui/material';

const SvgIcon = styled(MuiSvgIcon, {
       name: 'SecurityManagerIcon',
       shouldForwardProp: (prop) => prop !== 'fill',
     })(() => ({
        fill: '#eee',
        stroke: '#1D1D1B',
        strokeLinecap: 'round',
        strokeLinejoin:  'round',
        strokeWidth:  '1.5px',
        strokeMiterlimit: 10
     }));

    SvgIcon.defaultProps = {
        viewBox: '0 0 60 60',
        focusable: 'false',
        'aria-hidden': 'true'
    };

 const SecurityManagerIcon = (props) => {
      return (
              <SvgIcon
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{enableBackground:"new 0 0 60 60"}}
                  {...props}
              >
                  <style type="text/css">
                      .st0{"fill:#FFFFFF;stroke:#000000;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;"}
                  </style>
                  <g>
                      <path className="st0" d="M41.1,33.6L41.1,33.6l-6.5-7.9l0,0c-1.1-1.4-2.7-2.3-4.6-2.3c-1.4,0-2.7,0.5-3.7,1.3c-0.4,0.3-0.7,0.6-0.9,1
		l0,0l-6.5,7.9l0,0c-0.3,0.4-0.3,1,0.1,1.5c0.4,0.5,1.2,0.6,1.7,0.1l5.4-5.5l0.6,8.3L23,49.6c-0.2,0.3-0.3,0.7-0.2,1.1
		c0.1,0.9,1,1.6,1.9,1.4c0.5-0.1,0.9-0.4,1.2-0.8l0,0l0,0c0.1-0.1,0.2-0.3,0.2-0.5l4.1-9.7l4.1,9.7c0,0.2,0.1,0.3,0.2,0.5l0,0l0,0
		c0.3,0.4,0.7,0.7,1.2,0.8c0.9,0.1,1.8-0.5,1.9-1.4c0.1-0.4,0-0.7-0.2-1.1l-4-12.3l0.6-7.6l5.4,5.5c0.5,0.4,1.3,0.4,1.7-0.1
		C41.4,34.7,41.4,34.1,41.1,33.6z"/>
                      <g>
                          <path className="st0" d="M40.4,16.2c0-2.3-1.9-4.2-4.2-4.2c-0.8,0-1.5,0.2-2.1,0.6l0,0l-0.1-0.6l0,0c-0.4-2.3-2.4-4.1-4.8-4.1
			c-2.7,0-4.8,2.1-4.9,4.8h0l0,0.1c0,0,0,0,0,0c0,0.1,0,0.3,0,0.4l0,0.1c-0.2,0-0.4-0.1-0.6-0.1c-2,0-3.6,1.6-3.6,3.6
			c0,2,1.6,3.6,3.6,3.6h12.6l0,0C38.6,20.4,40.4,18.5,40.4,16.2z"/>
                          <path className="st0" d="M26.4,14.4c-0.7-0.7-1.6-1.2-2.7-1.2"/>
                          <path className="st0" d="M36.2,12c-1.7,0-3.2,1-3.8,2.4"/>
                      </g>
                  </g>
              </SvgIcon>
      );
     };

export default SecurityManagerIcon;