import LoginModule from "../../../Modules/Login";
import LoginForgotPasswordModule from "../../../Modules/Login/ForgotPassword/ForgotPassword";
import LoginChangePasswordModule from "../../../Modules/Login/ChangePassword/ChangePassword";
import LoginForgotPasswordResetModule from "../../../Modules/Login/ForgotPassword/Reset";
import LoginForgotUsernameModule from "../../../Modules/Login/ForgotUsername";
import LoginNoModule from "../../../Modules/Login/NoLogin";
import NeedHelpLoggingIn from "../../../Modules/Login/NeedHelpLoggingIn/NeedHelpLoggingIn"

export const appRouteMapping = [
    {
        route: '/login',
        exact: true,
        breadcrumb: 'Login',
        component: LoginModule,
    },
    {
        route: '/login/forgot-password',
        breadcrumb: 'Forgot password',
        component: LoginForgotPasswordModule,
    },
    {
        route: '/login/change-password',
        breadcrumb: 'Change password',
        component: LoginChangePasswordModule,
    },
    {
        route: '/login/forgot-username',
        breadcrumb: 'Forgot password',
        component: LoginForgotUsernameModule,
    },
    {
        route: '/login/password/reset/:otac',
        breadcrumb: 'Password Reset',
        component: LoginForgotPasswordResetModule,
    },
    {
        route: '/login/no-login',
        breadcrumb: 'No login',
        component: LoginNoModule,
    },
    {
        route: '/login/login-help',
        breadcrumb: "Login Help",
        component: NeedHelpLoggingIn,
    },
];