import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    wrapper: {
        margin: '10px 0 20px 0',
        padding: '0px 16px 0px 16px'
    },

}));

function ArticleListItemSkeletonLoader(props) {
    const classes = useStyles();
    const { fillerArrayAmount } = props;
    const articleSkeletonFilledArray = Array(fillerArrayAmount ? fillerArrayAmount : 8).fill();

    return (
      <React.Fragment>
          {articleSkeletonFilledArray.map(() => Math.round(Math.random() * 99)).map((val) => (
              <div className={classes.wrapper}>
                  <Skeleton width={val<50 ? '50%' : val + '%'} height={26} animation="wave">
                  </Skeleton>
                  <Skeleton width={val<50 ? '40%' : (val / 1.5) + '%'} height={20} animation="wave">
                  </Skeleton>
              </div>
          ))}
      </React.Fragment>
    );
}

export default ArticleListItemSkeletonLoader;