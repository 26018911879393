import * as React from 'react';
import { SvgIcon as MuiSvgIcon, styled } from '@mui/material';

const SvgIcon = styled(MuiSvgIcon, {
    name: 'CloudiaxIconSupport',
    shouldForwardProp: (prop) => prop !== 'fill',
})(() => ({
    fill: '#eee',
    stroke: '#000',
    strokeLinecap: 'round',
    strokeLinejoin:  'round',
    strokeWidth:  '1.5px',
    strokeMiterlimit: 10
}));

SvgIcon.defaultProps = {
    viewBox: '0 0 60 60',
    focusable: 'false',
    'aria-hidden': 'true'
};

const CloudiaxIconSupport = (props) => {
    return (
        <SvgIcon
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            style={{enableBackground:"new 0 0 60 60"}}
            {...props}
        >
            <style type="text/css">
                .st0{"fill:#FFFFFF;stroke:#000000;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;"}
            </style>
            <rect x="13.35" y="36.43" transform="matrix(0.2588 -0.9659 0.9659 0.2588 -26.7944 51.2728)" class="st0" width="13.32" height="13.32"/>
            <rect x="13.35" y="30.49" transform="matrix(0.5 -0.866 0.866 0.5 -22.1681 35.9083)" class="st0" width="13.32" height="13.32"/>
            <rect x="13.35" y="24.55" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -16.2058 23.2913)" class="st0" width="13.32" height="13.32"/>
            <path class="st0" d="M46.68,9.37H30.41c-1.19,0-2.17,0.98-2.17,2.17v10.38c0,1.19,0.98,2.17,2.17,2.17h1.73v6.57l6.57-6.57h7.96 c1.19,0,2.17-0.98,2.17-2.17V11.54C48.85,10.35,47.87,9.37,46.68,9.37z"/>
        </SvgIcon>
    );
};

export default CloudiaxIconSupport;