import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import { useAppLoadingState } from "../../../../context/appLoadingState";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const globalThemeOverrides = createTheme({
    overrides: {
        palette: {
            success: {
                main: '#51a843'
            }
        }
    }
});

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        // background: '#f6f5f3',
        minHeight:'100vh'
    },
    appLoadingBackdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    }
}));

function AppWrapper(props) {
    const classes = useStyles();
    const { isAppLoading } = useAppLoadingState();

    return (
    //    <ThemeProvider theme={globalThemeOverrides}>
        <div className={classes.root}>
            <CssBaseline />
            <Backdrop className={classes.appLoadingBackdrop} open={isAppLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {props.children}
        </div>
     //   </ThemeProvider>
    );
}

export default AppWrapper;