import React from 'react';
import { ThemeProvider, createTheme, makeStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/600.css";

import './App.css';
import { AuthContext } from "../../context/auth";
import { PermContext } from "../../context/permission";
import { NotificationContext } from "../../context/notification";
import { AppLoadingStateContext } from "../../context/appLoadingState";
import axios from "axios";
import AppWrapper from "../Layout/Partial/AppWrapper";
import MasterRouter from "../Routing";
import withRoot from '../../i18n/HOC/withRoot'
import ReactInterval from 'react-interval';
import {serialize} from "object-to-formdata";

const devMode = process.env.REACT_APP_DEV_MODE || false;
const simulate = null; //'mfaWizard';


const theme = createTheme({
    root: {
        letterSpacing: -0.5
        // some CSS that access to theme
    },
    palette: {
        primary: {
            light: '#3068bc',
            main: '#1A3A7A',
            dark: '#1b3457',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ed1e29',
            main: '#EB1C26',
            dark: '#ad1c22',
            contrastText: '#fff',
        },
        background: {
            default: "#faf9f8" 
        }
    },
    typography: {
        allVariants: {
            fontFamily: `'Montserrat',Helvetica,Arial,sans-serif`,
            fontSize: 14,
            letterSpacing: 0
        },
        h4: {
            color: '#1b3457',
            textTransform: "uppercase",
            fontWeight: 600,
            paddingBottom: 12,
            fontSize: 21
        },
        h5: {
            textTransform: "uppercase",
            fontWeight: 600,
            paddingBottom: 12
        }
    },
    overrides: {
        // MuiOutlinedInput: {
        //     root: {
        //         // backgroundColor: "#fff",
        //     }
        // },

        MuiInputLabel: {
            root: {
              color: "#1b3457",
            },
          },
        MUIDataTable: {
            root: {
                fontFamily: `'Montserrat',Helvetica,Arial,sans-serif`
            },
        },
    }
});


class App extends Component {

    constructor(props) {
        super(props);

        let authDataStorage = window.localStorage.getItem('authData'),
            authData = authDataStorage ? JSON.parse(authDataStorage) : null;

        /**
         * only for DevMode
         */
        if(devMode) {
            let simulationConfig = {
                isAuthenticated: true,
                authData: {
                    username: 'c10013.26',
                    mfaAuthType: 1,
                    hasMfa: true,
                    mfaWizardComplete: true,
                    basic: true,
                    mfa: true
                },
                groupBasedPermissions: {tc: 1, sm: 1, psm: 0 },
                supportGroupMembershipList: [3]
            }

            switch(simulate){
                case 'mfaWizard':
                    simulationConfig = {
                        isAuthenticated: false,
                        authData: {
                            username: 'devMode',
                            mfaAuthType: 2,
                            hasMfa: false,
                            basic: true,
                            mfaWizardComplete: false,
                            mfa: false
                        }
                    }
                    break;
            }
            authData = simulationConfig;
        }

        this.state = {
            NavBar: {
                title: 'Cloudiax Service Portal'
            },
            LeftDrawerMenu: {
                isOpen: true
            },
            appLoadingState: false,
            Authentication: {
                isAuthenticated: authData ? (authData.authData.basic && authData.authData.mfa) : false,
                authData: {
                    username: authData ? authData.authData.username : null,
                    mfaAuthType: authData ? authData.authData.mfaAuthType : 1,
                    basic: authData ? authData.authData.basic : false,
                    hasMfa: authData ? authData.authData.hasMfa : false,
                    mfaWizardComplete: (authData && authData.authData.basic && !authData.authData.hasMfa)  ? authData.authData.mfaWizardComplete : true,
                    mfa: authData ? authData.authData.mfa : false
                },
                groupBasedPermissions: authData ? authData.groupBasedPermissions : {tc: 0, sm: 0, psm: 0 },
                supportGroupMembershipList: authData ? authData.supportGroupMembershipList : []
            },
            Permission: {
                flags: {
                    tc: 0,
                    sm: 0,
                    psm: 0
                }
            },
            Notification: {
                counters: {
                    unread: 0
                },
                list: [
                ]
            }
        };

        this.updateAuthenticationData = this.updateAuthenticationData.bind(this);
        this.syncAuthenticationState = this.syncAuthenticationState.bind(this);
        this.updateAppLoadingState = this.updateAppLoadingState.bind(this);
        this.loadPermissions = this.loadPermissions.bind(this);
    }


    componentDidMount() {
        this.syncAuthenticationState();
        this.syncUserNotifications();
        this.loadPermissions();
    }

    // force reload of permissions after authentication
    componentDidUpdate(prevProps, prevState) {
        if (this.state.Authentication.isAuthenticated !== prevState.Authentication.isAuthenticated) {
            this.loadPermissions();
        }
    }

    updateAppLoadingState(bool) {
        this.setState({
           ...this.state,
            appLoadingState: bool
        });
    }

    updateAuthenticationData(data) {
    // Serialize relevant parts of the incoming data and the current state for comparison
        const newDataString = JSON.stringify({
                authData: {
                    username: data.authData.username,
                    mfaAuthType: data.authData.mfaAuthType,
                    basic: data.authData.basic,
                    hasMfa: data.authData.hasMfa,
                    mfaWizardComplete: data.authData.mfaWizardComplete,
                    mfa: data.authData.mfa
                },
                groupBasedPermissions: data.groupBasedPermissions,
                supportGroupMembershipList: data.supportGroupMembershipList
            });
    
        const currentStateDataString = JSON.stringify({
            authData: this.state.Authentication.authData,
            groupBasedPermissions: this.state.Authentication.groupBasedPermissions,
            supportGroupMembershipList: this.state.Authentication.supportGroupMembershipList
        });
    
    
        // Check if the incoming data is different from the current state's authData

        if (newDataString !== currentStateDataString) {
          // Proceed with state update only if the data is different
          window.localStorage.setItem("authData", JSON.stringify(data));
          this.setState({
            ...this.state,
            Authentication: {
              isAuthenticated:
                data.authData.basic && data.authData.mfa ? true : false,
              authData: {
                ...this.state.Authentication.authData,
                username: data.authData.username,
                mfaAuthType: data.authData.mfaAuthType,
                basic: data.authData.basic,
                hasMfa: data.authData.hasMfa,
                mfaWizardComplete: data.authData.mfaWizardComplete,
                mfa: data.authData.mfa,
              },
              groupBasedPermissions: data.groupBasedPermissions,
              supportGroupMembershipList: data.supportGroupMembershipList,
            },
          });
        } else if (devMode) {
          // If in devMode and data is the same, still update localStorage for debugging purposes
          window.localStorage.setItem(
            "authData",
            JSON.stringify(this.state.Authentication)
          );
        }
      }

    updatePermissionData(data) {
        this.setState({
            ...this.state,
            Permission: {
                flags: data
            }
        });
    }

    syncAuthenticationState() {
        axios.post("/api/v1/authentication/get-state").then(result => {
            if (result.status === 200) {
                let authDataObj = {
                    isAuthenticated: result.data.isAuthenticated.basic && result.data.isAuthenticated.mfa ? true : false,
                    authData: {
                        username: result.data.username,
                        mfaAuthType: result.data.isAuthenticated.mfaAuthType,
                        basic: result.data.isAuthenticated.basic,
                        hasMfa: result.data.hasMfa,
                        mfaWizardComplete: (result.data.isAuthenticated.basic && !result.data.hasMfa) ? result.data.mfaWizardComplete : true,
                        mfa: result.data.isAuthenticated.mfa
                    },
                    groupBasedPermissions: result.data.groupBasedPermissions,
                };

                if( result.data.hasOwnProperty('supportGroupMembershipList') ) {
                    authDataObj.supportGroupMembershipList = result.data.supportGroupMembershipList;
                }
                this.updateAuthenticationData(authDataObj);
            }
        }).catch(e => {
        });
    }

    syncUserNotifications() {
        axios.post(
            "/api/v1/user/inbox/list",
            serialize({
                format: 1,
            })
        ).then(result => {
            if (result.status === 200) {
                this.setState({
                    ...this.state,
                    Notification: result.data
                });
            }
        }).catch(e => {
        });
    }

    loadPermissions() {
        axios.post("/api/v1/render/control/get-flags").then(result => {
            if (result.status === 200) {
                this.updatePermissionData(result.data)
            }
        }).catch(e => {
        });
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <AppLoadingStateContext.Provider value={{isAppLoading: this.state.appLoadingState, updateAppLoadingState: this.updateAppLoadingState}}>
                    <AuthContext.Provider value={{authData: this.state.Authentication, updateAuthenticationData: this.updateAuthenticationData}}>
                        <PermContext.Provider value={{permData: this.state.Permission}}>
                            <NotificationContext.Provider value={{notificationData: this.state.Notification}}>

                                <ReactInterval
                                    timeout={1000*30*5}
                                    enabled={true}
                                    callback={this.syncAuthenticationState}
                                />
                                <ReactInterval
                                    timeout={1000*15}
                                    enabled={true}
                                    callback={this.syncUserNotifications}
                                />

                                <AppWrapper>
                                    <MasterRouter/>

                                </AppWrapper>

                            </NotificationContext.Provider>

                        </PermContext.Provider>
                    </AuthContext.Provider>
                </AppLoadingStateContext.Provider>
            </ThemeProvider>
        );
  }
}

export default withRoot(App);
