import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import './index.css';

ReactDOM.render(
    <App />,
  document.getElementById('root')
);

/*
import 'react-app-polyfill/ie9';
import React from 'react';
import { createRoot } from "react-dom/client";

import App from './components/App';
import './index.css';

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);*/
