import React from "react";

import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import { Link, Typography } from "@mui/material";
import AlertDialog from "../../../../../../Shared/Communication/AlertDialog";

const useStyles = makeStyles(theme => ({
    affectedUserListHeight: {
        height: 350
    }
}));


function AffectedUserList(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const {
        areAllUsersAffected,
        affectedUserList
    } = props;

    const [openAffectedUsersDialog, setOpenAffectedUsersDialog] = React.useState(false);

    return (
        <React.Fragment>
                {
                    affectedUserList && affectedUserList.length > 0
                        ? (
                            affectedUserList.length < 4 ? (
                                    areAllUsersAffected==true
                                        ? 'All Users'
                                        : affectedUserList.join(', ')
                                )
                            : (
                                    <React.Fragment>
                                    <Link
                                        component="button"
                                        color="inherit"
                                        onClick={() => setOpenAffectedUsersDialog(true)}
                                        variant="body2"
                                    >
                                        {areAllUsersAffected==true ? 'All Users' : affectedUserList.length + ' Users'}
                                    </Link>
                                    <AlertDialog
                                        title="Affected users:"
                                        text={
                                        <div className={classes.affectedUserListHeight}>{
                                            affectedUserList.map((listItem, idx) => (
                                                <React.Fragment key={idx}>
                                                    {listItem}
                                                    <br />
                                                </React.Fragment>
                                            ))}
                                        </div>
                                    }
                                        openState={openAffectedUsersDialog}
                                        onClose={() => setOpenAffectedUsersDialog(false)}
                                    />
                                    </React.Fragment>
                                )
                        )
                        :
                        t('noSelection')
                }
        </React.Fragment>
    );

}


export default AffectedUserList;