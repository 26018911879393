import * as React from 'react';
import { SvgIcon as MuiSvgIcon, styled } from '@mui/material';

const SvgIcon = styled(MuiSvgIcon, {
       name: 'NotificationsIcon',
       shouldForwardProp: (prop) => prop !== 'fill',
     })(() => ({
        fill: '#fff',
        stroke: '#000',
        strokeLinecap: 'round',
        strokeLinejoin:  'round',
        strokeWidth:  '1.5px',
        strokeMiterlimit: 10
     }));

    SvgIcon.defaultProps = {
        viewBox: '0 0 60 60',
        focusable: 'false',
        'aria-hidden': 'true'
    };

 const NotificationsIcon = (props) => {
      return (
              <SvgIcon
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{enableBackground:"new 0 0 60 60"}}
                  {...props}
              >
                   <style type="text/css">
                      .st0{"fill:#FFFFFF;stroke:#000000;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;"}
                  </style>
                  <g>
                      <circle className="st0" cx="44.1" cy="15.9" r="3.8"/>
                      <path className="st0" d="M28.2,41.1c-2.6,2.6-6.7,2.6-9.3,0s-2.6-6.7,0-9.3"/>
                      <path className="st0" d="M35,47.9c-0.6-4.5,0.8-9.2,4.2-12.7c0.4-0.4,1.9-1.7,2.2-1.9c4-4,4-10.6,0-14.7l0,0c-4-4-10.6-4-14.7,0
                      c-0.3,0.3-1.5,1.7-1.9,2.2c-3.5,3.5-8.2,4.9-12.7,4.2L35,47.9z"/>
                  </g>
              </SvgIcon>
      );
     };

export default NotificationsIcon;