import React, {Component} from 'react';
import { withStyles} from '@material-ui/core/styles';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

const detailViewStyles = theme => ({
    expandablePanel: {

    },
    expandablePanelTitle: {
        fontWeight: 600
    },
    tableCell: {
        verticalAlign: 'top',
        border: 0
    },
    tableCellLabel: {
        verticalAlign: 'top',
        border: 0,
        fontWeight:600,
        width:160
    },
});

class DetailView extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { classes } = this.props,
                rowData = this.props.rowData;

        return (
            <Table className={classes.expandablePanel}>
                <TableBody>
                    <TableRow>
                        <TableCell className={classes.tableCellLabel}> Username </TableCell>
                        <TableCell className={classes.tableCell}> { rowData[1] } </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.tableCellLabel}> Create Date </TableCell>
                        <TableCell className={classes.tableCell}> { rowData[3] } </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.tableCellLabel}> Status </TableCell>
                        <TableCell className={classes.tableCell}> { rowData[4] } </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.tableCellLabel}> Name </TableCell>
                        <TableCell className={classes.tableCell}> { rowData[2] } </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    }

}

export default withStyles(detailViewStyles)(DetailView);