import React  from 'react';
import { useTranslation } from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import { useAppLoadingState } from "../../../context/appLoadingState";
//import axios from "axios";
//import { serialize } from 'object-to-formdata';
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import LanguageSwitcher from "../../../components/Layout/Partial/LanguageSwitcher";


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        width:'40%'
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    buttonWithSpace: {
        marginTop: 30
    }
}));


function Preferences(props) {

    const classes = useStyles();
    const { t } = useTranslation();
    //const { updateAppLoadingState } = useAppLoadingState();

    return (
            <div className={classes.root}>
                <Typography variant="h4" gutterBottom={true}>
                    {t('preferences')}
                </Typography>
                <form className={classes.form} noValidate autoComplete="off">
                    <Grid container className={classes.gridContainer}>
                        <Grid item className={classes.gridItem}>
                            <LanguageSwitcher/>
                        </Grid>
                    </Grid>

                </form>
            </div>
        );
}

export default Preferences;