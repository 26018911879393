import React, {useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from "react-router-dom";
import { useAppLoadingState } from "../../../context/appLoadingState";
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import SearchBar from './Components/SearchBar';
import CategoryTree from './Components/CategoryTree';
import Breadcrumbs from './Components/Breadcrumbs';
import ArticleList from './Components/ArticleList';
import ArticleView from "./Components/ArticleView";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
}));

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function KnowledgeBase(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const browserLocation = useQuery();
    const browserHistory = useHistory();
    const [ articleId, setArticleId ] = React.useState( browserLocation.get('articleId') );
    const { updateAppLoadingState } = useAppLoadingState();
    const [ isDataStillProcessing, setIsDataStillProcessing] = React.useState({
        categoryTree:false,
        articleList:false
    });


    const [articleViewTitle, setArticleViewTitle] = React.useState('');
    const [articleViewContent, setArticleViewContent] = React.useState('');

    const [searchFilters, setSearchFilters] = React.useState({
        languageId: 1,
        page: 0,
        categoryId: null,
        articleId: articleId,
        query: ''
    });

    const [selectedCategoryTreeItems, setSelectedCategoryTreeItems] = React.useState([1]);

    const searchInputRef = React.useRef(null);
    const [searchInputWasCleared, setSearchInputWasCleared] = React.useState(false);
    const [searchButtonWasClicked, setSearchButtonWasClicked] = React.useState(false);
    const [searchWasInit, setSearchWasInit] = React.useState(false);

    const [breadcrumbData, setBreadcrumbData ] = React.useState({
        data: {
            total: 0,
            hasNoMatches: false,
            categoryName: null,
            articleId: articleId,
            query: null
        },
        text: ''
    });

    const [articleList, setArticleList] = React.useState({});

    // on clear filters
    const handleClearSearchFilters = (event) => {
        browserLocation.delete('articleId');
        browserHistory.replace({
            search: browserLocation.toString(),
        });
        setSearchFilters((prevState) => ({
            ...prevState,
            categoryId: null,
            articleId: null,
            page:0,
            query: ''
        }));
        setBreadcrumbData((prevState) => ({
            ...prevState,
            data: {
                ...prevState.data,
                total: 0,
                categoryName: null,
                articleId: null,
                query: ''
            }
        }));
        setSelectedCategoryTreeItems([1]);
        if( searchInputRef ) {
            searchInputRef.current.focus();
        }
        setSearchInputWasCleared(true);
    }

    // execute once on load
    useEffect(() => {
        console.log(articleId);
    }, [] );

    // handle the correct display of loading overlay for multiple data processes
    useEffect(() => {
        if( isDataStillProcessing.categoryTree || isDataStillProcessing.articleList ) {
            updateAppLoadingState(true);
        } else {
            updateAppLoadingState(false);
        }
    }, [isDataStillProcessing] );

    // render view
    return (
        <div className={classes.root}>
                <React.Fragment>
                    <Typography variant="h4" gutterBottom={true}>
                        {t('findAnswers')}
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} md={12} lg={7} xl={8}>
                                    <SearchBar
                                        simpleMode={false}
                                        hasSearchButton={true}
                                        placeholderText={t('whatAreYouLookingFor') + '?'}
                                        searchInputRef={searchInputRef}
                                        searchFilters={searchFilters}
                                        setSearchFilters={setSearchFilters}
                                        setSearchButtonWasClicked={setSearchButtonWasClicked}
                                        setSearchWasInit={setSearchWasInit}
                                        setSearchInputWasCleared={setSearchInputWasCleared}
                                        searchInputWasCleared={searchInputWasCleared}
                                        setArticleList={setArticleList}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <CategoryTree
                                setIsDataStillProcessing={setIsDataStillProcessing}
                                setSearchFilters={setSearchFilters}
                                setBreadcrumbData={setBreadcrumbData}
                                selectedCategoryTreeItems={selectedCategoryTreeItems}
                                setSelectedCategoryTreeItems={setSelectedCategoryTreeItems}
                            />
                        </Grid>
                        <Grid item xs={9}>
                                <Grid container>
                                    <Breadcrumbs
                                        searchFilters={searchFilters}
                                        breadcrumbData={breadcrumbData}
                                        setBreadcrumbData={setBreadcrumbData}
                                        handleClearSearchFilters={handleClearSearchFilters}
                                    />
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <ArticleList
                                            setIsDataStillProcessing={setIsDataStillProcessing}
                                            searchFilters={searchFilters}
                                            setSearchFilters={setSearchFilters}
                                            setBreadcrumbData={setBreadcrumbData}
                                            handleClearSearchFilters={handleClearSearchFilters}
                                            setSearchInputWasCleared={setSearchInputWasCleared}
                                            searchInputWasCleared={searchInputWasCleared}
                                            articleList={articleList}
                                            setArticleList={setArticleList}

                                            setArticleId={setArticleId}
                                            setArticleViewTitle={setArticleViewTitle}
                                            setArticleViewContent={setArticleViewContent}
                                        />
                                    </Grid>
                                </Grid>
                        </Grid>
                    </Grid>
                </React.Fragment>
        </div>
    );
}

export default KnowledgeBase;