import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import translationsEN from './translations/en/en.json'
import translationsDE from './translations/de/de.json'
import translationsHE from './translations/he/he.json'

const resources = {
    en: {
        translation: translationsEN
    },
    de: {
        translation: translationsDE
    },
    he: {
        translation: translationsHE
    }
};

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        fallbackLng: 'en',
        keySeparator: false,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;