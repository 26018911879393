import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import {TextValidator} from "react-material-ui-form-validator";
import InputAdornment from "@material-ui/core/InputAdornment";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import { makeStyles } from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";
import TextField from "@material-ui/core/TextField";
import StrengthIndicator from './StrengthIndicator';

const useStyles = makeStyles(theme => ({
    showPasswordIcon: {
        color:'#888',
        cursor: 'pointer',
    },
}));

const PasswordField= React.forwardRef((props, ref) => {
    const classes = useStyles();

    const { t } = useTranslation();
    const {
        id,
        autoFocus,
        autoComplete,
        name,
        label,
        password,
        setPassword,
        isRequired,
        useTextValidator,
        handleLastInputKeyPress,
        showStrengthIndicator,
        passwordScoreCallback,
    } = props;

    const [ passwordIsMasked, setPasswordIsMasked] = React.useState(true);

    function togglePasswordMask() {
        setPasswordIsMasked((prevState) => (!prevState));
    }

    const handleOnPaste = (e) => {
        let pwd = e.clipboardData.getData('text');
        setPassword(pwd);
    }

    function handlePasswordScore(score) {
        if(typeof passwordScoreCallback==='function' ) {
            passwordScoreCallback(score);
        }
    }

    function handleChange(e) {
        let pwd = e.target.value;
        setPassword(pwd);
    }

    const TextComponent = useTextValidator===true ? TextValidator : TextField;
    return (
        <React.Fragment>
            <TextComponent
                variant="outlined"
                size="small"
                margin="normal"
                fullWidth
                inputRef={ref}
                name={name.length ? name : 'password'}
                label={label ? label : t('password')}
                type={passwordIsMasked ? 'password' : 'text'}
                id={id.length ? id : 'password'}
                autoFocus={autoFocus ? autoFocus : false}
                autoComplete={autoComplete ? autoComplete : "current-password"}
                value={password}
                style={showStrengthIndicator ? {marginBottom:1} : null}
                onChange={handleChange}
                // onPaste={handleOnPaste}
                onKeyDown={ typeof handleLastInputKeyPress==='function' ? handleLastInputKeyPress : function(){}}
                InputProps={{
                    "data-cy": "cy-login-form-field-password",
                    endAdornment: (
                        <InputAdornment position="end">
                            {passwordIsMasked ?
                                <VisibilityOff
                                    className={classes.showPasswordIcon}
                                    onClick={togglePasswordMask}
                                />
                                :
                                <Visibility
                                    className={classes.showPasswordIcon}
                                    onClick={togglePasswordMask}
                                />
                            }
                        </InputAdornment>
                    ),
                }}
                validators={ isRequired ? ['required'] : null}
                errorMessages={ isRequired ? ['Password is a required field.'] : null}
            />
            {showStrengthIndicator == true &&
                <StrengthIndicator
                    toggle={true}
                    password={password}
                    callbackHandler={(score)=>handlePasswordScore(score)}
                />
            }
        </React.Fragment>
    );
})

export default PasswordField;