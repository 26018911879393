
import loginHelpEnglish from 'assets/pdfs/user-manual-login-en.pdf';
import loginHelpGerman from 'assets/pdfs/user-manual-login-de.pdf';


const pdfFile= window.localStorage.i18nextLng === "de" ? loginHelpGerman : loginHelpEnglish


const NeedHelpLoggingIn = () => {
    return (
        <div style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            zIndex: 9999
        }}>
            <object
                data={pdfFile}
                width="100%"
                height="100%"
                type="application/pdf"
            >
            </object>
        </div>
        );
};

export default NeedHelpLoggingIn