import * as React from 'react';
import { SvgIcon as MuiSvgIcon, styled } from '@mui/material';

const SvgIcon = styled(MuiSvgIcon, {
       name: 'BillingIcon',
       shouldForwardProp: (prop) => prop !== 'fill',
     })(() => ({
        fill: '#eee',
        stroke: '#1D1D1B',
        strokeLinecap: 'round',
        strokeLinejoin:  'round',
        strokeWidth:  '1.5px',
        strokeMiterlimit: 10
     }));

    SvgIcon.defaultProps = {
        viewBox: '0 0 60 60',
        focusable: 'false',
        'aria-hidden': 'true'
    };

 const BillingIcon = (props) => {
      return (
              <SvgIcon
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{enableBackground:"new 0 0 60 60"}}
                  {...props}
              >
                  <style type="text/css">
                      .st0{"fill:#FFFFFF;stroke:#000000;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;"}
                  </style>
                  <g>
                      <polygon className="st0" points="45.9,13.6 41.9,12.4 37.9,13.6 34,12.4 30,13.6 26,12.4 22.1,13.6 18.1,12.4 14.1,13.6 14.1,46.4
		18.1,47.6 22.1,46.4 26,47.6 30,46.4 34,47.6 37.9,46.4 41.9,47.6 45.9,46.4 	"/>
                      <line className="st0" x1="22" y1="41.6" x2="31" y2="41.6"/>
                      <line className="st0" x1="22" y1="36.9" x2="40.8" y2="36.9"/>
                      <line className="st0" x1="36.3" y1="32.2" x2="40.8" y2="32.2"/>
                      <line className="st0" x1="19.1" y1="18.1" x2="35.4" y2="18.1"/>
                      <line className="st0" x1="19.1" y1="22.8" x2="26.2" y2="22.8"/>
                      <line className="st0" x1="19.1" y1="27.5" x2="26.2" y2="27.5"/>
                  </g>
              </SvgIcon>
      );
     };

export default BillingIcon;