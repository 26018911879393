import * as React from 'react';
import { SvgIcon as MuiSvgIcon, styled } from '@mui/material';

const SvgIcon = styled(MuiSvgIcon, {
       name: 'CloudiaxIconKnowledgeBase',
       shouldForwardProp: (prop) => prop !== 'fill',
     })(() => ({
        fill: '#eee',
        stroke: '#1D1D1B',
        strokeLinecap: 'round',
        strokeLinejoin:  'round',
        strokeWidth:  '1.5px',
        strokeMiterlimit: 10
     }));

    SvgIcon.defaultProps = {
        viewBox: '0 0 60 60',
        focusable: 'false',
        'aria-hidden': 'true'
    };

 const CloudiaxIconKnowledgeBase = (props) => {
      return (
              <SvgIcon
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{enableBackground:"new 0 0 60 60"}}
                  {...props}
              >
                  <style type="text/css">
                      .st10{"fill:#FFFFFF;stroke:#1D1D1B;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;"}
                      .st11{"fill:none;stroke:#1D1D1B;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;"}
                  </style>
                  <path className="st10"
                        d="M38.55,34.1l-3.71-1.86l-4.06-5.39l-3.42,1.12l5.39,6.8l4.85,1.61c0.66,0.19,1.36-0.19,1.55-0.85 C39.3,34.95,39.04,34.38,38.55,34.1z"/>
                  <path className="st10"
                        d="M26.27,46.48l2.11-7.53l-3.68-1.76l-2.1,8.23l-3.02,6.53c-0.13,0.24-0.22,0.51-0.22,0.81 c0,0.97,0.79,1.76,1.76,1.76c0.69,0,1.27-0.4,1.56-0.98L26.27,46.48z"/>
                  <path className="st10"
                        d="M36.53,51.76l-7.45-13.61l1.94-9.46c0.01-0.02,0.01-0.05,0.01-0.08l0.01-0.06h-0.01 c0.04-0.21,0.06-0.43,0.06-0.65c0-2.03-1.65-3.68-3.68-3.68c-0.9,0-1.72,0.32-2.35,0.85c-0.24,0.2-0.44,0.43-0.62,0.68l-5.09,5.6 l-1.44,6.5c0,0.69,0.56,1.25,1.25,1.25c0.59,0,1.06-0.42,1.19-0.97l1.68-5.39l2.36-2.13c-0.21,2.14-0.59,6.1-0.66,7.04 c-0.17,2.28,1.33,3.93,1.33,3.93l8.71,12.27c0.32,0.38,0.79,0.64,1.33,0.64c0.97,0,1.76-0.79,1.76-1.76 C36.88,52.37,36.73,52.04,36.53,51.76z"/>
                  <path className="st10"
                        d="M37.25,13.74c0,4.55-3.69,8.25-8.25,8.25c-4.56,0-8.25-3.69-8.25-8.25c0-4.55,3.69-8.24,8.25-8.24 C33.55,5.5,37.25,9.19,37.25,13.74z"/>
                  <line className="st11" x1="41.32" y1="26.06" x2="37.24" y2="21.99"/>
              </SvgIcon>
      );
     };

export default CloudiaxIconKnowledgeBase;