import axios from "axios";
import {serialize} from "object-to-formdata";

const http = axios.create({
    baseURL: '/api/v1/support/ticket/media',
    headers: {
        "Content-type": "application/json"
    }
});

class MediaManagementService {

    http() {
        return http;
    }

    /**
     * Upload media
     */
    upload(ticketId, file, onUploadProgress) {
        let formData = new FormData();

        formData.append('ticketId', ticketId);
        formData.append('file', file);

        return http.post("/upload", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress,
        });
    }

    /**
     * Delete media
     */
    delete(ticketId, id, onComplete) {
        return http.post("/delete", serialize({
            ticketId: ticketId,
            id: id
        }))
        .then((response) => {
            if( typeof onComplete === 'function') {
                onComplete(response);
            }
        });
    }

    /**
     * get media
     */
    get(ticketId, fileId) {
        return http.post(
            "/get",
            serialize({
                ticketId: ticketId,
                id: fileId
            }),
            {
                responseType: 'blob',
            })
            .then((response) => {
                const blob = new Blob([response.data], {type:  response.headers['content-type'] });
                let reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                    return reader.result;
                }
            });
    }

}

export default new MediaManagementService();