import React, { useState } from "react";

import LanguageIcon from "@mui/icons-material/Language";
import { useTranslation } from "react-i18next";

import NavBar from "../Partial/NavBar/NavBar";
import RouteBuilder from "../../Routing/Layout/Forms/RouteBuilder";

function Forms() {
  const { i18n } = useTranslation();

  const [locale, setLocale] = useState(
    (i18n.language || window.localStorage.i18nextLng) === "de" ? "de" : "en"
  );
  const handleLanguageChange = (locale) => {
    setLocale(locale);
    i18n.changeLanguage(locale);
  };

  const urlHome =
    locale === "de"
      ? "https://www.cloudiax.com/de/"
      : "https://www.cloudiax.com/";

  return (
    <React.Fragment>
      <NavBar
        title={" "}
        leftDrawerIsOpen={false}
        logoLinkPath={urlHome}
        navbarItems={[
          {
            id: "navbar-languageMenu",
            icon: <LanguageIcon sx={{ fontSize: "2.5rem" }} />,
            ariaLabel: "Language",
            onClick: null,
            dropdownMenu: {
              id: "menu-account",
              items: [
                {
                  id: "navbar-item-english",
                  caption: "English",
                  selected: locale === "en",
                  onClick: () => {
                    handleLanguageChange("en");
                  },
                },
                {
                  id: "navbar-item-german",
                  caption: "Deutsch",
                  selected: locale === "de",
                  onClick: () => {
                    handleLanguageChange("de");
                  },
                },
              ],
            },
          },
        ]}
      />
      <RouteBuilder />
    </React.Fragment>
  );
}

export default Forms;
