import React from 'react';
import { useTranslation } from 'react-i18next';
//import { makeStyles } from '@material-ui/core/styles';
import ExtendedDataTable from "../../../../Combinations/ExtendedDataTable";
import DetailView  from "./DetailView";

function List(props) {

    const { t } = useTranslation();

    return (
        <React.Fragment>
            <ExtendedDataTable
                isOpen={true}
                isDenseTable={false}
                title={t('yourUsers')}
                columns={[
                    {
                      name: "id",
                      label: "Id",
                      options: {
                          display: 'excluded'
                      }
                    },
                    {
                        name: "username",
                        label: t('username'),
                        options: {
                            filter: true,
                            sort: true,

                        }
                    },
                    {
                        name: "name",
                        label: t('name'),
                        options: {
                            filter: true,
                            sort: true,

                        }
                    },
                    {
                        name: "createDate",
                        label: t('createDate'),
                        options: {
                            filter: true,
                            sort: false,

                        }
                    },
                    {
                        name: "status",
                        label: t('status'),
                        options: {
                            filter: true,
                            sort: false,

                        }
                    },
                ]}
                expandableComponent={DetailView}
                options={{
                    /*
                    serverSide: true,
                    onTableChange: (action, tableState) => {
                        this.xhrRequest('my.api.com/tableData', result => {
                            this.setState({ data: result });
                        });
                    },
                     */
                    onRowClick: function(e) {

                    }
                }}
                data={[
                    [100, "c99111.01", "Max Mustermann", "01.01.2016", "Active"],
                    [100, "c99111.05", "Josef Wolf", "07.02.2016", "Disabled"],
                    [100, "c99111.07", "Paul Janssen", "12.05.2017", "Active"],

                ]}
            />
        </React.Fragment>
    );
}

export default List;