import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { useAppLoadingState } from "../../../context/appLoadingState";
import axios from "axios";
import { serialize } from 'object-to-formdata';
import Button from "@material-ui/core/Button";

import Grid from '@mui/material/Grid';
import Card from '@material-ui/core/Card';
import Checkbox from '@mui/material/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@mui/material/ListItemButton';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Typography from "@material-ui/core/Typography";
import { useTranslation } from 'react-i18next';
import ReadNotification from "@mui/icons-material/MarkEmailRead";
import UnreadNotification from "@mui/icons-material/Email";

import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        width:'40%'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    buttonWithSpace: {
        marginTop: 30
    },
    inboxList: {

    },
    isUnread: {
        fontWeight: 'bold'
    },
    isRead: {
        opacity: 0.5
    }
}));


function Inbox(props) {

    const classes = useStyles();
    const { updateAppLoadingState } = useAppLoadingState();
    const { t } = useTranslation();

    const [ inbox, setInbox ] = React.useState({list:[]});

    const [checkboxList, setCheckboxList] = React.useState([]);

    const handleToggleCheckbox = (value) => () => {
        const currentIndex = checkboxList.indexOf(value);
        const newChecked = [...checkboxList];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setCheckboxList(newChecked);
    };

    function loadList() {
        updateAppLoadingState(true);
        axios.post(
            "/api/v1/user/inbox/list"
        ).then(result => {
            updateAppLoadingState(false);
            if (result.status === 200) {
                setInbox(result.data);
            }
        });
    }

    useEffect(() => {
        loadList();
    }, []);


    return (
        <React.Fragment>
            <Typography variant="h4" gutterBottom={true}>
                Inbox
            </Typography>
            <Card variant="outlined">
                <Box sx={{ flexGrow: 1 }}>
                    <Toolbar>
                        <Box sx={{ flexGrow: 1 }} />
                        <Box>
                            <IconButton
                                size="small"
                                edge="end"
                                aria-label="Delete"
                                //aria-controls={menuId}
                                aria-haspopup="true"
                                //onClick={handleProfileMenuOpen}
                                color="inherit"
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </Box>
                <List dense={true} className={classes.inboxList}>
                    {inbox.list.map(function (item, index) {
                        return (
                            <ListItem
                                onClick={null}
                                data-cy={'cy-inbox-list-item' + index}
                                className={item.isRead ? classes.isRead : classes.isUnread}
                            >
                                <ListItemIcon onClick={handleToggleCheckbox(index)}>
                                    <Checkbox
                                        edge="start"
                                        checked={checkboxList.indexOf(index) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': 'inbox-list-item-index-'+index }}
                                    />
                                </ListItemIcon>
                                <ListItemIcon>
                                    {item.isRead
                                        ? <ReadNotification fontSize="small"/>
                                        : <UnreadNotification fontSize="small"/>
                                    }
                                </ListItemIcon>
                                <ListItemText
                                    primary={item.title}
                                    secondary={item.teaserText}
                                />
                            </ListItem>)
                    })}
                </List>
            </Card>
        </React.Fragment>
    );
}

export default Inbox;