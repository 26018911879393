import React from 'react';
import { appRouteMapping } from "./Route.mapping";
import { Route } from "react-router-dom";

function RouteBuilder(props) {
    return appRouteMapping.map(({route, exact, component }, key) => {
        return (
            <Route key={route} exact={exact} path={route} component={component}/>
        );
    });
}

export default RouteBuilder;