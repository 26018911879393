import React from 'react';
import { useTranslation,Trans } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    loginOtherOptionsTitle: {
        marginTop: 40,
    },
    link: {
        '&:hover, &:focus': {
            cursor: 'pointer',
        },
    }
}));

function NoLogin(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <React.Fragment>
                <form className={classes.form} noValidate>
                    <Typography component="p" variant="body1" gutterBottom={true}>
                        {t("noLoginSecurityManagerInfo")}
                    </Typography>

                    <Typography component="p" variant="body1">
                        <Trans i18nKey="noLoginNoSecurityManagerInfo">
                            child0
                            <Link
                                data-cy="cy-no-login-formPDF-link"
                            target="_blank" href="https://www.cloudiax.com/download/security-manager-en.pdf">child1
                            </Link>
                            child2
                            <Link
                                data-cy="cy-no-login-sendForm-link"
                                href="mailto:order@cloudiax.com">child3
                            </Link>.
                            child4
                        </Trans>
                     </Typography>



                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Button
                                component={Link}
                                href="/login"
                                fullWidth
                                variant="text"
                                color="primary"
                                className={classes.submit}
                                data-cy="cy-no-login-back-btn"
                            >
                                {t('backToLoginPage')}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
        </React.Fragment>
    );
}

export default NoLogin;