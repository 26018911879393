import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { useAppLoadingState } from "../../../context/appLoadingState";
import axios from "axios";
import { serialize } from 'object-to-formdata';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import DoneIcon from '@mui/icons-material/Done';
import Typography from "@material-ui/core/Typography";
import ResponseToast from "../../Combinations/ResponseToast";
import { useTranslation } from 'react-i18next';
import TotpValidator from "../../Shared/Authentication/TotpValidator";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        width:'40%'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    buttonWithSpace: {
        marginTop: 30
    }
}));

function Profile(props) {

    const classes = useStyles();
    const { updateAppLoadingState } = useAppLoadingState();
    const { t } = useTranslation();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [telephoneNumber, setTelephoneNumber] = useState("");
    const [mail, setMail] = useState("");
    const [currentFirstName, setCurrentFirstName] = useState("");
    const [currentLastName, setCurrentLastName] = useState("");
    const [currentTelephoneNumber, setCurrentTelephoneNumber] = useState("");
    const [currentMail, setCurrentMail] = useState("");

    const [profileUpdateSuccessState, setProfileUpdateSuccessState] = useState(null);
    const [ totpValidatorOpenState, setTotpValidatorOpenState ]  = useState(false);

    useEffect(() => {
        fetchProfile();
    }, []);

    function fetchProfile() {
        updateAppLoadingState(true);

        axios.post("/api/v1/profile/fetch").then(result => {
            updateAppLoadingState(false);
            if (result.status === 200) {
                let profile = result.data;
                if(profile) {
                    setFirstName(profile.firstName);
                    setLastName(profile.lastName);
                    setTelephoneNumber(profile.telephone);
                    setMail(profile.mail);
                    setCurrentFirstName(profile.firstName);
                    setCurrentLastName(profile.lastName);
                    setCurrentTelephoneNumber(profile.telephone);
                    setCurrentMail(profile.mail);
                }
            }
        }).catch(e => {
            console.log(e);
            updateAppLoadingState(false);
        });
    }

    function formSubmit() {
        if( mail != currentMail ) {
            setTotpValidatorOpenState(true);
        } else {
            updateProfile(null, function(){

            });
        }
    }

    function wasProfileDataUpdated() {
        if( currentMail === mail
            && currentFirstName === firstName
            && currentLastName === lastName
            && currentTelephoneNumber === telephoneNumber) {
            return false;
        }
        return true;
    }

    function updateProfile(totp, callback) {
        updateAppLoadingState(true);

        axios.post(
            "/api/v1/profile/edit",
            serialize({
                firstName: firstName,
                lastName: lastName,
                telephoneNumber: telephoneNumber,
                mail: mail,
                totp : totp
            })
        ).then(result => {
            console.log(result);
            updateAppLoadingState(false);
            if (result.status === 200) {
                if(result.data.success) {
                    setProfileUpdateSuccessState(true);
                } else {
                    setProfileUpdateSuccessState(false);
                }
                if( typeof callback === 'function' ) {
                    callback(result.data);
                }
            }
        }).catch(e => {
            console.log(e);
            updateAppLoadingState(false);
            setProfileUpdateSuccessState(false);
        });
    }

    return (
            <React.Fragment>
                <Typography variant="h4" gutterBottom={true}>
                    {t('editYourProfile')}
                </Typography>
                <form className={classes.root} noValidate autoComplete="off">
                    <TextField
                        id="firstName"
                        label={t('firstName')}
                        fullWidth
                        size="small"
                        margin="normal"
                        variant="outlined"
                        value={firstName}
                        inputProps={{
                            "data-cy": "cy-profile-edit-form-field-firstname",
                        }}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                    <TextField
                        id="lastName"
                        label={t('lastName')}
                        fullWidth
                        size="small"
                        margin="normal"
                        variant="outlined"
                        value={lastName}
                        inputProps={{
                            "data-cy": "cy-profile-edit-form-field-lastName",
                        }}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                    <TextField
                        id="telephone"
                        label={t('telephone')}
                        fullWidth
                        size="small"
                        margin="normal"
                        variant="outlined"
                        value={telephoneNumber}
                        inputProps={{
                            "data-cy": "cy-profile-edit-form-field-telephone",
                        }}
                        onChange={(e) => setTelephoneNumber(e.target.value)}
                    />
                    <TextField
                        id="email"
                        label={t('email')}
                        fullWidth
                        size="small"
                        margin="normal"
                        variant="outlined"
                        value={mail}
                        inputProps={{
                            "data-cy": "cy-profile-edit-form-field-email",
                        }}
                        onChange={(e) => setMail(e.target.value)}
                    />
                    <Button
                        disabled={!wasProfileDataUpdated()}
                        data-cy="cy-profile-edit-submit-btn"
                        variant="contained" color="secondary" margin="normal" startIcon={<DoneIcon/>} className={classes.buttonWithSpace} onClick={formSubmit}>
                        {t('saveChanges')}
                    </Button>
                </form>
                <ResponseToast
                    key="profileEditResponseToast"
                    autoHideDuration={3000}
                    responseStatus={profileUpdateSuccessState}
                    successMessage={t('yourProfileWasUpdatedSuccessfully')}
                    failureMessage={t('failedToUpdateProfilePleaseTryAgainLater')}
                    closeHandler={() => setProfileUpdateSuccessState(null)}
                />
                <TotpValidator
                    openState={totpValidatorOpenState}
                    setOpenState={setTotpValidatorOpenState}
                    onSubmit={(totp,callback) => updateProfile(totp,callback)}
                />
            </React.Fragment>
        );
}

export default Profile;