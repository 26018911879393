import React, {useState, useEffect } from 'react';
import {useTheme, makeStyles, createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useAppLoadingState } from "../../../../context/appLoadingState";
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';


const useStyles = makeStyles(theme => ({
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    gridContainer: {
        margin: theme.spacing(1),
    },
    gridItem: {
        margin: theme.spacing(1),
        marginRight:  theme.spacing(3),
    },
    dropdown1: {
        minWidth: 190,
    },
}));

function LanguageSwitcher(props) {
    const classes = useStyles();
    const { updateAppLoadingState } = useAppLoadingState();
    const { t, i18n } = useTranslation();
    const theme = useTheme();

    const [locale, setLocale] = useState(
         (i18n.language || window.localStorage.i18nextLng) === "de" ? "de" : "en"
      );



    const handleChange = (event) => {
        updateAppLoadingState(true);
        setLocale(event.target.value);
    }

    useEffect(() => {
        i18n.changeLanguage(locale)
    }, [locale]);

    i18n.on('languageChanged', function(lng) {
        document.body.dir = i18n.dir();
        theme.direction = i18n.dir();
        updateAppLoadingState(false);
    });

    return (
        <TextField
            select
            size='small'
            labelId="language"
            id="language"
            label={props.mini ? null : t('displayLanguage')}
            value={locale}
            onChange={handleChange}
            variant="outlined"
            className={props.mini ? '' : classes.dropdown1}
            inputProps={{
                "data-cy": "cy-language-switcher"
            }}
        >
            <MenuItem key="en" value="en">English</MenuItem>
            <MenuItem key="de" value="de">German</MenuItem>
            {/* <MenuItem key="he" value="he">עברית</MenuItem> */}
        </TextField>
    );
}

export default LanguageSwitcher;