import React from 'react';
//import { makeStyles } from '@material-ui/core/styles';
import List from './List';

function Databases(props) {

    return (
        <List/>
    );
}

export default Databases;