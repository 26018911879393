import axios from "axios";
import {serialize} from "object-to-formdata";

class Timer {
    getStorage(timerId) {
        const storageId = 'swo_'+timerId;
        let sws = window.localStorage.getItem(storageId),
            swo = sws ? JSON.parse(sws) : {};
        return swo;
    };

    setStorage(timerId, data) {
        const storageId = 'swo_'+timerId;
        window.localStorage.setItem(storageId, JSON.stringify(data));
    }

    set(timerId, uid, timeoutMins, callback) {
        let swo = this.getStorage(timerId);

        swo[uid] = {
            uid: parseInt(uid),
            ts: new Date(),
            tm: timeoutMins
        };
        this.setStorage(timerId, swo)
        this.maintain(timerId, uid,callback);
    };

    maintain(timerId, uid, callback) {
        let swo = this.getStorage(timerId);
        if( Object.keys(swo).length ) {
            let so = swo[uid];
            if( so ) {
                const timeoutMins = so.tm,
                    storedTS = new Date(so.ts),
                    nowTS = new Date(),
                    runtimeMS = Math.abs(nowTS - storedTS),
                    timeLeftMS = (timeoutMins * 1000 * 60) - runtimeMS;

                let timeoutTimer = setTimeout(
                    function () {
                        if (typeof callback === 'function') {
                            callback();
                        }
                    }, timeLeftMS);
            }
        }
    };

    status(timerId, uid) {
        let swo = this.getStorage(timerId);
        if( Object.keys(swo).length ) {
            let so = swo[uid];
            if( so ) {
                const storedTS = new Date(so.ts),
                    nowTS = new Date(),
                    runtimeMS = Math.abs(nowTS - storedTS),
                    timeLeftMS = (so.tm * 1000 * 60) - runtimeMS,
                    runtimeMins = Math.floor(runtimeMS / 60000),
                    runtimeSecs = Math.round((runtimeMS % 60000) / 1000),
                    countdownMins = Math.floor(timeLeftMS / 60000),
                    countdownSecs = Math.round((timeLeftMS % 60000) / 1000);

                return {
                    countdown: {
                        raw: {
                            minutes: countdownMins,
                            seconds: countdownSecs
                        }
                    },
                    runtime: {
                        raw: {
                            minutes: runtimeMins,
                            seconds: runtimeSecs
                        },
                        stdout: ((runtimeMins < 10) ? '0' + runtimeMins : runtimeMins) + ':' + ((runtimeSecs < 10) ? '0' + runtimeSecs : runtimeSecs)
                    }
                };
            }
        }
    }

    removeTimer(timerId, uid) {
        let swo = this.getStorage(timerId);
        if( Object.keys(swo).length && swo[uid]) {
            delete swo[uid];
            this.setStorage(timerId,swo);
        }
    }

    remoteCheck(endpoint, timerId, params,callback) {
        let swo = this.getStorage(timerId);
        if( Object.keys(swo).length ) {
            axios.post(
                endpoint,
                serialize(params)
            ).then(result => {
                if (result.status === 200) {
                    if(typeof callback === 'function') {
                        callback(result.data);
                    }
                }
            }).catch(e => {
            });
        }
    }

    check(timerId, uid) {
        let swo = this.getStorage(timerId);
        if( !Object.keys(swo).length || !swo[uid]) {
            return true;
        }

        let so = swo[uid];
        if( so ) {
            const storedTS = new Date(so.ts);
            const nowTS = new Date();
            const expiryMins = so.tm * 60 * 1000;

            // timer reached
            if ((nowTS - storedTS) > expiryMins) {
                this.removeTimer(timerId);
                return true;
            } else {
                // not yet..
                return false;
            }
        }
    };
}

export default new Timer();