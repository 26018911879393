import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { useAppLoadingState } from "../../../../context/appLoadingState";
import axios from "axios";
import { serialize } from 'object-to-formdata';
import Grid from "@material-ui/core/Grid";
import PasswordField from "../../../Shared/PasswordField";
import Button from "@material-ui/core/Button";
import DoneIcon from '@mui/icons-material/Done';
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';
import ResponseToast from "../../../Combinations/ResponseToast";
import { useTranslation } from 'react-i18next';
import TotpValidator from '../../../Shared/Authentication/TotpValidator';
import PasswordStrengthChecklist from '../../../Shared/PasswordField/PasswordStrengthChecklist';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        width:'100%'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    captionTopSpace: {
        display: 'block',
        marginTop: 20,
    },
    buttonWithSpace: {
        marginTop: 30
    },
    showPasswordIcon: {
        color:'#888',
        cursor: 'pointer',
    },
}));

function ChangePassword(props) {

    const classes = useStyles();
    const { updateAppLoadingState } = useAppLoadingState();
    const { t } = useTranslation();

    const [ passwordChangeSuccessState, setPasswordChangeSuccessState ] = useState(null);
    const [ responseFailureMessage, setResponseFailureMessage ] = useState("");

    const [ password, setPassword ]  = useState("");
    const [ newPassword, setNewPassword ] = useState("");
    const [ newPasswordRepeat, setNewPasswordRepeat ] = useState("");

    const [ totpValidatorOpenState, setTotpValidatorOpenState ]  = useState(false);

    const passwordScore = {
        risky: 0,
        poor: 1,
        weak: 2,
        acceptable: 3,
        strong: 4
    };

    // TODO: disabled for testing only
    const [ isPasswordTooRisky, setIsPasswordTooRisky ]  = useState(false);

    function changePassword() {
        setTotpValidatorOpenState(true);
    }

    // TODO: disabled for testing only
    function passwordScoreCallback(score) {
        // setIsPasswordTooRisky(
        //     score < passwordScore.acceptable
        // );
    }

    function changePasswordSubmit(totp,callback) {
        updateAppLoadingState(true);
        setResponseFailureMessage(t('failedToUpdatePasswordPleaseTryAgainLater'));

        if(password.length && newPassword === newPasswordRepeat && totp.length) {
            axios.post(
                "/api/v1/profile/change-password",
                serialize({
                    password: password,
                    newPassword: newPassword,
                    totp: totp
                })
            ).then(result => {
                updateAppLoadingState(false);
                if (result.status === 200) {
                    if (result.data.success) {
                        setPassword('')
                        setNewPassword('');
                        setNewPasswordRepeat('');
                        setPasswordChangeSuccessState(true);
                    } else {
                        if(result.data.message && !result.data.errorCode) {
                            setResponseFailureMessage(result.data.message);
                            setPasswordChangeSuccessState(false);
                        }
                    }
                    if( typeof callback === 'function' ) {
                        callback(result.data);
                    }
                }
            }).catch(e => {
                console.log(e);
                updateAppLoadingState(false);
                setPasswordChangeSuccessState(false);
            });
        } else {
            updateAppLoadingState(false);
        }
    }


    return (
        <React.Fragment>
            <Typography variant="h4" gutterBottom={true}>
                {t('changePassword')}
            </Typography>
            <form className={classes.root} noValidate autoComplete="off">
                <Grid container>
                    <Grid item xs={4}>
                        <PasswordField
                            id="password"
                            name="password"
                            password={password}
                            setPassword={setPassword}
                            isRequired={true}
                            handleLastInputKeyPress={null}
                            label={t('currentPassword')}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={4}>
                        <Divider varient="middle" style={{width:'100%',marginTop: 10}} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={4}>
                        <PasswordField
                            id="newPassword"
                            name="newPassword"
                            password={newPassword}
                            setPassword={setNewPassword}
                            isRequired={true}
                            handleLastInputKeyPress={null}
                            label={t('newPassword')}
                            showStrengthIndicator={true}
                            passwordScoreCallback={passwordScoreCallback}
                        />
                        <PasswordField
                            id="confirmNewPassword"
                            name="confirmNewPassword"
                            password={newPasswordRepeat}
                            setPassword={setNewPasswordRepeat}
                            isRequired={true}
                            handleLastInputKeyPress={null}
                            label={t('confirmNewPassword')}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        {newPassword.length>0 &&
                        <PasswordStrengthChecklist
                            password={newPassword}
                            passwordAgain={newPasswordRepeat}
                            callbackHandler={ (isValid)=>{ console.log(isValid); }}
                        />
                        }
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <Button
                            disabled={isPasswordTooRisky}
                            variant="contained"
                            color="secondary"
                            margin="normal" startIcon={<DoneIcon/>}
                            className={classes.buttonWithSpace}
                            onClick={changePassword}
                            data-cy="cy-profile-change-password-changePassword-btn"
                        >
                            {t('changePassword')}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <ResponseToast
                key="profilePasswordChangeResponseToast"
                autoHideDuration={3000}
                responseStatus={passwordChangeSuccessState}
                successMessage={t('yourPasswordWasUpdatedSuccessfully')}
                failureMessage={responseFailureMessage}
                closeHandler={() => setPasswordChangeSuccessState(null)}
            />
            <TotpValidator
                openState={totpValidatorOpenState}
                setOpenState={setTotpValidatorOpenState}
                onSubmit={(totp,callback) => changePasswordSubmit(totp,callback)}
            />
        </React.Fragment>
    );

}

export default ChangePassword;