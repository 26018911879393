import React, {useEffect, useState} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {useTranslation} from "react-i18next";
import PasswordChecklist from "react-password-checklist"

const colorScheme = {
    progressBackground: '#E9E9E9',
    progressColor: {
        risky: '#AA4A44',
        poor: '#ec645c',
        weak: '#FF8C00',
        acceptable: '#7FFFD4',
        good: '#AFE1AF',
        strong: '#00a86b'
    }
};

const useStyles = makeStyles((theme) => ({
    checklist: {
        margin: '16px 0px 0px 23px !important',
        '& li': {
            display:'block'
        }
    },
    title: {
        fontWeight: 600
    },
    warning: {
        marginTop: "8px"
    }
}))

function PasswordStrengthChecklist(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        toggle,
        password,
        passwordAgain,
        callbackHandler
    } = props;

    // standard AD password policy
    const minPasswordLength=7;
    const messages={
        letter: t("passwordChecklistLetter"),
        number: t("passwordChecklistNumber"),
        capital: t("passwordChecklistCapital"),
        specialChar: t("passwordChecklistSpecialChar"),
        minLength: t("passwordChecklistMinLength",{minPasswordLength}),
        match: t("passwordChecklistMatch"),
	}

    return (
        <>
            <div className={classes.checklist}>
                <Typography variant="body2" gutterBottom={true} className={classes.title}> {t("passwordChecklistTitle")}</Typography>
                <PasswordChecklist
                    rules={["letter","number","capital","specialChar","minLength","match"]}
                    messages={messages}
                    minLength={minPasswordLength}
                    value={password}
                    valueAgain={passwordAgain}
                    iconSize={11}
                    onChange={(isValid) => callbackHandler}
                />
            </div>     
            <Typography variant="body2" color="textSecondary" className={classes.warning}>
                {t("passwordChecklistPolicyWarning")}
            </Typography>
        </>
    );
}

export default PasswordStrengthChecklist;



