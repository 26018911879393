import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import {useAppLoadingState} from "../../../../context/appLoadingState";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";
import { serialize } from 'object-to-formdata';
import AlertDialog from "../../../Shared/Communication/AlertDialog";

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        '&:hover, &:focus': {
            textDecoration: 'none'
        },
    },
}));

function ForgotUsername(props) {
    const classes = useStyles();
    const { updateAppLoadingState } = useAppLoadingState();
    const { t } = useTranslation();
    const [ email, setEmail ]  = useState("");
    const [ emailSentOpenState, setEmailSentOpenState] = useState(false);
    const [ alertDialogText, setAlertDialogText ] = useState('We will send you an email with matching account name in a minute.');

    function handleLastInputKeyPress(e){
        if(e.key === 'Enter'){
            e.preventDefault();
            handleFindUsername();
        }
    }

    function handleFindUsername() {

        setEmailSentOpenState(false);
        updateAppLoadingState(true);

        if(email.length) {
            axios.post(
                "/api/v1/user/recovery/username/recover",
                serialize({
                    email: email,
                })
            ).then(result => {
                updateAppLoadingState(false);
                if (result.status === 200) {
                    console.log(result.data);
                    if (result.data.success) {
                        // stub
                    } else {

                    }
                    setEmailSentOpenState(true);
                }
            }).catch(e => {
                updateAppLoadingState(false);
            });
        } else {
            updateAppLoadingState(false);
        }
    }


    return (
        <React.Fragment>
            <Typography component="h2" variant="subtitle1">
                {t('usernameRecovery')}
            </Typography>
            <form className={classes.form} noValidate>
                <TextField
                    variant="outlined"
                    margin="normal"
                    size="small"
                    required
                    fullWidth
                    id="email"
                    label={t('email')}
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    inputProps={{
                        "data-cy": "cy-forgot-username-form-field-email",
                    }}
                    onKeyDown={handleLastInputKeyPress}
                    onChange={(e) => {
                        setEmail(e.target.value);
                    }}
                />
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Button
                            component={Link}
                            href="/login"
                            fullWidth
                            variant="text"
                            color="primary"
                            className={classes.submit}
                            data-cy="cy-forgot-username-back-btn"
                        >
                            {t('backToLoginPage')}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="secondary"
                            className={classes.submit}
                            data-cy="cy-forgot-username-findUsername-btn"
                            onClick={handleFindUsername}
                        >
                            {t('findUsername')}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <AlertDialog
                openState={emailSentOpenState}
                title="Username Recovery"
                text={alertDialogText}
            />
        </React.Fragment>
    );
}

export default ForgotUsername;