import CreatePartnerEmployee from "../../../../Modules/SecurityManager/ManagementModule/Views/CreatePartnerEmployee";


export const appRouteMapping = [
    {
        route: '/security-manager/management/create-partner-employee',
        breadcrumb: 'Create Partner Employee',
        component: CreatePartnerEmployee,
    },

];