import React, {useEffect, useState} from 'react';
import {AuthContext, useAuth} from "../../../../context/auth";
import { useTranslation } from 'react-i18next';
import {makeStyles } from '@material-ui/core/styles';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

const useStyles = makeStyles({
    root: {
    },
    dialogContent: {
        padding:0
    },
    appBar: {
        position: 'relative',
    },
    errorMessage: {
        visibility: 'visible',
        color: "red"
    },
    submitButton: {
        marginTop: 30,
        backgroundColor: '#51A843',
        color:'#fff',
        '&:hover': {
            backgroundColor: '#377c2b',
            color:'#fff',
        },
    }
});

function TotpValidator(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const { authData } = useAuth();

    const {
        openState,
        setOpenState,
        onSubmit,
    } = props;

    const [ totp, setTotp ] = useState("");
    const [ totpErrorMsg, setTotpErrorMsg ] = useState("");

    const [ isSubmitting, setIsSubmitting ]  = React.useState(false);

    const wrongTotpMsg = 'Invalid TOTP, please try again.';
    const standardErrorMsg = 'Something went wrong.';

    // useEffect(() => {
    //     setOpenState(props.openState);
    // }, [props.openState]);

    useEffect(() => {
        if(openState){
            setTotp("");
            setTotpErrorMsg("");
        }
            
    }, [openState]);
    
    function handleClose(){
        setOpenState(false);
        setIsSubmitting(false);
    }

    function handleSubmit(e){
        e.preventDefault();
        if(totp.length) {
            setIsSubmitting(true);
            setTotpErrorMsg("");
            onSubmit(totp, function(data){
                if(data.success) {
                    setOpenState(false);
                } else {
                    if( data.errorCode==555 ) {
                        setTotpErrorMsg(wrongTotpMsg);
                    } else {
                        setTotpErrorMsg(standardErrorMsg)
                    }
                }
                setIsSubmitting(false);
            });
        }
    }

    return (
        <React.Fragment>
            <Dialog
                maxWidth="sm"
                fullWidth
                open={openState}
                onClose={handleClose}
            >
                <form onSubmit={handleSubmit}>
                    <DialogTitle>{t('proveYourIdentity')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText color="text.primary">
                            In order to keep your account safe, certain actions are only possible by providing your 2nd-factor authentication token (TOTP).
                        </DialogContentText>
                        <TextField
                            margin="normal"
                            fullWidth
                            name="username"
                            label={t('username')}
                            type="text"
                            id="username"
                            disabled
                            variant="outlined"
                            size="small"
                            value={authData.authData.username || ''}
                            inputProps={{
                                "data-cy": "cy-totp-valiator-form-readOnly-field-username",
                            }}
                        />
                        <TextField
                            margin="normal"
                            autoFocus
                            fullWidth
                            required
                            name="totp"
                            label="TOTP"
                            type="text"
                            id="totp"
                            variant="outlined"
                            size="small"
                            value={totp}
                            inputProps={{
                                "data-cy": "y-totp-validator-form-field-totp",
                                autocomplete: 'off',
                                maxLength: 6
                            }}
                            onChange={(e) => {
                                const newTotp = e.target.value.replace(/\D/g, ""); //ignore everything but numbers
                                setTotp(newTotp);
                            }}
                        />
                        <div className={classes.errorMessage}>{totpErrorMsg}</div>
                        <DialogActions>
                            <Button
                                disabled={isSubmitting || totp.length!=6}
                                variant="contained"
                                margin="normal" startIcon={<VerifiedUserIcon/>}
                                className={classes.submitButton}
                                type="submit"
                                data-cy="cy-totp-validator-submit-btn"
                            >
                                {t('verify')}
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </form>
            </Dialog>
        </React.Fragment>
    );
}

export default TotpValidator;