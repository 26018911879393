import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@mui/material/LinearProgress';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const LinearProgressBar = ({ progressLabel, value, background, color }) => {
    const useStyles = makeStyles({
        root: {
            height: 2,
            borderRadius: 1
        },
        colorPrimary: {
            backgroundColor: background + ' !important'
        },
        bar: {
            borderRadius: 5,
            backgroundColor: color + ' !important'
        }
    });

    const classes = useStyles();

    return (
        typeof progressLabel !== 'undefined') ? (
                <Box sx={{width: '100%'}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Box sx={{width: '100%', mr: 1}}>
                            <LinearProgress
                                variant="determinate"
                                value={value}
                                classes={{
                                    root: classes.root,
                                    colorPrimary: classes.colorPrimary,
                                    bar: classes.bar
                                }}
                            />
                        </Box>
                        <Box sx={{minWidth: 30}}>
                            <Typography variant="caption" color="text.secondary">{progressLabel}</Typography>
                        </Box>
                    </Box>
                </Box>
        ) :
        (
            <LinearProgress
                variant="determinate"
                value={value}
                classes={{
                    root: classes.root,
                    colorPrimary: classes.colorPrimary,
                    bar: classes.bar
                }}
            />
        );
};
export default LinearProgressBar;