import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import axios from "axios";
import {serialize} from "object-to-formdata";
import {useAppLoadingState} from "../../../../../../../context/appLoadingState";

const useStyles = makeStyles((theme) => ({
    openTicketButtonContainer: {
        marginTop: 10
    },
    openTicketSubTitle: {
        padding: '8px 0'
    }
}));


function OpenTicketButtonContainer(props) {
    const classes = useStyles();
    const { updateAppLoadingState } = useAppLoadingState();
    const { t } = useTranslation();
    const {
        articleList,
        shortDescriptionStarterText
    } = props;


    const handleCreateDraft = (event) => {
        updateAppLoadingState(true);
        axios.post(
            "/api/v1/support/ticket/create-draft",
            serialize({
                shortDescription: shortDescriptionStarterText,
            })
        ).then(result => {
            updateAppLoadingState(false);
            if (result.status === 200) {
                props.history.push('/support/ticket/create');
            }
        }).catch(e => {
            console.log(e);
            updateAppLoadingState(false);
        });
    };



    return (
        <React.Fragment>
            {Object.keys(articleList).length>0 &&
            <Divider/>
            }
            <Grid container spacing={3} justify="center" className={classes.openTicketButtonContainer}>
                <Grid item xs={12} align="center">
                    <Typography variant="subtitle1" className={classes.openTicketSubTitle}>
                        {Object.keys(articleList).length>0 ? t('createTicketButtonSubtTitle') : t('noResultsCreateTicketButtonSubtTitle')}
                    </Typography>
                    <br/>
                    <Button
                        data-cy="cy-createTicket-solutionSearch-openTicket-btn"
                        variant="contained" size="large" color="secondary" onClick={handleCreateDraft}>
                        {t('openATicket')}
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default OpenTicketButtonContainer;