import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {usePerm} from "../../../../context/permission";
import { useAppLoadingState } from "../../../../context/appLoadingState";
import RouteBuilder from "../../../Routing/Layout/Dashboard/Management";
import Divider from '@mui/material/Divider';

import axios from "axios";
import { serialize } from 'object-to-formdata';
import Grid from '@mui/material/Grid';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SaveIcon from '@material-ui/icons/Save';
import Typography from "@material-ui/core/Typography";
import { useTranslation } from 'react-i18next';
import GeneralPermissionDeniedMessage from "../../../Shared/StaticContainerMessage/GeneralPermissionDeniedMessage";
import SideMenu from "./SideMenu";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        height:'100vh'
    },
    sideMenu: {
        background: '#fff',
        paddingLeft:' 0 !important',
        height: '100%'
    },
    managementFeature: {
        height: '100%'
    }
}));


function ManagementModule(props) {

    const classes = useStyles();
    const { permData } = usePerm();
    const { updateAppLoadingState } = useAppLoadingState();
    const { t } = useTranslation();

    useEffect(() => {

    }, []);

    return (
        <div className={classes.root}>
            {(permData.flags.sm == false && permData.flags.psm == false)  &&
                <GeneralPermissionDeniedMessage
                    customReason="If you think this is by mistake"
                    contactPerson="customer support"
                />
            }
            {(permData.flags.sm == true || permData.flags.psm == true) &&
            <Grid container spacing={3}>
                <Grid item xs={12} md={2} lg={2} xl={2} className={classes.sideMenu}>
                    <SideMenu/>
                </Grid>
                <Divider orientation="vertical" flexItem display={{ sm: 'none', md: 'inline' }}/>
                <Grid item xs={12} md={9} lg={9} xl={9} className={classes.managementFeature}>
                    <RouteBuilder/>
                </Grid>
            </Grid>
            }

        </div>
    );
}

export default ManagementModule;