import React from 'react';
import camelCase from 'camelcase';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {Link, Route} from "react-router-dom";
import {appRouteMapping} from "../../../Routing/Layout/Dashboard/Route.mapping";

function BreadcrumbBuilder(props) {

    const useStyles = makeStyles(theme => ({
        wrapper: {
            display: 'flex',
            padding: 20,
            paddingBottom:0
        },
        crumb: {
            borderRadius: 10,
            padding: 2,
            paddingLeft: 4,
            paddingRight: 4,
            height: theme.spacing(3),
            color: '#000',
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: 12,
            textDecoration: 'none',
            '&:hover, &:focus': {
                backgroundColor: theme.palette.grey[300],
            },
        },
        lastCrumb: {
            fontSize: 12,
            fontWeight: theme.typography.fontWeightBold,
        }
    }));

    const { t } = useTranslation();
    const classes = useStyles();
    const breadcrumbMap = [];

    for(let i in appRouteMapping) {
        if( appRouteMapping[i].hasOwnProperty('invisible') && appRouteMapping[i].invisible==true ) {
            continue;
        }

        const translation = t(
            camelCase(appRouteMapping[i].breadcrumb)
        );
        let fullRoute = appRouteMapping[i].route;

        let routeSplitted = fullRoute.split('/').filter(x => x),
            routeLast = routeSplitted[routeSplitted.length-1];

        // clean parameter definition from the route
        let route = '/'+(
            ( routeLast.indexOf(':')===-1 )
            ? routeSplitted
            : routeSplitted.splice( 0,routeSplitted.length - 1)
        ).join('/');

        breadcrumbMap[route] = {
            hasComponent:  appRouteMapping[i].component != null,
            alternativeRoute: appRouteMapping[i].hasOwnProperty('alternativeRoute') ? appRouteMapping[i].alternativeRoute : null,
            text: translation ? translation : appRouteMapping[i].breadcrumb
        };
    }

    return (
        <Route>
            {() => {
                let pathnames = window.location.pathname.split('/').filter(x => x),
                        pathnamesLast = pathnames[pathnames.length-1];

                // avoid the param template
                 pathnames = ( !/^\d+$/.test(pathnamesLast) )
                        ? pathnames
                        : pathnames.splice( 0,pathnames.length - 1);

                return (
                    <Breadcrumbs aria-label="breadcrumb" className={classes.wrapper}>
                        {props.hasOwnProperty('showHome') && props.showHome &&
                        <Link color="inherit" to="/" className={classes.crumb}>
                            {t('home')}
                        </Link>
                        }
                        {pathnames.map((value, index) => {
                            const last = index === pathnames.length - 1;
                            const to = `/${pathnames.slice(0, index + 1).join('/')}`;

                            if( breadcrumbMap[to] ) {
                                let usedRoute = to;
                                if( !breadcrumbMap[to].hasComponent && breadcrumbMap[to].alternativeRoute ) {
                                    usedRoute = breadcrumbMap[to].alternativeRoute;
                                }
                                return last ? (
                                    <Typography color="textPrimary" key={usedRoute} className={classes.lastCrumb}>
                                        {breadcrumbMap[to].text}
                                    </Typography>
                                ) : (
                                    <Link color="inherit" to={usedRoute} key={usedRoute} className={classes.crumb}>
                                        {breadcrumbMap[to].text}
                                    </Link>
                                );
                            }
                        })}
                    </Breadcrumbs>
                );
            }}
        </Route>
    );
}

export default React.memo(BreadcrumbBuilder);